import React, { useState, createContext } from "react";

export const ReportsContext = createContext();

export const ReportsProvider = (props) => {
    const { children } = props;

    const [ reports, setReports ] = useState('');
    const [ reportsUser, setReportsUser ] = useState('');

    const clearReportsContext = async () => {
        setReports('');
        setReportsUser('')
        return
    }

    return (
        <ReportsContext.Provider 
            value={{ 
                reports: reports,
                reportsUser: reportsUser,
                setReports,
                setReportsUser,
                clearReportsContext,
            }}
        >
            {children}
        </ReportsContext.Provider>
    )
}