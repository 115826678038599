import React from "react";
import { Container, LoadingContainer } from "./styles";
import DotLoader from "react-spinners/ClipLoader";

const Button = ({ text, loading, onClick }) => {
  if (loading) {
    return (
      <LoadingContainer>
        <DotLoader
          size={50}
          color={"#9a4749"}
          loading={loading}
          speedMultiplier={1.5}
        />
      </LoadingContainer>
    );
  }

  return <Container onClick={onClick}>{text}</Container>;
};

export default Button;
