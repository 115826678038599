import React, { useEffect, useState } from 'react';

import { Container, Content, Item, Header, ClearText, ToolBar } from './styles';
import { arrayHelper } from '../../../../helpers/arrayHelper';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { BsEraser } from 'react-icons/bs';
import { especiesFilterIcons } from '../../../../resources/especiesFilterIcons';
import { returnFilterIcons } from '../../../../resources/returnFilterIcons';

function EspeciesMapFilter({ onChangeSelected, calculate, clearEspeciesFilter }) {

  const [ selectedEspecies, setSelectedEspecies ] = useState(arrayHelper.addSelectedField(
    especiesFilterIcons.concat(returnFilterIcons), true));
  const [ expanded, setExpanded ] = useState(false);

  const handleSelect = (item) => {
    const index = selectedEspecies.findIndex(x => x.especie == item.especie);

    if(index != -1){
      const newItem = {
        ...item,
        selected: !item.selected
      }

      const newArray = arrayHelper.insertObjectOnArray(selectedEspecies, index, newItem)

      setSelectedEspecies(newArray)
      onChangeSelected(newArray.filter(x => x.selected == true))
    }
  }

  const clearFilter = () => {
    setSelectedEspecies(arrayHelper.addSelectedField(especiesFilterIcons.concat(returnFilterIcons), true))
    onChangeSelected(arrayHelper.addSelectedField(especiesFilterIcons.concat(returnFilterIcons), true))
  }

  useEffect(() => {
    if(calculate){
      setSelectedEspecies(arrayHelper.addSelectedField(especiesFilterIcons.concat(returnFilterIcons), true))
    }
  }, [calculate])

  useEffect(() => {
    setSelectedEspecies(arrayHelper.addSelectedField(especiesFilterIcons.concat(returnFilterIcons), true))
  }, [clearEspeciesFilter])

  return (
    <Container>
      <Header onClick={() => setExpanded(!expanded)}>
        <h3>Filtro de benefícios</h3>
        {expanded
            ? <IoIosArrowUp cursor='pointer' />
            : <IoIosArrowDown cursor='pointer' />
        }
      </Header>
      {expanded && (
        <>
          <ToolBar>
            {expanded && <h5>Clique para marcar/desmarcar</h5>}
            <ClearText onClick={() => clearFilter()}>
              Limpar
              <BsEraser  style={{ cursor: 'pointer', marginLeft: 5, color: '#808B9F' }} />
            </ClearText>
          </ToolBar>
          <Content>
            {selectedEspecies.map((item, index) => (
              <Item 
                key={index} 
                selected={item.selected}
                title={item.title}
                onClick={() => handleSelect(item)}
              >
                <img src={item.icon} />
              </Item>
            ))}
          </Content>
        </>
      )}
    </Container>
  )
}

export default EspeciesMapFilter;