import React, { useContext, useState } from 'react';

import { CardsContainer } from './styles';

import { CustomCard } from '../../../../components/CustomCard';

import checkedIcon from '../../../../assets/checkedIcon.svg';
import clockIcon from '../../../../assets/clockIcon.svg';
import inRouteIcon from '../../../../assets/inRouteIcon.svg';
import expiredIcon from '../../../../assets/expiredIcon.svg';
import contractIcon from '../../../../assets/contractIcon.svg';

import Modal from 'react-modal';

//Utils
import tableColumnsReturns from '../../../../resources/tableColumnsReturns.json';
import { UsersContext } from '../../../../contexts/UsersContext';
import ModalReturnCard from '../../../../components/ModalReturnCard';

const defaultModalData = {
    array: [],
    title: '',
    type: null,
    columns: 5,
    filterTypes: null,
    tableColumns: tableColumnsReturns,
    visible: false
}

function ReturnsCards({ cardsData }) {
    const { users } = useContext(UsersContext);

    const [ modalData, setModalData ] = useState(defaultModalData);    
    
    const closeModal = () => {
        setModalData(defaultModalData)
    }

    return (
        <>
            <CardsContainer>
                <CustomCard.Root
                    loading={!cardsData}
                    onClick={() => {
                        setModalData({
                            array: cardsData.realizedReturns,
                            title: 'Retornos realizados',
                            type: 'realized',
                            columns: 6,
                            filterTypes: [
                                { headerName: 'Data', field: 'dataRetorno' }, 
                                { headerName: 'Operador', field: 'operatorName'},
                                { headerName: 'Resultado', field: 'result'}
                            ],
                            tableColumns: tableColumnsReturns,
                            visible: true
                        })
                    }}
                >
                    <CustomCard.Title
                        color="var(--success)"
                        title="Retornos realizados"
                        imgSrc={checkedIcon}
                    />
                    <CustomCard.Divider />
                    <CustomCard.Content
                        color="var(--success)"
                        value={cardsData.realizedReturns?.length}
                        description=''
                    />
                </CustomCard.Root>

                <CustomCard.Root
                    loading={!cardsData}
                    onClick={() => {
                        setModalData({
                            array: cardsData.pendingReturns,
                            title: 'Retornos a realizar',
                            type: 'pending',
                            columns: 5,
                            filterTypes: [
                                { headerName: 'Operador', field: 'operatorName'},
                            ],
                            tableColumns: tableColumnsReturns,
                            visible: true
                        })
                    }}
                >
                    <CustomCard.Title
                        color="var(--yellow)"
                        title="Retornos a realizar"
                        imgSrc={clockIcon}
                    />
                    <CustomCard.Divider />
                    <CustomCard.Content
                        color="var(--yellow)"
                        value={cardsData.pendingReturns?.length}
                        description=''
                    />
                </CustomCard.Root>

                <CustomCard.Root
                    loading={!users}
                    onClick={() => {
                        setModalData({
                            array: cardsData.inRouteReturns,
                            title: 'Retornos em rota',
                            type: 'inRoute',
                            columns: 6,
                            filterTypes: [
                                { headerName: 'Status', field: 'status' }, 
                                { headerName: 'Operador', field: 'operatorName'},
                            ],
                            tableColumns: tableColumnsReturns,
                            visible: true
                        })
                    }}
                >
                    <CustomCard.Title
                        color="var(--blue)"
                        title="Retornos em rota"
                        imgSrc={inRouteIcon}
                    />
                    <CustomCard.Divider />
                    <CustomCard.Content
                        color="var(--blue)"
                        value={cardsData.inRouteReturns?.length}
                        description=''
                    />
                </CustomCard.Root>

                <CustomCard.Root
                    loading={!users}
                    onClick={() => {
                        setModalData({
                            array: cardsData.expiredReturns,
                            title: 'Retornos expirados',
                            type: 'expired',
                            columns: 5,
                            filterTypes: [
                                { headerName: 'Operador', field: 'operatorName'},
                            ],
                            tableColumns: tableColumnsReturns,
                            visible: true
                        })
                    }}
                >
                    <CustomCard.Title
                        color="var(--red)"
                        title="Retornos expirados"
                        imgSrc={expiredIcon}
                    />
                    <CustomCard.Divider />
                    <CustomCard.Content
                        color="var(--red)"
                        value={cardsData.expiredReturns?.length}
                        description=''
                    />
                </CustomCard.Root>

                <CustomCard.Root
                    loading={!users}
                    onClick={() => {
                        setModalData({
                            array: cardsData.closedByReturns,
                            title: 'Contratos fechados após retorno',
                            type: 'closed',
                            columns: 5,
                            filterTypes: null,
                            tableColumns: tableColumnsReturns,
                            visible: true
                        })
                    }}
                >
                    <CustomCard.Title
                        color="var(--success)"
                        title="Contratos fechados após retorno"
                        imgSrc={contractIcon}
                    />
                    <CustomCard.Divider />
                    <CustomCard.Content
                        color="var(--success)"
                        value={cardsData.closedByReturns?.length}
                        description=''
                    />
                </CustomCard.Root>
            </CardsContainer>

            <Modal
                isOpen={modalData.visible}
                onRequestClose={() => closeModal()}
                overlayClassName="modal-overlay"
                className="modal-content-auto-width"
                contentLabel="Example Modal"
            >
                <ModalReturnCard data={modalData} onClose={() => closeModal()}/>
            </Modal>
        </>
    )
}

export default ReturnsCards;