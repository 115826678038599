import { useState } from "react";
import { mappers } from "../helpers/mappers";
import { collection, query, onSnapshot } from "firebase/firestore";
import db from '../firebase/config';
import { orderBy } from "lodash";
import { arrayHelper } from "../helpers/arrayHelper";

export default function useClientHistory(){
    const [ clientHistory, setClientHistory ] = useState('');

    //get remove history admin
    const getClientHistory = async (clientCpf) => {        
        const consulta = query(collection(db, `clientes/${clientCpf}/history`), orderBy("time", "asc"));
        await onSnapshot(consulta, (querySnapshot) => {
            const database = []

            querySnapshot.forEach(async (doc) => {
                // doc.data() is never undefined for query doc snapshots
                const data = doc.data();

                database.push({
                    ...data,
                    docId: doc.id,
                    dataInclusao: data.time
                })
            })
            
            setClientHistory(arrayHelper.ordenateArrayAsc(database, 'time'));
        }, error => console.log("getClientHistory error", error));
    }

    return {
        clientHistory: clientHistory,
        getClientHistory,
        loading: !clientHistory ? true : false
    }
}