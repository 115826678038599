export const screenSizeValues = {
  sml: 400,
  sm: 768,
  md: 992,
  lg: 1200
}

export const screenSizes = {
  sml: "400px",
  sm: "768px",
  md: "992px",
  lg: "1200px"
}

export const breakpoints = {
  sml: `(max-width: ${screenSizes.sml})`,
  sm: `(max-width: ${screenSizes.sm})`,
  md: `(max-width: ${screenSizes.md})`,
  lg: `(max-width: ${screenSizes.lg})`
}