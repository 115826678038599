export const requiredImportHeader = [
    "CPF",
    "NOME",
    "DATA DE NASCIMENTO",
    "IDADE",
    "ESPECIE",
    "NOME_MAE",
    "LOGRADOURO S1",
    "NUMERO S1",
    "COMPLEMENTO S1",
    "BAIRRO S1",
    "ESTADO-CIDADE S1",
    "CEP S1",
    "PROCESSOS",
]