import React, { useContext, useEffect, useState } from 'react';

import { 
    ClientHeader, 
    ClientNameContent, 
    RowContent, 
    RowItem, 
    Container, 
    DetailsContainer, 
    Divider, 
    ImageEspecie, 
    ImageStreetView, 
    InfoHeader,
    ReportDescription,
    ReportHeader,
    ReportsContent,
    Content,
    ResultText,
    TextInput,
    CancelButton,
    SaveButton,
    EditButtons,
    AddressItem,
    AddressHeader,
    AddressText,
    ActionsContent,
    ActionButton,
    ClientHeaderLeft,
    TextArea,
    AnaliseText,
    AddressForm,
} from './styles';

//Firebase and context
import { AuthContext } from '../../contexts/AuthContext';
import { doc, getDoc, updateDoc, deleteDoc, writeBatch, arrayUnion } from "firebase/firestore";
import db from '../../firebase/config';

//Icons
import logIconGrey from '../../assets/logIconGrey.svg';
import { FiMapPin, FiTrash2 } from 'react-icons/fi';
import { TbPhoneCall, TbHammer, TbBriefcase, TbFileInvoice, TbPencil } from 'react-icons/tb';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

//Custom components
import CustomLoading from '../CustomLoading';
import ModalHeader from '../ModalHeader';
import ModalLogs from '../ModalLogs';
import ModalStreetView from '../ModalStreetView';
import ModalDeleteUser from '../ModalDeleteUser';

//Utils
import Modal from 'react-modal';
import Select from 'react-select';
import { alertSuccessStyle } from '../../resources/alertSuccessStyle';
import { alertErrorStyle } from '../../resources/alertErrorStyle';
import { toast } from 'react-toastify';
import axios from 'axios';
import { Accordion } from '@mui/material';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

//utils
import { markers } from '../../helpers/markers';
import { address } from '../../helpers/address';
import { datetime } from '../../helpers/datetime';
import { addClientLogs } from '../../firebase/logs/clients/addClientLogs';
import ClientBadges from '../ClientBadges';
import { ArticleOutlined, RadioButtonChecked, RadioButtonUncheckedOutlined } from '@mui/icons-material';
import { addressFields } from '../../constants/addressFields';

//APIS
import Geocode from "react-geocode";
import { MapsContext } from '../../contexts/MapsContext';
import useAttendClients from '../../hooks/useAttendClients';
import ModalDetailsHistory from '../ModalDetailsHistory';
import { arrayHelper } from '../../helpers/arrayHelper';

const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const selectOptions = [
    {
        label: "Contrato fechado",
        value: "Contrato fechado",
    },
    {
        label: "Localidade diversa",
        value: "Localidade diversa",
    },
    {
        label: "Endereço não encontrado",
        value: "Endereço não encontrado",
    },
    {
        label: "Retornar",
        value: "Retornar",
    },
    {
        label: "Já tem advogado",
        value: "Já tem advogado",
    },
    {
        label: "Não tem direito",
        value: "Não tem direito",
    },
    {
        label: "Não tem interesse",
        value: "Não tem interesse",
    },
]

const validEnds = ['S1', 'S2', 'O1', 'O2'];

const validEndNames = ['logradouro', 'numero', 'complemento', 'bairro', 'cidade', 'estado', 'cep'];

const validEndNamesInd = ['clientLogradouro', 'clientNumero', 'clientBairro', 'clientCidade', 'clientEstado'];

function ModalDetailsAdmin({ data, defaultEdit, onClose }) {

    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);
    Geocode.setLanguage('pt-BR');

    const { isAdmin, isManager, userData } = useContext(AuthContext);
    const { reports } = useAttendClients();
    const { maps } = useContext(MapsContext);

    const [ loadingData, setLoadingData ] = useState(true);
    const [ client, setClient ] = useState('');
    const [ clientReports, setClientReports ] = useState('');
    const [ newReports, setNewReports ] = useState([]);
    
    const [ imageStreetView, setImageStreetView ] = useState('');

    const [ expandedAddress, setExpandedAddress ] = useState(0);

    const [ sectionEdit, setSectionEdit ] = useState(-1);
    const [ newObj, setNewObj ] = useState({});

    const [ modalLogs, setModalLogs ] = useState(false);
    const [ modalStreetView, setModalStreetView ] = useState(false);
    const [ modalDeleteUser, setModalDeleteUser ] = useState(false);
    const [ modalHistory, setModalHistory ] = useState(false);

    useEffect(() => {
        (async () => {
            setLoadingData(true);

            if(defaultEdit == true){
                setSectionEdit(1)
            }

            if(data.lat && data.lng){
                await axios.get(`https://maps.googleapis.com/maps/api/streetview?size=724x144&location=${data.lat},${data.lng}&fov=80&heading=70&pitch=0&key=${apiKey}`, { responseType: "blob" })
                .then((response) => {
                    setImageStreetView(response.data);
                })
                .catch((error) => {
                    
                })
            }

            if(!data.cpf){
                const docRef = doc(db, "clientes", data.clientCpf);
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    const client = {...docSnap.data(), docId: docSnap.id };

                    if(!client.clientCpf){
                        await axios.get(`https://maps.googleapis.com/maps/api/streetview?size=724x144&location=${
                            client.lat},${client.lng}&fov=80&heading=70&pitch=0&key=${apiKey}`, { responseType: "blob" })
                        .then((response) => {
                            setImageStreetView(response.data);
                        })
                        .catch((error) => {
                            
                        })
                    }
                    
                    setClient({ ...client, ...data, dataInclusao: client.time });
                    setNewObj({ ...client, ...data, dataInclusao: client.time });
                    setLoadingData(false);
                } else {
                    setClient(data)
                    setLoadingData(false);
                }
            }else{
                setClient(data);
                setNewObj(data);
                setLoadingData(false);
            } 
        })();
    }, [])

    useEffect(() => {
        (async () => {
            if(reports){
                const arrayReports = reports.filter(x => x.clientCpf === data.cpf || x.clientCpf === data.clientCpf);

                if(arrayReports){
                    const ordenatedReports= arrayReports.sort((a, b) => {
                        if(a.time > b.time){
                            return -1;
                        }
                        if(a.time < b.time){
                            return 1
                        };
                        return 0;
                    });

                    setClientReports(ordenatedReports)
                } 
            }    
        })();
    }, [reports]);

    const formatEndIndication = (client) => {
        var string;

        for(const endName of validEndNamesInd){
            if(client[`${endName}`] != ''){
                if(endName == 'clientLogradouro'){
                    string = client[`${endName}`]
                }else{
                    if(endName == 'clientCidade' || endName == 'clientEstado'){
                        string = string + ' - ' + client[`${endName}`]
                    }else{
                        string = string + ', ' + client[`${endName}`]
                    }
                }
            }
        }

        if(string == undefined){
            string = 'N/D';
        }

        return string;
    }

    const handleActiveEdit = (sectionId) => {
        if(sectionEdit == sectionId){
            setSectionEdit(-1);
        }else{
            setSectionEdit(sectionId)
        }
    }

    const handleEdit = (field, value) => {
        if(field == 'analise'){
            var string = ''

            const splitText = value.split('\n');

            for(const text of splitText){
                if(text == ''){
                    // string = string + "<br/>"
                }else{
                    string = string + text + "<br/>"
                }
            }

            setNewObj({
                ...newObj,
                [field]: string,
            })
        }else{
            setNewObj({
                ...newObj,
                [field]: value,
            })
        }        
    }

    const handleChangeValidEnd = (newValidEnd) => {
        setNewObj({
            ...newObj,
            validEnd: newValidEnd,
        })
    }

    const saveEdit = async () => {

        var newData = {};

        const newObjKeys = Object.keys(newObj);

        const changedFields = []
        
        for(const key of newObjKeys){
            if(client[key] != newObj[key]){
                changedFields.push(key)
                newData[key] = newObj[key]
            }
        }    
        
        let newAddressData = {}

        //if is map client, not indication contract
        if(client.cpf){
            newAddressData = await saveNewLocation(changedFields)
        }

        if(newAddressData === null){
            return 
        }

        newData = { ...newData, ...newAddressData }

        if(Object.keys(newData).length > 0){

            const clientRef = doc(db, "clientes", client.cpf);

            await updateDoc(clientRef, newData)
                .then(async () => {
                    var stringChanges = '';

                    const changesKeys = Object.keys(newData);

                    for(const key of changesKeys){
                        if(stringChanges == ''){
                            stringChanges = key + ` de ${client[key]} para ${newData[key]}` 
                        }else{
                            stringChanges = stringChanges + ' | ' + key + ` de ${client[key]} para ${newData[key]}`
                        }
                    }

                    const logMessage = `${client.nome} teve suas informações alteradas pelo ${userData.userName}: [ ${stringChanges} ]`

                    await addClientLogs(client.cpf, logMessage);

                    toast('Cliente atualizado com sucesso!', alertSuccessStyle)
                })
                .catch((error) => {
                    toast(`Erro ao atualizar cliente: ${error.code}`, alertErrorStyle)
                })
            
            setClient({...client, ...newObj})
            setSectionEdit(-1)
        }else{
            
        }  
    }

    const getLocation = async (address) => {

        try {
            const response = await Geocode.fromAddress(address.toLowerCase());
            const locations = response.results[0].geometry.location;
            return locations;
        }catch{
            return null
        }
        
    }

    const saveNewLocation = async (changedFields) => {
        const addressChanges = []

        for(const item of changedFields){
            if(addressFields.concat(['validEnd']).includes(item)){
                addressChanges.push(item)
            }
        }

        if(addressChanges.length > 0){
            const result = await getLocation(address.formatValidEnd(newObj, newObj.validEnd));

            const newCidadeMap = newObj[`estado${newObj.validEnd}`].trim() 
                + ' - ' + newObj[`cidade${newObj.validEnd}`].trim();
            let newMapId = null
    
            for(const map of maps){
                const findInCities = map.cities.find(x => x.value === newCidadeMap);
    
                if(findInCities){
                    newMapId = map.docId
                }
            }
    
            if(!newMapId){
                toast(`Não foi encontrado um mapa com a cidade ${
                    newCidadeMap}, adicione essa cidade ao mapa para concluir a alteração`, alertErrorStyle)
            }

            if(result && newCidadeMap){
                return {
                    lat: result.lat,
                    lng: result.lng,
                    differentLocation: false,
                    endNotFoundOperator: false,
                    endNotFound: false,
                    operatorUid: "",
                    selected: "",
                    cidadeMap: newMapId ? newCidadeMap : "",
                    mapId: newMapId ? newMapId : client.mapId
                }
            }else{
                toast('Endereço não encontrado', alertErrorStyle)
                return null
            }
        }

        return {}
    }

    const handleEditReports = async (field, value, index) => {
        
        const array1 = clientReports.slice(0, index);
        const array2 = clientReports.slice(index+1, clientReports.length);

        array1.push({
            ...clientReports[index],
            [field]: value
        })

        setClientReports(array1.concat(array2))
        setNewReports([...newReports, {
            ...clientReports[index],
            [field]: value
        }])
    }

    const saveEditReports = async () => {
        const batch = writeBatch(db);

        for(const newReport of newReports){

            const dateNow = new Date();
            
            //Save client
            const clientRef = doc(db, "clientes", newReport.clientCpf);

            batch.update(clientRef, {
                selected: newReport.result == selectOptions[1].value 
                    || newReport.result  == selectOptions[2].value || newReport.result  == selectOptions[3].value 
                    ? "" : "done",
                reportId: newReport.docId,
                closeDate: dateNow.getTime(),
                differentLocation: newReport.result == selectOptions[1].value ? true : false,
                endNotFoundOperator: newReport.result == selectOptions[2].value ? true : false,
                operatorUid: newReport.result == selectOptions[3].value ? newReport.operatorUid : '',
                retornos: newReport.result == selectOptions[3].value ? newReport.retornos+1 : newReport.retornos,
            })

            //Save report
            const reportRef = doc(db, "reports", newReport.docId);

            batch.update(reportRef, {
                result: newReport.result,
                description: newReport.description,
                retornos: newReport.result == selectOptions[3].value ? newReport.retornos+1 : newReport.retornos,
                time: dateNow.getTime(),
            })

            //Save report
            const stringDate = datetime.getFullDate(dateNow.getTime()); 

            const logMessage = `${newReport.clientName} teve seu atendimento alterado pelo ${userData.userName} para: ${
                newReport.result}`

            const log = `${stringDate} -> ${logMessage}` 

            const docRefLogs = doc(db, "clientesLogs", newReport.clientCpf);

            batch.update(docRefLogs, {
                logs: arrayUnion(log)
            });
        }

        batch.commit().then(async () => {
            toast("Atendimentos alterados com sucesso", alertSuccessStyle)
        }).catch(async (error) => {
            toast(`Erro ao alterar atendimentos ${error.code}`, alertErrorStyle)
        })

        setClient({...client, ...newObj})
        setSectionEdit(-1)
    }

    const deleteReport = async (report) => {

        const reportRef = doc(db, "reports", report.docId);

        const clientReports = reports.filter(x => x.clientCpf === report.clientCpf);

        const ordenatedReports = arrayHelper.ordenateArrayDesc(clientReports, 'time');

        const lastReport = ordenatedReports.length > 1 ? ordenatedReports[1] : null

        const newData = lastReport
            ? {
                selected: lastReport.result == selectOptions[1].value 
                    || lastReport.result  == selectOptions[2].value || lastReport.result  == selectOptions[3].value 
                    ? "" : "done",
                reportId: lastReport.docId,
                closeDate: lastReport.time,
                differentLocation: lastReport.result == selectOptions[1].value ? true : false,
                endNotFoundOperator: lastReport.result == selectOptions[2].value ? true : false,
                operatorUid: lastReport.result == selectOptions[3].value ? lastReport.operatorUid : '',
                retornos: lastReport.retornos,
            }
            : {
                selected: "",
                operatorUid: "",
                closeDate: "",
                reportId: "",
            }

        await deleteDoc(reportRef)
        .then(async () => {
            var stringChanges = `${datetime.getFullDate(report.time)} -> Resultado: ${
                report.result} | Operador: ${report.operatorName}`;

            const logMessage = `${report.clientName} teve seu atendimento excluído pelo ${userData.userName}: [ ${stringChanges} ]`

            const clientRef = doc(db, "clientes", report.clientCpf);
            
            await updateDoc(clientRef, newData)
            .then(async () => {
                await addClientLogs(report.clientCpf, logMessage);

                toast('Atendimento excluído com sucesso!', alertSuccessStyle)
                setSectionEdit(-1)
            })
            .catch((error) => {
                toast(`Erro ao excluir atendimento: ${error.code}`, alertErrorStyle)
            })
        })
        .catch((error) => {
            toast(`Erro ao excluir atendimento: ${error.code}`, alertErrorStyle)
        })
    }

    const customStyle = {      
        container: (base) => ({
            ...base,
            borderRadius: 40,
            color: 'var(--blue4)'
        }),
        control: (base) => ({
            ...base,
            padding: 3,
            borderRadius: 40,
            color: 'var(--blue4)'
        }),
        multiValue: (base) => ({
            ...base,
            borderRadius: 40
        }),
        singleValue: provided => ({
            ...provided,
            color: 'var(--blue4)'
        })
    } 

    if(loadingData || !client){
        return (
            <Container>
                <CustomLoading background='transparent' />
            </Container>
        )
    }
    
    return (
        <Container>
            <ModalHeader 
                title='Detalhes do cliente'
                onClose={onClose}
            />
            <Content>
                <ImageStreetView
                    src={imageStreetView ? URL.createObjectURL(imageStreetView) : null}
                    onClick={() => setModalStreetView(true)}
                />

                <ClientHeader>
                    <ClientHeaderLeft>
                        <ImageEspecie>
                            <img 
                                src={markers.getMarkerIconsEspecie(client.especie)} 
                                style={{ width: 30, height: 30, objectFit: 'cover'}} 
                            />
                        </ImageEspecie>
                        <ClientNameContent>
                            {client.nome
                                ? <h4>{client.nome}</h4>
                                : <h4>{client.clientName}</h4>
                            }
                            <p>{client.especie}</p>
                        </ClientNameContent>
                    </ClientHeaderLeft>
                    <div>
                        <ClientBadges data={client} isModalDetails />
                    </div>
                    {client.nome && client.dataInclusao && (<h3>Data de inclusão: {datetime.getDate(client.dataInclusao)}</h3>)}
                    {client.nome && !client.dataInclusao && (<h3>Data de inclusão: {datetime.getDate(client.time)}</h3>)}
                </ClientHeader>

                <Divider />

                <DetailsContainer>
                    <InfoHeader>
                        <div>
                            <TbFileInvoice size={24} color='var(--red)'  style={{ strokeWidth: 1.8 }} />
                            <p>Dados</p>
                            {sectionEdit != 0 && isAdmin && (
                                <TbPencil 
                                    onClick={() => handleActiveEdit(0)}
                                    size={22} 
                                    style={{ strokeWidth: 1.5, cursor: 'pointer' }} 
                                />
                            )}
                        </div>
                        {sectionEdit == 0 && (
                            <EditButtons>
                                <CancelButton onClick={() => {
                                    setSectionEdit(-1);
                                    setNewObj(client)
                                }}>
                                    Cancelar
                                </CancelButton>
                                <SaveButton onClick={async () => await saveEdit()}>
                                    Salvar edição
                                </SaveButton>
                            </EditButtons>
                        )}
                    </InfoHeader>
                    <RowContent>
                        {client.dataNasc && (
                            <RowItem>
                                <h3>Data de nascimento</h3>
                                {sectionEdit == 0
                                    ? <TextInput>
                                        <input 
                                            name='dataNasc'
                                            onChange={event => handleEdit('dataNasc', event.target.value)}
                                            defaultValue={newObj.dataNasc}
                                        />
                                    </TextInput>
                                    : <p>{client.dataNasc}</p>
                                }
                            </RowItem>
                        )}
                        
                        {client.idade && (
                            <RowItem>
                                <h3>Idade</h3>
                                {sectionEdit == 0
                                    ? <TextInput>
                                        <input 
                                            name='idade'
                                            onChange={event => handleEdit('idade', event.target.value)}
                                            defaultValue={newObj.idade}
                                        />
                                    </TextInput>
                                    : <p>{client.idade}</p>
                                }
                            </RowItem>
                        )}
                        
                        {client.cpf
                            ?<RowItem>
                                <h3>CPF</h3>
                                {sectionEdit == 0
                                    ? <TextInput>
                                        <input 
                                            name='cpf'
                                            onChange={event => handleEdit('cpf', event.target.value)}
                                            defaultValue="Não editável"
                                            disabled={true}
                                        />
                                    </TextInput>
                                    : <p>{client.cpf}</p>
                                }
                            </RowItem>

                            : <RowItem>
                                <h3>CPF</h3>
                                {sectionEdit == 0
                                    ? <TextInput>
                                        <input 
                                            name='clientCpf'
                                            onChange={event => handleEdit('clientCpf', event.target.value)}
                                            defaultValue="Não editável"
                                            disabled={true}
                                        />
                                    </TextInput>
                                    : <p>{client.clientCpf}</p>
                                }
                            </RowItem>
                        }
                        
                        {client.nomeMae && (
                            <RowItem>
                                <h3>Nome da mãe</h3>
                                {sectionEdit == 0
                                    ? <TextInput>
                                        <input 
                                            name='nomeMae'
                                            onChange={event => handleEdit('nomeMae', event.target.value)}
                                            defaultValue={newObj.nomeMae}
                                        />
                                    </TextInput>
                                    : <p>{client.nomeMae}</p>
                                }
                            </RowItem>
                        )}
                    </RowContent>
                </DetailsContainer>

                {client.cpf 
                    ? <>
                        <Divider />
                        <DetailsContainer>
                            <InfoHeader>
                                <div>
                                    <FiMapPin size={22} color='var(--red)' />
                                    <p>Endereço</p>
                                    {sectionEdit != 1 && isAdmin && (
                                        <TbPencil 
                                            onClick={() => handleActiveEdit(1)}
                                            size={22} 
                                            style={{ strokeWidth: 1.5, cursor: 'pointer' }} 
                                        />
                                    )}
                                </div>
                                {sectionEdit == 1 && (
                                    <EditButtons>
                                        <CancelButton onClick={() => {
                                            setSectionEdit(-1);
                                            setNewObj(client)
                                        }}>
                                            Cancelar
                                        </CancelButton>
                                        <SaveButton onClick={async () => await saveEdit()}>
                                            Salvar edição
                                        </SaveButton>
                                    </EditButtons>
                                )}
                            </InfoHeader>
                            {validEnds.map((item, index) => {

                                const clientAddress = address.formatValidEnd(client, item)

                                if(clientAddress != 'N/D'){
                                    return (
                                        <AddressItem key={index}>
                                            <AddressHeader>
                                                {sectionEdit == 1
                                                    ? <div onClick={() => handleChangeValidEnd(item)}>
                                                        {newObj.validEnd === item
                                                            ? <RadioButtonChecked fontSize='medium'/>
                                                            : <RadioButtonUncheckedOutlined fontSize='medium' />
                                                        }
                                                        <p>
                                                            Endereço {item}{item == client.validEnd && (' (atual)')}
                                                        </p>
                                                    </div>
                                                    :   <p>
                                                        Endereço {item}{item == client.validEnd && (' (atual)')}
                                                    </p>
                                                }
                                                {/* {expandedAddress == index
                                                    ? <IoIosArrowUp cursor='pointer' onClick={() => setExpandedAddress(null)} />
                                                    : <IoIosArrowDown cursor='pointer' onClick={() => setExpandedAddress(index)} />
                                                } */}
                                            </AddressHeader>
                                                {sectionEdit == 1
                                                    ?<AddressText>
                                                        {validEndNames.map((endName, index) => (
                                                            <AddressForm key={index}>
                                                                <p>{endName}</p>
                                                                <TextInput style={{
                                                                    gridArea: index == 0 ? 'header' : 'content'
                                                                }}>
                                                                    <input 
                                                                        name={endName}
                                                                        onChange={event => handleEdit(`${endName}${item}`, event.target.value)}
                                                                        defaultValue={client[`${endName}${item}`] != 'N/D' 
                                                                            ? (client[`${endName}${item}`])
                                                                            : null
                                                                        }
                                                                        placeholder={`${endName}`}
                                                                    />
                                                                </TextInput>
                                                            </AddressForm>
                                                        ))}
                                                    </AddressText>
                                                    : <p>{clientAddress}</p>
                                                }
                                        </AddressItem>
                                    )
                                }
                            })}
                        </DetailsContainer>
                    </>

                    : <>
                        <Divider />
                        <DetailsContainer>
                            <InfoHeader>
                                <div>
                                    <FiMapPin size={22} color='var(--red)' />
                                    <p>Endereço</p>
                                    {sectionEdit != 1 && isAdmin && (
                                        <TbPencil 
                                            onClick={() => handleActiveEdit(1)}
                                            size={22} 
                                            style={{ strokeWidth: 1.5, cursor: 'pointer' }} 
                                        />
                                    )}
                                </div>
                                {sectionEdit == 1 && (
                                    <EditButtons>
                                        <CancelButton onClick={() => {
                                            setSectionEdit(-1);
                                            setNewObj(client)
                                        }}>
                                            Cancelar
                                        </CancelButton>
                                        <SaveButton onClick={async () => await saveEdit()}>
                                            Salvar edição
                                        </SaveButton>
                                    </EditButtons>
                                )}
                            </InfoHeader>
                            <AddressItem>
                                <AddressHeader>
                                    <p>
                                        Endereço
                                    </p>
                                    {expandedAddress == 0
                                        ? <IoIosArrowUp cursor='pointer' onClick={() => setExpandedAddress(null)} />
                                        : <IoIosArrowDown cursor='pointer' onClick={() => setExpandedAddress(0)} />
                                    }
                                </AddressHeader>
                                {expandedAddress == 0 && (
                                    <AddressText>
                                        {sectionEdit == 1
                                            ? validEndNamesInd.map((endName, index) => (
                                                <TextInput key={index}>
                                                    <input 
                                                        name={endName}
                                                        onChange={event => handleEdit(`${endName}`, event.target.value)}
                                                        defaultValue={client[`${endName}`] != 'N/D' 
                                                            ? (client[`${endName}`])
                                                            : null
                                                        }
                                                        placeholder={`${endName}`}
                                                    />
                                                </TextInput>
                                            ))
                                            : <p>{formatEndIndication(client)}</p>
                                        }
                                    </AddressText>
                                )}
                                
                            </AddressItem>
                        </DetailsContainer>
                    </>
                }

                {client.cpf
                    ? <>
                        <Divider />

                        <DetailsContainer>
                            <InfoHeader>
                                <div>
                                    <TbPhoneCall size={22} color='var(--red)' />
                                    <p>Contato</p>
                                    {sectionEdit != 2 && isAdmin && (
                                        <TbPencil 
                                            onClick={() => handleActiveEdit(2)}
                                            size={22} 
                                            style={{ strokeWidth: 1.5, cursor: 'pointer' }} 
                                        />
                                    )}
                                </div>
                                {sectionEdit == 2 && (
                                    <EditButtons>
                                        <CancelButton onClick={() => {
                                            setSectionEdit(-1);
                                            setNewObj(client)
                                        }}>
                                            Cancelar
                                        </CancelButton>
                                        <SaveButton onClick={async () => await saveEdit()}>
                                            Salvar edição
                                        </SaveButton>
                                    </EditButtons>
                                )}
                            </InfoHeader>
                            <RowContent>
                                {client.telPrincipal && client.telPrincipal != 'N/D' && (
                                    <RowItem>
                                        <h3>Telefone principal</h3>
                                        {sectionEdit == 2
                                            ? <TextInput>
                                                <input 
                                                    name='telPrincipal'
                                                    onChange={event => handleEdit('telPrincipal', event.target.value)}
                                                    defaultValue={newObj.telPrincipal}
                                                />
                                            </TextInput>
                                            :  <p>{client.telPrincipal}</p>
                                        }
                                    </RowItem>
                                )}
                                
                                {client.telSecundario && client.telSecundario != 'N/D'  && (
                                    <RowItem>
                                        <h3>Telefone secundário</h3>
                                        {sectionEdit == 2
                                            ? <TextInput>
                                                <input 
                                                    name='telSecundario'
                                                    onChange={event => handleEdit('telSecundario', event.target.value)}
                                                    defaultValue={newObj.telSecundario}
                                                />
                                            </TextInput>
                                            :  <p>{client.telSecundario}</p>
                                        }
                                    </RowItem>
                                )}
                                
                                {client.celular && client.celular != 'N/D'  && (
                                    <RowItem>
                                        <h3>Celular</h3>
                                        {sectionEdit == 2
                                            ? <TextInput>
                                                <input 
                                                    name='celular'
                                                    onChange={event => handleEdit('celular', event.target.value)}
                                                    defaultValue={newObj.celular}
                                                />
                                            </TextInput>
                                            :  <p>{client.celular}</p>
                                        }
                                    </RowItem>
                                )}

                                {client.tel0 && client.tel0 != 'N/D'  && (
                                    <RowItem>
                                        <h3>Telefone 0</h3>
                                        {sectionEdit == 2
                                            ? <TextInput>
                                                <input 
                                                    name='tel0'
                                                    onChange={event => handleEdit('tel0', event.target.value)}
                                                    defaultValue={newObj.tel0}
                                                />
                                            </TextInput>
                                            :  <p>{client.tel0}</p>
                                        }
                                    </RowItem>
                                )}

                                {client.tel1 && client.tel1 != 'N/D'  && (
                                    <RowItem>
                                        <h3>Telefone 1</h3>
                                        {sectionEdit == 2
                                            ? <TextInput>
                                                <input 
                                                    name='tel1'
                                                    onChange={event => handleEdit('tel1', event.target.value)}
                                                    defaultValue={newObj.tel1}
                                                />
                                            </TextInput>
                                            :  <p>{client.tel1}</p>
                                        }
                                    </RowItem>
                                )}

                                {client.tel2 && client.tel2 != 'N/D'  && (
                                    <RowItem>
                                        <h3>Telefone 2</h3>
                                        {sectionEdit == 2
                                            ? <TextInput>
                                                <input 
                                                    name='tel2'
                                                    onChange={event => handleEdit('tel2', event.target.value)}
                                                    defaultValue={newObj.tel2}
                                                />
                                            </TextInput>
                                            :  <p>{client.tel2}</p>
                                        }
                                    </RowItem>
                                )}
                                
                                {client.celular0 && client.celular0 != 'N/D'  && (
                                    <RowItem>
                                        <h3>Celular 0</h3>
                                        {sectionEdit == 2
                                            ? <TextInput>
                                                <input 
                                                    name='celular0'
                                                    onChange={event => handleEdit('celular0', event.target.value)}
                                                    defaultValue={newObj.celular0}
                                                />
                                            </TextInput>
                                            :  <p>{client.celular0}</p>
                                        }
                                    </RowItem>
                                )}

                                {client.celular1 && client.celular1 != 'N/D'  && (
                                    <RowItem>
                                        <h3>Celular 1</h3>
                                        {sectionEdit == 2
                                            ? <TextInput>
                                                <input 
                                                    name='celular1'
                                                    onChange={event => handleEdit('celular1', event.target.value)}
                                                    defaultValue={newObj.celular1}
                                                />
                                            </TextInput>
                                            :  <p>{client.celular1}</p>
                                        }
                                    </RowItem>
                                )}

                                {client.celular2 && client.celular2 != 'N/D'  && (
                                    <RowItem>
                                        <h3>Celular 2</h3>
                                        {sectionEdit == 2
                                            ? <TextInput>
                                                <input 
                                                    name='celular2'
                                                    onChange={event => handleEdit('celular2', event.target.value)}
                                                    defaultValue={newObj.celular2}
                                                />
                                            </TextInput>
                                            :  <p>{client.celular2}</p>
                                        }
                                    </RowItem>
                                )}
                            </RowContent>
                        </DetailsContainer>
                    </>
                    : <>
                        <Divider />

                        <DetailsContainer>
                            <InfoHeader>
                                <div>
                                    <TbPhoneCall size={22} color='var(--red)' />
                                    <p>Contato</p>
                                    {sectionEdit != 2 && isAdmin && (
                                        <TbPencil 
                                            onClick={() => handleActiveEdit(2)}
                                            size={22} 
                                            style={{ strokeWidth: 1.5, cursor: 'pointer' }} 
                                        />
                                    )}
                                </div>
                                {sectionEdit == 2 && (
                                    <EditButtons>
                                        <CancelButton onClick={() => {
                                            setSectionEdit(-1);
                                            setNewObj(client)
                                        }}>
                                            Cancelar
                                        </CancelButton>
                                        <SaveButton onClick={async () => await saveEdit()}>
                                            Salvar edição
                                        </SaveButton>
                                    </EditButtons>
                                )}
                            </InfoHeader>
                            <RowContent>
                                {client.clientTel && client.clientTel != 'N/D' && (
                                    <RowItem>
                                        <h3>Telefone</h3>
                                        {sectionEdit == 2
                                            ? <TextInput>
                                                <input 
                                                    name='clientTel'
                                                    onChange={event => handleEdit('clientTel', event.target.value)}
                                                    defaultValue={newObj.clientTel}
                                                />
                                            </TextInput>
                                            :  <p>{client.clientTel}</p>
                                        }
                                    </RowItem>
                                )}
                            </RowContent>
                        </DetailsContainer>
                    </>
                }


                {client.cpf && (
                    <>
                        <Divider />
                        <DetailsContainer>
                            <InfoHeader>
                                <div>
                                    <TbPhoneCall size={22} color='var(--red)' />
                                    <p>Indeferimento</p>
                                    {sectionEdit != 3 && isAdmin && (
                                        <TbPencil 
                                            onClick={() => handleActiveEdit(3)}
                                            size={22} 
                                            style={{ strokeWidth: 1.5, cursor: 'pointer' }} 
                                        />
                                    )}
                                </div>
                                {sectionEdit == 3 && (
                                    <EditButtons>
                                        <CancelButton onClick={() => {
                                            setSectionEdit(-1);
                                            setNewObj(client)
                                        }}>
                                            Cancelar
                                        </CancelButton>
                                        <SaveButton onClick={async () => await saveEdit()}>
                                            Salvar edição
                                        </SaveButton>
                                    </EditButtons>
                                )}
                            </InfoHeader>
                            <RowContent>
                                {client.nb && client.nb != 'N/D' && (
                                    <RowItem>
                                        <h3>NB</h3>
                                        {sectionEdit == 3
                                            ? <TextInput>
                                                <input 
                                                    name='nb'
                                                    onChange={event => handleEdit('nb', event.target.value)}
                                                    defaultValue={newObj.nb}
                                                />
                                            </TextInput>
                                            :  <p>{client.nb}</p>
                                        }
                                    </RowItem>
                                )}
                                
                                {client.motivoIndef && client.motivoIndef != 'N/D'  && (
                                    <RowItem>
                                        <h3>Resultado</h3>
                                        {sectionEdit == 3
                                            ? <TextInput>
                                                <input 
                                                    name='motivoIndef'
                                                    onChange={event => handleEdit('motivoIndef', event.target.value)}
                                                    defaultValue={newObj.motivoIndef}
                                                />
                                            </TextInput>
                                            :  <p>{client.motivoIndef}</p>
                                        }
                                    </RowItem>
                                )}
                                
                                {client.dataIndef && client.dataIndef != 'N/D'  && (
                                    <RowItem>
                                        <h3>Data do indeferimento</h3>
                                        {sectionEdit == 3
                                            ? <TextInput>
                                                <input 
                                                    name='dataIndef'
                                                    onChange={event => handleEdit('dataIndef', event.target.value)}
                                                    defaultValue={newObj.dataIndef}
                                                />
                                            </TextInput>
                                            :  <p>{client.dataIndef}</p>
                                        }
                                    </RowItem>
                                )}

                                {client.dataDer && client.dataDer != 'N/D'  && (
                                    <RowItem>
                                        <h3>Data da Der</h3>
                                        {sectionEdit == 3
                                            ? <TextInput>
                                                <input 
                                                    name='dataDer'
                                                    onChange={event => handleEdit('dataDer', event.target.value)}
                                                    defaultValue={newObj.dataDer}
                                                />
                                            </TextInput>
                                            :  <p>{client.dataDer}</p>
                                        }
                                    </RowItem>
                                )}
                            </RowContent>
                        </DetailsContainer>
                    </>
                )}
                
                {client.cpf && (
                    <>
                        <Divider />
                        <DetailsContainer>
                            <InfoHeader>
                                <div>
                                    <TbHammer size={22} color='var(--red)' style={{transform: 'rotate(-90deg)' }} />
                                    <p>Processos arquivados</p>
                                    {sectionEdit != 4 && isAdmin && (
                                        <TbPencil 
                                            onClick={() => handleActiveEdit(4)}
                                            size={22} 
                                            style={{ strokeWidth: 1.5, cursor: 'pointer' }} 
                                        />
                                    )}
                                </div>
                                {sectionEdit == 4 && (
                                    <EditButtons>
                                        <CancelButton onClick={() => {
                                            setSectionEdit(-1);
                                            setNewObj(client)
                                        }}>
                                            Cancelar
                                        </CancelButton>
                                        <SaveButton onClick={async () => await saveEdit()}>
                                            Salvar edição
                                        </SaveButton>
                                    </EditButtons>
                                )}
                            </InfoHeader>
                            {sectionEdit == 4
                                ? <TextInput>
                                    <input 
                                        name='processos'
                                        onChange={event => handleEdit('processos', event.target.value)}
                                        defaultValue={newObj.processos}
                                    />
                                </TextInput>
                                :  <p>{client.processos}</p>
                            }
                        </DetailsContainer>
                    </>
                )}           

                {client.cpf && (
                    <>
                        <Divider />
                        <DetailsContainer>
                            <InfoHeader>
                                <div>
                                    <TbHammer size={22} color='var(--red)' style={{transform: 'rotate(-90deg)' }} />
                                    <p>Análise</p>
                                    {sectionEdit != 5 && isAdmin && (
                                        <TbPencil 
                                            onClick={() => handleActiveEdit(5)}
                                            size={22} 
                                            style={{ strokeWidth: 1.5, cursor: 'pointer' }} 
                                        />
                                    )}
                                </div>
                                {sectionEdit == 5 && (
                                    <EditButtons>
                                        <CancelButton onClick={() => {
                                            setSectionEdit(-1);
                                            setNewObj(client)
                                        }}>
                                            Cancelar
                                        </CancelButton>
                                        <SaveButton onClick={async () => await saveEdit()}>
                                            Salvar edição
                                        </SaveButton>
                                    </EditButtons>
                                )}
                            </InfoHeader>
                            {sectionEdit == 5
                                ? <TextArea>
                                    <textarea 
                                        name='analise'
                                        onChange={event => handleEdit('analise', event.target.value)}
                                        defaultValue={client.analise ? newObj.analise.split('<br/>').join('\n') : ""}
                                    />
                                </TextArea>
                                : <>
                                    {client.analise && client.analise.length > 0 &&
                                        client.analise.split('<br/>').map((item, index) => (
                                            <AnaliseText key={index}>{item}</AnaliseText>
                                        ))
                                    }
                                </>
                            }
                        </DetailsContainer>
                    </>
                )}               
                

                <Divider />

                <DetailsContainer>
                    <InfoHeader>
                        <div>
                            <TbBriefcase size={22} color='var(--red)' />
                            <p>Atendimentos</p>
                            {sectionEdit != 6 && (isAdmin || isManager)  && (
                                <TbPencil 
                                    onClick={() => handleActiveEdit(6)}
                                    size={22} 
                                    style={{ strokeWidth: 1.5, cursor: 'pointer' }} 
                                />
                            )}
                        </div>
                        {sectionEdit == 6 && (
                            <EditButtons>
                                <CancelButton onClick={() => {
                                    setSectionEdit(-1);
                                    setNewObj(client)
                                }}>
                                    Cancelar
                                </CancelButton>
                                <SaveButton onClick={async () => await saveEditReports()}>
                                    Salvar edição
                                </SaveButton>
                            </EditButtons>
                        )}
                    </InfoHeader>
                    <ReportsContent>
                        {clientReports && clientReports.map((item, index) => (
                            <Accordion
                                defaultExpanded
                                key={index}
                                sx={{
                                    boxShadow: 'none',
                                    borderBottom: '1px solid var(--grey1)',
                                    gap: 0,
                                    margin: 0,
                                    marginBottom: 0,
                                    marginTop: 0,
                                    padding: 0,
                                    paddingBottom: 1,
                                }}
                                style={{
                                    margin: 0,
                                    marginBottom: 0,
                                    marginTop: 0,
                                }}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    sx={{ 
                                        backgroundColor: 'transparent',      
                                        flexDirection: 'row',
                                        display: 'flex',  
                                    }}
                                    color='white'
                                >
                                    <ReportHeader>
                                        <p>{`${datetime.getFullDate(item.time)} - `}<span>{item.operatorName}</span></p>
                                        {sectionEdit == 6 && item.result != 'Contrato fechado por indicação'
                                            ? <>
                                                <Select 
                                                    isClearable={false} 
                                                    isSearchable={true} 
                                                    defaultValue={{label: item.result, value: item.result}}
                                                    closeMenuOnSelect={true} 
                                                    options={selectOptions} 
                                                    isMulti={false} 
                                                    styles={customStyle}
                                                    onChange={(item) => handleEditReports('result', item.value, index)}
                                                />
                                                <FiTrash2 
                                                    onClick={() => deleteReport(item)}
                                                    color='var(--red)' 
                                                    size={20} 
                                                    style={{ strokeWidth: 1.5, cursor: 'pointer' }}
                                                />
                                            </>
                                            : <ResultText>{item.result}</ResultText>
                                            }
                                    </ReportHeader>
                                </AccordionSummary>
                                <AccordionDetails
                                    sx={{
                                        padding: '5px 15px',
                                    }}
                                >
                                    <ReportDescription>
                                        {sectionEdit == 6
                                            ? <TextInput>
                                                <input 
                                                    name='description'
                                                    onChange={event => handleEditReports('description', event.target.value, index)}
                                                    defaultValue={item.description}
                                                />
                                            </TextInput>
                                            :  <p>{item.description}</p>
                                        }
                                    </ReportDescription>
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </ReportsContent>
                </DetailsContainer>

                <DetailsContainer>
                    <ActionsContent>
                        <ActionButton color='var(--grey2)' onClick={() => setModalHistory(true)}>
                            <ArticleOutlined color='var(--grey2)' />
                            Histórico do cliente
                        </ActionButton>

                        {client.cpf && isAdmin  && (
                            <ActionButton color='var(--grey2)' onClick={() => setModalLogs(true)}>
                                <img src={logIconGrey} alt='icon icon' />
                                Ver log do cliente
                            </ActionButton>
                        )}
                        
                        {isAdmin && !['done', 'foraDoMapa'].includes(client.selected) && (
                            <ActionButton color='var(--red)' onClick={() => setModalDeleteUser(true)}>
                                <FiTrash2 />
                                Excluir do mapa
                            </ActionButton>
                        )}
                    </ActionsContent>
                </DetailsContainer>
            </Content>

            <Modal
                isOpen={modalLogs}
                onRequestClose={() => setModalLogs(false)}
                overlayClassName="modal-overlay"
                className="modal-content-auto-width"
                contentLabel="Example Modal"
            >
                <ModalLogs 
                    clientCpf={client.cpf}
                    clientName={client.nome}
                    onClose={() => setModalLogs(false)}
                />
            </Modal>

            <Modal
                isOpen={modalHistory}
                onRequestClose={() => setModalHistory(false)}
                overlayClassName="modal-overlay"
                className="modal-content-auto-width"
                contentLabel="Example Modal"
            >
                <ModalDetailsHistory 
                    data={client}
                    onClose={() => setModalHistory(false)}
                />
            </Modal>

            <Modal
                isOpen={modalStreetView}
                onRequestClose={() => setModalStreetView(false)}
                overlayClassName="modal-overlay"
                className="modal-content-auto-width"
                contentLabel="Example Modal"
            >
                <ModalStreetView 
                    client={client}
                    onClose={() => setModalStreetView(false)}
                />
            </Modal>

            <Modal
                isOpen={modalDeleteUser}
                onRequestClose={() => {
                    setModalDeleteUser(false);
                }}
                overlayClassName="modal-overlay"
                className="modal-content-auto-width"
                contentLabel="Example Modal"
            >
                <ModalDeleteUser 
                    client={data}
                    onSuccess={() => {
                        setModalDeleteUser(false);
                        onClose();
                    }}
                    onCancel={() => {
                        setModalDeleteUser(false);
                }}/>
            </Modal>
        </Container>
    )
}

export default ModalDetailsAdmin;