import React from 'react';

import { 
    Container, 
    RoutePointItemLeft, 
    RoutePointItemInfo, 
    RoutePointItemRight, 
    PointDot, 
    IconContainer
} from './styles';

//Icons
import { FiTrash2 } from 'react-icons/fi';
import PointIcon from '../PointIcon';
import { markers } from '../../helpers/markers';

const RoutePointItem = ({ letter, clientName, clientEnd, especie, onRemove }) => {

    return <Container>
        <RoutePointItemLeft>
            <PointIcon text={letter} size={18} />

            <PointDot />
            <PointDot />
            <PointDot />
            <PointDot />
            <PointDot />
            <PointDot />
        </RoutePointItemLeft>
        <IconContainer>
            <img style={{ width: 15 }} src={markers.getMarkerIconsEspecie(especie)} alt='icon'/>
        </IconContainer>
        <RoutePointItemInfo>
            <h4>{clientName}</h4>
            <p>{clientEnd}</p>
        </RoutePointItemInfo>
        <RoutePointItemRight>
            <FiTrash2 onClick={onRemove} size={18} style={{strokeWidth: 1.5, cursor: 'pointer'}}/>
        </RoutePointItemRight>
    </Container>
}

export default RoutePointItem;