import { useContext, useEffect } from "react";
import { AuthContext } from "../contexts/AuthContext";

import { collection, query, onSnapshot, where } from "firebase/firestore";
import db from '../firebase/config';
import { ClientsContext } from "../contexts/ClientsContext";
import { mappers } from "../helpers/mappers";

export default function useMapRemovedClients(){
    const { isAdmin } = useContext(AuthContext);

    const { mapRemovedClients, setMapRemovedClients } = useContext(ClientsContext);

    //Get clientes do Banco de dados fora do mapa
    const getMapRemovedClients = async () => {        
        const consulta = query(collection(db, 'clientes'), where('selected', '==', 'foraDoMapa'));
        await onSnapshot(consulta, (querySnapshot) => {
            const mappedDocs = mappers.mapFirebaseDoc(querySnapshot)
            
            setMapRemovedClients(mappedDocs);
        });
    }

    useEffect(() => {
        if(isAdmin){
            if(!mapRemovedClients){
                getMapRemovedClients();
            }
        }
    }, [])

    return {
        mapRemovedClients: mapRemovedClients,
        loadingAttendClients: !mapRemovedClients ? true : false
    }
}