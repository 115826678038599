import React, { useEffect, useState, useContext } from 'react'

import {
    Container,
    Header,
    RoutesContainer,
    Divider,
    SwitchRoute,
    SwitchButton,
    RouteSection,
    RoutesCard,
    RoutesCardHeader,
    HistoryContainer,
    FilterContainer,
    FilteredDescription,
    DateDescription,
    FilterItem,
    PointDivider,
    HistoryHeader,
    HistoryCard,
    HistoryList,
    DividerLight,
} from './styles';

//Firebase and context
import { MapsContext } from '../../contexts/MapsContext';

import Modal from 'react-modal';

import routeOrange from '../../assets/routeOrange.svg'
import routeSuccess from '../../assets/routeSuccess.svg'
import routeYellow from '../../assets/routeYellow.svg'
import routeRed from '../../assets/routeRed.svg'

import { writeBatch, doc } from "firebase/firestore";
import db from '../../firebase/config';
import ProfileButton from '../../components/ProfileButton';
import CustomLoadingPage from '../../components/CustomLoadingPage';
import PointIcon from '../../components/PointIcon';
import RouteItem from '../../components/RouteItem';
import DateFilter from '../../components/DateFilter';
import ModalViewRoute from '../../components/ModalViewRoute';
import CustomFilterHeader from '../../components/CustomFilterHeader';
import { arrayHelper } from '../../helpers/arrayHelper';
import { filtersHelper } from '../../helpers/filtersHelper';
import useRoutes from '../../hooks/useRoutes';

const AdminRoutesPage = () => {
    const { maps, getMapsAdmin } = useContext(MapsContext);
    const { routes, fetchRoutes } = useRoutes();

    //Switch
    const [ switchSelected, setSwitchSelected ] = useState(0);

    const [ loadingData, setLoadingData ] = useState(true);
    const [ currentRoute, setCurrentRoute ] = useState('');

    //Route of day
    const [ routesOfDay, setRoutesOfDay ] = useState('');

    //History
    const [ historyData, setHistoryData ] = useState('');

    const [ filters, setFilters ] = useState([]);

    //Modal
    const [ modalViewRoute, setModalViewRoute ] = useState(false);

    useEffect(() => {
        getMapsAdmin()
        fetchRoutes()
    }, []);

    useEffect(() => {
        setLoadingData(true)
        if(routes && maps){
            const database = [];
            for(const route of routes){
                var atendido = 0;

                for(const point of route.points){
                    if(point.atendido){
                        atendido++
                    }
                }
                database.push({
                    ...route,
                    status: atendido < route.points.length && route.status == 'finalizado' 
                        ? 'parcial' : route.status
                });
            }

            const savedRoutes = arrayHelper.ordenateArrayDesc(database, 'time')

            const dateNow = new Date();
            const arrayRoutes = [];

            const ontemTime = dateNow.getTime() - (2*(86400*1000))

            const aguardando = savedRoutes.filter(x => x.status == 'aguardando' && x.time >= ontemTime );
            const iniciado = savedRoutes.filter(x => x.status == 'iniciado' && x.time >= ontemTime );
            const finalizado = savedRoutes.filter(x => (x.status == 'finalizado' || x.status == 'parcial') && x.time >= ontemTime );
            const cancelado = savedRoutes.filter(x => x.status == 'cancelado' && x.time >= ontemTime );
            

            setRoutesOfDay({
                aguardando: aguardando,
                iniciado: iniciado,
                finalizado: finalizado,
                cancelado: cancelado
            })

            for(const route of savedRoutes){
                var city = '';

                for(const point of route.points){
                    if(point.mapId){
                        const result = maps.find(x => x.docId == point.mapId);

                        if(result){
                            city = result.mapName
                        }
                    }
                }

                arrayRoutes.push({
                    ...route,
                    city: city
                })
            }

            setHistoryData(arrayRoutes);
        }
        setLoadingData(false)
    }, [routes, maps])

    const reOpenRoute = async (item) => {
        const batch = writeBatch(db);

        for(const point of item.points){
            const docRef = doc(db, "clientes", point.cpf);

            batch.update(docRef, {
                selected: item.uid,
            });
        }
        const docRef = doc(db, "rotas", item.docId);

        batch.update(docRef, {
            status: 'aguardando',
        });

        batch.commit().then(() => {
            alert("Rota re-aberta com sucesso");
        }).catch((error) => {
            alert("Erro ao re-abrir rota: ", error.code);
        })
        
    }

    const handleChangeDateFilter = (calendarValues, field) => {
        const exists = filters.findIndex(x => x.field === field);

        if(exists != -1){
            if(calendarValues?.selectedStart || calendarValues?.selectedEnd){
                const newObject = {
                    ...filters[exists],
                    values: calendarValues
                }
    
                const newArray = arrayHelper.replaceItemOfArray(filters, exists, newObject)
    
                setFilters(newArray)
            }else{
                const newArray = arrayHelper.removeItemOfArray(filters, exists)
    
                setFilters(newArray)
            }
        }else{
            const newObject = {
                field: field,
                type: 'date',
                reportType: 'atendimentos',
                values: calendarValues
            }
            
            setFilters([...filters, newObject])
        }
    }

    const filterTable = () => {
        return filtersHelper.filterDataTable(historyData, filters)
    }

    const getFilterDescription = () => {
        const exists = filters.find(x => x.field === 'routeCloseDate');

        if(exists){
            const selectedStart = exists?.values?.selectedStart;
            const selectedEnd = exists?.values?.selectedEnd;

            if(selectedStart && selectedEnd?.day){
                return `${selectedStart.day}/${selectedStart.month}/${selectedStart.year} - 
                    ${selectedEnd.day}/${selectedEnd.month}/${selectedEnd.year}`
            }

            if(selectedStart){
                return `${selectedStart.day}/${selectedStart.month}/${selectedStart.year}`
            }
        }else{
            return `Desde o início`
        }
    }

    if(loadingData || !routesOfDay || !historyData){
        return (
            <Container>
                <CustomLoadingPage />
            </Container>
        );
    }

    const tableRows = filters.length > 0 ? filterTable() : historyData

    return (
        <Container>
            <Header>
                <h1>Rotas</h1>
                <div>
                    {switchSelected == 1 && (
                        <CustomFilterHeader 
                            filters={filters}
                            filterTypes={[
                                { headerName: 'Status', field: 'status' },
                                { headerName: 'Operador', field: 'operatorName' },
                                { headerName: 'Mapa', field: 'city' }
                            ]}
                            dateField='time'
                            onChangeFilters={(newFiltersArray) => {
                                setFilters(newFiltersArray)
                            }}
                            onClearFilters={() => {
                                setFilters([])
                            }}      
                            tableRowsBackup={historyData}                      
                        />
                    )}
                    <ProfileButton arrowColor='var(grey2)' />
                </div>
            </Header>
            <SwitchRoute>
                <SwitchButton onClick={() => setSwitchSelected(0)}>
                    Rotas de hoje
                    {switchSelected == 0 && (<div></div>)}
                </SwitchButton>
                <SwitchButton onClick={() => setSwitchSelected(1)}>
                    Histórico
                    {switchSelected == 1 && (<div></div>)}
                </SwitchButton>
            </SwitchRoute>
            {switchSelected == 0 && (
                <RoutesContainer>
                    <> 
                        <RouteSection>
                            Aguardando
                            <PointIcon
                                size={30}
                                fontWeight='500'
                                fontSize='14'
                                backgroundColor='#4A5E83'
                                textColor='white'
                                text={routesOfDay.aguardando.length}
                            />
                        </RouteSection>
                        <RoutesCard>
                            <RoutesCardHeader>
                                <h3>Nome</h3>
                                <h3>Operador</h3>
                                <h3>Status</h3>
                                <h3>Criação</h3>
                                <h3>Progresso</h3>
                                <h3>Ações</h3>
                            </RoutesCardHeader>
                            {routesOfDay.aguardando.map((item, index) => (
                                <RouteItem 
                                    key={index} 
                                    data={item} 
                                    columns={6} 
                                    onViewRoute={() => {
                                        setCurrentRoute(item);
                                        setModalViewRoute(true)
                                    }}
                                />
                            ))}
                        </RoutesCard>
                    </>
                    

                    <> 
                        <RouteSection>
                            Em andamento
                            <PointIcon
                                size={30}
                                fontWeight='500'
                                fontSize='14'
                                backgroundColor='#4A5E83'
                                textColor='white'
                                text={routesOfDay.iniciado.length}
                            />
                        </RouteSection>
                        <RoutesCard>
                            <RoutesCardHeader columns={7}>
                                <h3>Nome</h3>
                                <h3>Operador</h3>
                                <h3>Status</h3>
                                <h3>Criação</h3>
                                <h3>Início</h3>
                                <h3>Progresso</h3>
                                <h3>Ações</h3>
                            </RoutesCardHeader>
                            {routesOfDay.iniciado.map((item, index) => (
                                <RouteItem 
                                    key={index} 
                                    data={item} 
                                    columns={7} 
                                    onViewRoute={() => {
                                        setCurrentRoute(item);
                                        setModalViewRoute(true)
                                    }}
                                />
                            ))}
                        </RoutesCard>
                    </>

                    <> 
                        <RouteSection>
                            Encerradas
                            <PointIcon
                                size={30}
                                fontWeight='500'
                                fontSize='14'
                                backgroundColor='#4A5E83'
                                textColor='white'
                                text={routesOfDay.finalizado.length}
                            />
                        </RouteSection>
                        <RoutesCard>
                            <RoutesCardHeader columns={8}>
                                <h3>Nome</h3>
                                <h3>Operador</h3>
                                <h3>Status</h3>
                                <h3>Criação</h3>
                                <h3>Início</h3>
                                <h3>Finalização</h3>
                                <h3>Progresso</h3>
                                <h3>Ações</h3>
                            </RoutesCardHeader>
                            {routesOfDay.finalizado.map((item, index) => (
                                <RouteItem 
                                    key={index} 
                                    data={item} 
                                    columns={8} 
                                    onViewRoute={() => {
                                        setCurrentRoute(item);
                                        setModalViewRoute(true)
                                    }}    
                                />
                            ))}
                        </RoutesCard>
                    </>

                    <> 
                        <RouteSection>
                            Canceladas
                            <PointIcon
                                size={30}
                                fontWeight='500'
                                fontSize='14'
                                backgroundColor='#4A5E83'
                                textColor='white'
                                text={routesOfDay.cancelado.length}
                            />
                        </RouteSection>
                        <RoutesCard>
                            <RoutesCardHeader columns={7}>
                                <h3>Nome</h3>
                                <h3>Operador</h3>
                                <h3>Status</h3>
                                <h3>Criação</h3>
                                <h3>Cancelamento</h3>
                                <h3>Progresso</h3>
                                <h3>Ações</h3>
                            </RoutesCardHeader>
                            {routesOfDay.cancelado.map((item, index) => (
                                <RouteItem 
                                    key={index} 
                                    data={item} 
                                    columns={7}
                                    history={false} 
                                    onViewRoute={() => {
                                        setCurrentRoute(item);
                                        setModalViewRoute(true)
                                    }}
                                />
                            ))}
                        </RoutesCard>
                    </>
                </RoutesContainer>
            )}     
            {switchSelected == 1 && (
                <HistoryContainer>
                    <FilterContainer>
                        <DateFilter 
                            filters={filters}
                            dateField='routeCloseDate'
                            onFilterDate={(values) => handleChangeDateFilter(values, 'routeCloseDate')} 
                            clearDateFilter={() => handleChangeDateFilter(null, 'routeCloseDate')}
                            onClose={() => {}}
                        />
                        <Divider />
                        
                        <FilteredDescription>
                            <h3>Exibindo:</h3>
                            <br />
                            <DateDescription>
                                {getFilterDescription()}
                            </DateDescription>
                            
                            <br />
                            <FilterItem>
                                <img src={routeOrange} alt='img img' />
                                <div>
                                    <h3>Rotas em andamento:</h3>
                                    <h4>{tableRows.filter(x => x.status == 'iniciado').length}</h4>
                                </div>
                            </FilterItem>
                            <PointDivider />
                            <FilterItem>
                                <img src={routeSuccess} alt='img img' />
                                <div>
                                    <h3>Rotas concluídas:</h3>
                                    <h4>{tableRows.filter(x => x.status == 'finalizado').length}</h4>
                                </div>
                            </FilterItem>
                            <PointDivider />
                            <FilterItem>
                                <img src={routeYellow} alt='img img' />
                                <div>
                                    <h3>Rotas parcialmente concluídas:</h3>
                                    <h4>{tableRows.filter(x => x.status == 'parcial').length}</h4>
                                </div>
                            </FilterItem>
                            <PointDivider />
                            <FilterItem>
                                <img src={routeRed} alt='img img' />
                                <div>
                                    <h3>Rotas canceladas:</h3>
                                    <h4>{tableRows.filter(x => x.status == 'cancelado').length}</h4>
                                </div>
                            </FilterItem>
                        </FilteredDescription>                        
                    </FilterContainer>
                    <HistoryCard>
                        <HistoryHeader>
                            <h3>Nome</h3>
                            <h3>Operador</h3>
                            <h3>Data de conclusão</h3>
                            <h3>Progresso</h3>
                            <h3>Ações</h3>
                        </HistoryHeader>
                        <HistoryList>
                        {tableRows.map((item, index) => (
                            <div key={item.docId}>
                                <RouteItem 
                                    data={item} 
                                    columns={5} 
                                    history={true} 
                                    onViewRoute={() => {
                                        setCurrentRoute(item);
                                        setModalViewRoute(true)
                                    }}
                                />
                                {index != historyData.length-1 && (
                                    <DividerLight />
                                )}
                            </div>
                        ))}
                        </HistoryList>
                        
                    </HistoryCard>
                </HistoryContainer>
            )}       
            <Modal
                isOpen={modalViewRoute}
                onRequestClose={() => setModalViewRoute(false)}
                overlayClassName="modal-overlay"
                className="modal-view-route"
                contentLabel="Example Modal"
            >
                <ModalViewRoute 
                    routeData={currentRoute} 
                    onClose={() => setModalViewRoute(false)}
                />
            </Modal>
        </Container>
    )
};

export default AdminRoutesPage;