import styled from "styled-components";

export const Container = styled.div`
    /* display: flex; */
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 256px;

    padding: 20px 34px;

    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    height: 200vh;
    width: calc(100% - 256px);


    background: var(--background);
    /* z-index: 5000; */
`;

export const Header = styled.div`
    width: 100%;
    height: 67px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 20px;

    div {
        display: flex;
        
        align-items: center;

        gap: 20px;
    }

    h1 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 300;
        font-size: 32px;
        line-height: 38px;
        /* identical to box height */


        /* 1 */

        color: #4A5E83;
    }

    div {
        display: flex;
        flex-direction: row;

        gap: 20px;
        align-items: center;
    }
`;

export const LogsContainer = styled.div`
    height: 93%;
    width: 100%;
    background: #FFFFFF;

    padding: 40px 20px;
    /* shadowcard */

    box-shadow: 0px 4px 20px rgba(35, 54, 63, 0.05);
    border-radius: 20px;
`;

export const ListHeader = styled.div`
    display: grid;
    grid-template-columns: repeat(6, 1fr) 0.5fr;
    min-height: 60px;
    width: 100%;

    padding: 0 10px;

    align-items: center;
    /* justify-content: center; */

    background: rgba(236, 242, 251, 0.5);
    border-radius: 10px;

    h3 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        /* identical to box height */


        /* 2 */

        color: #808B9F;
    }
`;

export const LogsListContainer = styled.div`
    display: flex;
    flex-direction: column;

    padding-top: 10px;

    overflow-y: auto;
    height: 95%;
`;

export const LogItem = styled.div`
    display: grid;
    grid-template-columns: repeat(6, 1fr) 0.5fr;

    padding: 0 15px;
    cursor: pointer;

    align-items: center;
    min-height: 40px;

    gap: 5px;

    div {
        display: flex;
        flex-direction: row;
        align-items: center;

        max-width: 95%;

        color: ${props => props.color};

        gap: 10px;
    }

    h4 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;

        color: #808B9F;
    }
`;

export const ModalContainer = styled.div`
    min-width: 500px;
    padding: 20px;
`;

export const SubHeader = styled.div`
    display: flex;
    flex-direction: row;

    align-items: center;

    gap: 20px;

    margin: 20px 0;

    color: var(--red);

    div {
        display: flex;
        flex-direction: row;

        align-items: center;
        gap: 5px;
    }

    h4 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;

        /* 2 */

        color: #808B9F;
    }
`;

export const ModalListHeader = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr) 0.5fr;
    min-height: 60px;
    width: 100%;

    padding: 0 10px;

    align-items: center;
    /* justify-content: center; */

    background: rgba(236, 242, 251, 0.5);
    border-radius: 10px;

    h3 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        /* identical to box height */


        /* 2 */

        color: #808B9F;
    }
`;

export const ModalListContainer = styled.div`
    display: flex;
    flex-direction: column;

    padding-top: 10px;
    overflow-y: auto;
    height: 450px;
`;

export const ModalLogItem = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr) 0.5fr;

    padding: 0 15px;

    align-items: center;
    min-height: 40px;

    gap: 5px;

    div {
        display: flex;
        flex-direction: row;
        align-items: center;

        max-width: 95%;

        color: ${props => props.color};

        gap: 10px;
    }

    h4 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;

        color: #808B9F;
    }
`;