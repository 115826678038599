import { especiesColor } from '../constants/especiesColors';

export const especiesHelper = {
    getEspecieTag
}

function getEspecieTag(especie){
    if(especie){
        const find =  especiesColor.find(x => x.especie == especie);

        if(find && find?.tagColor && find?.textColor){
            return {
                tagColor: find.tagColor,
                textColor: find.textColor
            }
        }
    }
    
    return {
        tagColor: '#808B9F',
        textColor: "#FFFFFF"
    }
}