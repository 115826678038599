import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import { RoutesContext } from '../../contexts/RoutesContext';
import ModalHeader from '../ModalHeader';

import { 
    Container, 
    CardsContainer, 
    ReturnCard, 
    ReturnCardHeader, 
    Divider, 
    ReturnCardNumber, 
    ReturnsList, 
    ReturnsListHeader, 
    ReturnsListContent, 
    ListItem, 
    ListClientName, 
    ListEspecie, 
    ListDate, 
    ListOperator, 
    ListOptions, 
    DividerHeader,
    Content
} from './styles';

import pendingIcon from '../../assets/pending.png';
import inRouteIcon from '../../assets/inRoute.png';
import expiredIcon from '../../assets/expired.png';
import { AiOutlineEye } from 'react-icons/ai'

import Avatar from '@mui/material/Avatar';
import CustomLoading from '../CustomLoading';
import { avatar } from '../../helpers/avatar';
import { filtersHelper } from '../../helpers/filtersHelper';
import CustomFilterHeader from '../CustomFilterHeader';
import useAttendClients from '../../hooks/useAttendClients';

const especies = [
    'LOAS DEF',
    'LOAS IDOSO',
    'AUX. DOENÇA'
]

function ModalReturnsOperator({ reports, onClose, onClickView }) {

    const { userData } = useContext(AuthContext);
    const { reportsUser } = useAttendClients();
    const { routesUser } = useContext(RoutesContext);

    const [ filters, setFilters ] = useState([]);
    const [ seletedType, setSelectedType ] = useState('all');

    const [ cardsData, setCardsData ] = useState('')

    useEffect(() => {
        if(reportsUser && routesUser){
            processReturns()
        }
    }, [reportsUser, routesUser, reports])

    const processReturns = (seletedType) => {
        const arrayPending = [];
        const arrayExpired = [];
        const arrayInRoute = [];

        for(const report of reports){
            if(report.atraso){
                arrayExpired.push({
                    ...report,
                    reportStatus: 'Expirado'
                });
            }else{
                if(report.reportStatus === 'Em Rota'){
                    arrayInRoute.push(report)
                }else{
                    arrayPending.push({
                        ...report,
                        reportStatus: 'Pendente'
                    })
                }
            }
        }    

        if(routesUser){
            for(const route of routesUser){
                for(const routePoint of route.points){
                    if(routePoint.retornos > 0){
                        const report = reportsUser.find(x => x.clientCpf === routePoint.cpf);

                        if(report){
                            arrayInRoute.push({
                                ...report,
                                ...routePoint,
                                reportStatus: 'Em Rota'
                            })
                        }
                    }
                }
            }
        }

        setCardsData({
            pending: arrayPending,
            inRoute: arrayInRoute,
            expired: arrayExpired,
            all: arrayPending.concat(arrayExpired).concat(arrayInRoute)
        })
    }

    const filterByTable = (baseTableData) => {
        return filtersHelper.filterDataTable(baseTableData, filters)
    }

    const clearFilters = () => {
        setFilters([])
    }

    if(!cardsData){
        return <Container>
            <CustomLoading background='transparent' />
        </Container>
    }

    const baseTableData = cardsData[seletedType]

    const tableData = filters.length > 0  ? filterByTable(baseTableData) : baseTableData

    const backupTableData = cardsData.all;

    return (
        <Container>
            <ModalHeader title='Retornos' onClose={onClose} />
            <Content>
                <CardsContainer>
                    <ReturnCard onClick={() => {
                        if(seletedType === 'pending'){
                            setSelectedType('all')
                        }else{
                            setSelectedType('pending')
                        }
                    }}>
                        <ReturnCardHeader>
                            <img src={pendingIcon} />
                            <h3>Retornos pendentes</h3>
                        </ReturnCardHeader>
                        <DividerHeader />
                        <ReturnCardNumber color='#F5C87B'>
                            {cardsData.pending.length}
                        </ReturnCardNumber>
                    </ReturnCard>

                    <ReturnCard onClick={() => {
                        if(seletedType === 'inRoute'){
                            setSelectedType('all')
                        }else{
                            setSelectedType('inRoute')
                        }
                    }}>
                        <ReturnCardHeader>
                            <img src={inRouteIcon} />
                            <h3>Retornos em rota</h3>
                        </ReturnCardHeader>
                        <DividerHeader />
                        <ReturnCardNumber color='#9BBEFF'>
                            {cardsData.inRoute.length}
                        </ReturnCardNumber>
                    </ReturnCard>

                    <ReturnCard onClick={() => {
                        if(seletedType === 'expired'){
                            setSelectedType('all')
                        }else{
                            setSelectedType('expired')
                        }
                    }}>
                        <ReturnCardHeader>
                            <img src={expiredIcon} />
                            <h3>Retornos expirados</h3>
                        </ReturnCardHeader>
                        <DividerHeader />
                        <ReturnCardNumber color='#DE6161'>
                            {cardsData.expired.length}
                        </ReturnCardNumber>
                    </ReturnCard>
                </CardsContainer>
                <ReturnsList>
                    <ReturnsListHeader>
                        <h3>Seus retornos</h3>
                        <div>
                            <CustomFilterHeader 
                                dateField='time'
                                filters={filters}
                                filterTypes={[
                                    { headerName: 'Data', field: 'dataRetorno' }, 
                                    { headerName: 'Status', field: 'reportStatus'}
                                ]}
                                tableRowsBackup={backupTableData}
                                onChangeFilters={(newFiltersArray) => {
                                    setFilters(newFiltersArray)
                                }}
                                onClearFilters={() => clearFilters()}
                            />
                        </div>
                    </ReturnsListHeader>
                    <ReturnsListContent>
                        {tableData.map((item, index) => {
                            var especieColor = '#DE6161'
                            if(item.especie == especies[0]){
                                especieColor = '#808B9F'
                            }
                            if(item.especie == especies[1]){
                                especieColor = '#9BBEFF'
                            }
                            return (
                                <>
                                    <ListItem key={index}>
                                        <ListClientName>
                                            {item.clientName}
                                        </ListClientName>
                                        <ListEspecie color={especieColor}>
                                            <h4>
                                                {item.especie}
                                            </h4>
                                        </ListEspecie>
                                        <ListDate>
                                            {item.dataRetorno || item.horaRetorno 
                                                ? `${item.dataRetorno} - ${item.horaRetorno}`
                                                : 'Sem data e hora'
                                            }
                                        </ListDate>
                                        <ListOperator>
                                            <Avatar 
                                                src={avatar.getAvatarByData(userData)}
                                                sx={{ marginRight: 1, height: 24, width: 24}}
                                            />
                                            <h4>
                                                {item.operatorName}
                                            </h4>
                                        </ListOperator>
                                        <ListOptions>
                                            <AiOutlineEye 
                                            onClick={() => onClickView(item)} 
                                            cursor='pointer' 
                                            size={20} 
                                            color='#808B9F' 
                                            fill='#808B9F' 
                                        />
                                        </ListOptions>
                                    </ListItem>
                                    <Divider />
                                </>
                            )   
                        })}
                    </ReturnsListContent>
                </ReturnsList>
            </Content>
        </Container>
    )
}

export default ModalReturnsOperator;