import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px 25px;
    width: 412px;
    /* height: 354px; */
`;

export const FieldText = styled.p`
    height: 24px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 24px;

    margin-bottom: 5px;

    color: #4A5E83;
`;

export const TextInput = styled.div`
    box-sizing: border-box;
    display: flex;

    width: 100%;
    height: 44px;
    align-items: center;

    border: 1px solid var(--grey1);
    border-radius: 100px;
    margin-bottom: 20px;
`;

export const Divider = styled.div`
    margin: 20px 0px;
    border: 1px solid #ECF2FB;
    align-self: center;
    width: 100%;
`;


export const ActionsContent = styled.div`
    display: flex;
    flex-direction: row;
    align-items: bottom;
    justify-content: right;
    gap: 10px;
    padding: 18px 0;
`;

export const CancelButton = styled.div`
     display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    padding: 10px;

    p {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;

        color: var(--grey2);
    }
`;


export const SaveButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    padding: 12px 58px 12px 58px;

    background: #DE6161;
    box-shadow: 0px 7px 10px rgba(228, 73, 73, 0.25);
    border-radius: 100px;

    p {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        /* identical to box height, or 171% */

        text-align: center;

        /* 5 */

        color: var(--white);

    }
`;