import React, { useContext, useEffect, useState } from 'react';

import { 
    Container, 
    Header, 
} from './styles';

//Firebase and Context
import { UsersContext } from '../../contexts/UsersContext';
import { writeBatch, doc } from "firebase/firestore";
import db from '../../firebase/config';

//Custom components
import ProfileButton from '../../components/ProfileButton';
import OperatorsCard from '../../components/OperatorsCard';
import CustomTable from '../../components/CustomTable';

//Utils
import tableColumnsReturns from '../../resources/tableColumnsReturns.json';
import { arrayHelper } from '../../helpers/arrayHelper';
import { AuthContext } from '../../contexts/AuthContext';
import { filtersHelper } from '../../helpers/filtersHelper';
import useRoutes from '../../hooks/useRoutes';
import useExpiredReturns from '../../hooks/useExpiredReturns';
import useAttendClients from '../../hooks/useAttendClients';
import ReturnsCards from './components/ReturnsCards';
import { reportResultType } from '../../constants/reportResultType';
import { searchHelper } from '../../helpers/searchHelper';
import useMapReturns from '../../hooks/useMapReturns';

function AdminReturnsPage() {

    const { user, isAdmin } = useContext(AuthContext);

    const { users } = useContext(UsersContext);
    const { returnMapClients } = useMapReturns();
    const { attendClients, reports } = useAttendClients();
    const { expiredReturnsClients, fetchExpiredReturns } = useExpiredReturns();
    const { routes, fetchRoutes } = useRoutes();

    //Cards
    const [ operatorsData, setOperatorsData ] = useState('');
    const [ cardsData, setCardsData ] = useState('')

    //OperatorsCard
    const [ operatorsCardFilters, setOperatorsCardFilters ] = useState([])

    //filters and search
    const [ filters, setFilters ] = useState([])
    const [ search, setSearch ] = useState('');

    useEffect(() => {
        if(attendClients && users && returnMapClients){
            fetchRoutes()
            fetchExpiredReturns()
            processReportsCards(attendClients)
        }
    }, [attendClients, users, expiredReturnsClients, returnMapClients])

    useEffect(() => {
        if(cardsData){
            processOperatorsCard(cardsData)
        }
    }, [cardsData])

    const processReportsCards = (attendClients) => {
        const adminUids = arrayHelper.reduceToSimple(users.admins, 'uid').concat([users.rootUid]);

        if(isAdmin){
            adminUids.push(user.uid)
        }

        const notAttendByAdmin = attendClients.filter(x => !adminUids.includes(x.operatorUid))

        const expiredReturnsCpfs = arrayHelper.reduceToSimple(expiredReturnsClients, 'cpf');

        const realizedReturns = notAttendByAdmin.filter(x => x.result != reportResultType.RETURN && x.retornos > 0)
        const signedAfterReturn = notAttendByAdmin.filter(x => x.result == reportResultType.SIGNED && x.retornos > 0)

        const pendingReturns = []
        const expiredReturns = []
        for(const client of returnMapClients){
            const report = reports.find(x => x.docId === client.reportId);

            const operator = users?.operators?.find(x => x.uid === client.operatorUid)
        
            if(report && operator){
                if(expiredReturnsCpfs.includes(client.cpf)){
                    expiredReturns.push({
                        ...report,
                        operatorUid: client.operatorUid,
                        operatorName: operator.userName
                    })
                }else{
                    pendingReturns.push({
                        ...report,
                        operatorUid: client.operatorUid,
                        operatorName: operator.userName
                    })
                }
            }
        }

        const inRouteReturns = []
        for(const route of routes){
            if(route.status == 'iniciado' || route.status == 'aguardando'){
                if(route.points){
                    for(const routePoint of route.points){
                        if(routePoint.retornos > 0){
                            const report = reports.find(x => x.docId === routePoint.reportId);
        
                            if(report){
                                inRouteReturns.push({
                                    ...routePoint,
                                    ...report,
                                    operatorUid: route.uid,
                                    operatorName: route.operatorName,
                                    time: report.time,
                                    routeStatus: route.status,
                                    routeTime: route.time,
                                })
                            }
                        }
                    }
                }
            }
        }

        setCardsData({
            realizedReturns: arrayHelper.ordenateArrayDesc(realizedReturns, 'time'),
            pendingReturns: arrayHelper.ordenateArrayDesc(pendingReturns, 'time'),
            inRouteReturns: arrayHelper.ordenateArrayDesc(inRouteReturns, 'time'),
            expiredReturns: arrayHelper.ordenateArrayDesc(expiredReturns, 'time'),
            closedByReturns: arrayHelper.ordenateArrayDesc(signedAfterReturn, 'time'), 
            total: arrayHelper.ordenateArrayDesc(realizedReturns.concat(pendingReturns, 
                inRouteReturns, expiredReturns, signedAfterReturn), 'time')
        })
    }

    const processOperatorsCard = (data) => {

        const array = [];
        var arrayUsers = users.operators.filter(x => x.disabled != true);

        array.push({
            userName: 'Geral',
            img64: null,
            realizedReturns: data.realizedReturns,
            pendingReturns: data.pendingReturns,
            inRouteReturns: data.inRouteReturns,
            expiredReturns: data.expiredReturns,
            closedByReturns: data.closedByReturns,
            total: data.total
        })

        for(const user of arrayUsers){
            const findRealized = data.realizedReturns.filter(x => x.operatorUid === user.uid);
            const findPending = data.pendingReturns.filter(x => x.operatorUid === user.uid);
            const findInRoute = data.inRouteReturns.filter(x => x.operatorUid === user.uid);
            const findExpired = data.expiredReturns.filter(x => x.operatorUid === user.uid);
            const findClosedByReturn = data.closedByReturns.filter(x => x.operatorUid === user.uid);
            
            array.push({
                ...user,
                realizedReturns: arrayHelper.ordenateArrayDesc(findRealized, 'time'),
                pendingReturns: arrayHelper.ordenateArrayDesc(findPending, 'time'),
                inRouteReturns: arrayHelper.ordenateArrayDesc(findInRoute, 'time'),
                expiredReturns: arrayHelper.ordenateArrayDesc(findExpired, 'time'),
                closedByReturns: arrayHelper.ordenateArrayDesc(findClosedByReturn, 'time'),
                total: arrayHelper.ordenateArrayDesc(findRealized.concat(findPending, findInRoute, 
                    findExpired, findClosedByReturn), 'time')
            })
        }

        setOperatorsData(array);
    }

    useEffect(() => {
        if(cardsData?.pending){
            expireReturns(cardsData.pending)
        }
    }, [cardsData])

    function formatNumber(number){
        if(number < 10){
            return `0${number}`
        }else{
            return number
        }
    }

    const expireReturns = (data) => {

        const batch = writeBatch(db);

        const dateNow = new Date();

        for(const report of data){
            const clientDoc = returnMapClients.find(x => x.cpf == report.clientCpf);

            if(clientDoc && clientDoc?.forwardTime){
                if(dateNow.getTime() > (clientDoc.forwardTime+((86400*1000) * 10)) && !clientDoc?.atraso){

                    const docRef = doc(db, "clientes", report.clientCpf);
    
                    batch.update(docRef, {
                        atraso: true,
                        atrasoTime: dateNow.getTime()
                    });
                }
            }else{
                if(clientDoc && dateNow.getTime() > (report.time+((86400*1000) * 10)) && !clientDoc?.atraso){

                    const docRef = doc(db, "clientes", report.clientCpf);
    
                    batch.update(docRef, {
                        atraso: true,
                        atrasoTime: dateNow.getTime()
                    });
                }
            }

            if(report.dataRetorno){
                const textArray = report.dataRetorno.split('/');

                var stringHora = null;


                const stringDate = `${textArray[2]}-${formatNumber(textArray[1])}-${formatNumber(textArray[0])}`;

                if(report.horaRetorno){
                    const horaArray = report.horaRetorno.split(':');

                    stringHora = `${formatNumber(horaArray[0])}:${formatNumber(horaArray[1])}`;
                }

                const finalString = stringHora ? `${stringDate}T${stringHora}` : stringDate;
                
                const reportTime = new Date(finalString).getTime()

                if(dateNow.getTime() > reportTime){
                    const docRef = doc(db, "clientes", report.clientCpf);

                    batch.update(docRef, {
                        atraso: true,
                        atrasoTime: dateNow.getTime()
                    });
                }
            }
        }

        if(data.length > 0){
            batch.commit()
        }
    }

    const filterByOperatorCard = () => {
        const operatorUid = operatorsCardFilters[0]
        const type = operatorsCardFilters[1]

        const operatorCardData = operatorUid ? operatorsData.find(x => x.uid === operatorUid) : operatorsData[0]

        if(operatorCardData){
            if(type){
                switch(type){
                    case "realized":
                        return operatorCardData.realizedReturns
                    case "pending":
                        return operatorCardData.pendingReturns
                    case "inRoute":
                        return operatorCardData.inRouteReturns
                    case "expired":
                        return operatorCardData.expiredReturns
                    case "closed":
                        return operatorCardData.closedByReturns
                }
            }else{
                return operatorCardData.total
            }
        }

        return []
    }

    const filterByTable = () => {
        if(cardsData?.total){
            return filtersHelper.filterDataTable(cardsData?.total, filters)
        }else{
            return []
        }
        
    }

    const clearFilters = () => {
        setFilters([])
        setSearch('')
    }

    const baseTableData = operatorsCardFilters.length > 0  
        && operatorsData ? filterByOperatorCard() : filterByTable() 

    const backupTableData = operatorsCardFilters.length > 0  
        && operatorsData ? filterByOperatorCard() : cardsData ? cardsData?.total : []

    const tableData = search ? 
        searchHelper.searchTable(search, tableColumnsReturns, backupTableData) : baseTableData

    return (
        <Container>
            <Header>
                <h1>Central de Retornos</h1>
                <ProfileButton arrowColor='var(grey2)' />
            </Header>

            <ReturnsCards cardsData={cardsData} />

            <OperatorsCard 
                operatorsData={operatorsData}
                onFilter={(newData) => {
                    clearFilters()
                    setOperatorsCardFilters(newData)
                }} 
                unFilter={() => {
                    clearFilters()
                    setOperatorsCardFilters([])
                }}
            />

            <CustomTable 
                title="Clientes com retorno"
                tableColumns={tableColumnsReturns} 
                tableRows={tableData} 
                tableRowsBackup={backupTableData}
                filterTypes={tableColumnsReturns} 
                columns={7}
                filtersHighlights={[
                    {
                        field: 'time',
                        headerName: 'Data'
                    },
                    {
                        field: 'operatorName',
                        headerName: 'Operador'
                    },
                    {
                        field: 'result',
                        headerName: 'Resultado'
                    }
                ]}
                dateField='time'
                search={search}
                setSearch={setSearch}
                filters={filters}
                onChangeFilters={(newFiltersArray) => {
                    setFilters(newFiltersArray)
                }}
                onClearFilters={() => clearFilters()}
            />
        </Container>
    )
}

export default AdminReturnsPage;