import styled from 'styled-components';

export const Container = styled.div`
    display: grid;
    grid-template-columns: ${props => props.columns == 6 ? `repeat(5, 1fr) 0.5fr` : props.columns == 5 ? `repeat(4, 1fr) 0.5fr`
    : `repeat(3, 1fr) 0.5fr repeat(${props.columns-6}, 0.75fr) 1fr 0.5fr}`};

    padding: 0 15px;
    cursor: pointer;
    user-select: none;

    align-items: center;
    min-height: 59px;

    gap: 5px;

    div {
        display: flex;
        flex-direction: row;
        align-items: center;

        max-width: 95%;

        gap: 10px;
    }

    h4 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;

        color: #808B9F;
    }
`;

export const RouteName = styled.div`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    width: 80%;
    /* 1 */

    color: #4A5E83;
`;

export const Options = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    gap: 5px;
`;