import { query, collection, where, orderBy, getDocs } from "firebase/firestore";
import db from '../../config';

export async function getUserLogs(userId){

    const docsArray = [];

    const dateNow = new Date();

    var monthStart = dateNow.getMonth()+1 - 3;
    var yearStart = dateNow.getFullYear();

    if(monthStart <= 0){
        monthStart = monthStart + 12
    }

    if(dateNow.getMonth()+1 <= 3){
        yearStart = yearStart - 1
    }

    const dateStart = new Date(`${monthStart}/${dateNow.getDate()}/${yearStart}`).getTime();
    const dateEnd = dateNow.getTime();

    const consulta = query(collection(db, `usersLogs/${userId}/logs`), 
        where("time", ">=", dateStart), 
        where("time", "<=", dateEnd),
        orderBy("time", 'desc'));

    const querySnapshot = await getDocs(consulta);

    
    querySnapshot.forEach(async (doc) => {
        docsArray.push(doc.data())
    });

    return docsArray 
}