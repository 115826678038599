import React from 'react';
import { badges } from '../../helpers/badges';

import { Container, ClientBadgeFake, ClientBadgeItem, ContainerModalDetails } from './styles';

function ClientBadges({data, isModalDetails}) {

  if(isModalDetails){
    return (
      <ContainerModalDetails>
        {badges.getClientBadges(data).map((item, index) => (
          <ClientBadgeItem key={index} src={item.path} title={item.title} />
        ))}
      </ContainerModalDetails>
    )
  }else{
    return (
      <Container>
          {badges.getClientBadges(data).length === 1
            ? <>
              <ClientBadgeFake />
              {badges.getClientBadges(data).map((item, index) => (
                <ClientBadgeItem key={index} src={item.path} title={item.title} />
              ))}
              <ClientBadgeFake />
            </>
            :<>
              <ClientBadgeFake />
              {badges.getClientBadges(data).map((item, index) => (
                <ClientBadgeItem key={index} src={item.path} title={item.title} />
              ))}
            </>
          }
      </Container>
    )
  }
}

export default ClientBadges;