import { arrayHelper } from "./arrayHelper"
import { datetime } from "./datetime";

export const filtersHelper = {
    filterDataTable,
    filterCalendar,
    getFilterOptions,
    getDefaultValues
}

function filterDataTable(array, filters) {
    let baseData = array;

    for (const filter of filters) {
        if(filter.field === 'log' || filter.field === 'logOperator'){
            baseData = filterLogs(baseData, filter)
        }else{
            if (filter.type === 'string') {
                const values = arrayHelper.reduceToSimple(filter.values, 'value');
    
                baseData = baseData.filter(x => values.includes(x[filter.field]))
            }
    
            if(filter.type === 'date' && filter.values){
                baseData = filterCalendar(baseData, filter)
            }
        }
    }

    return baseData
}

function filterCalendar(data, filter) {

    const { field, reportType } = filter;
    const { selectedStart, selectedEnd } = filter.values;

    const startTime = new Date(`${selectedStart.month}/${selectedStart.day}/${selectedStart.year}`).getTime();
    const endTime = selectedEnd.day
        ? new Date(`${selectedEnd.month}/${selectedEnd.day}/${selectedEnd.year}`).getTime()
        : startTime + (86400*1000)

    const arrayValues = [];
    const newArray = [];


    for (var i = startTime; i <= endTime; i = i + (86400 * 1000)) {
        const date = new Date(i);

        if (reportType == 'retornos') {
            const stringDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;

            arrayValues.push(stringDate)
        }

        if (reportType == 'atendimentos') {
            const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
            const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
            const year = date.getFullYear();

            const stringDate = `${day}/${month}/${year}`;

            arrayValues.push(stringDate)
        }
    }

    for (const date of arrayValues) {
        if (reportType == 'retornos') {
            const result = data.find(x => x.dataRetorno === date);

            if (result) {
                newArray.push(result)
            }
        }

        if (reportType == 'atendimentos') {
            for (const item of data) {
                if (item[field]) {
                    if (datetime.getDate(item[field]) === date) {
                        newArray.push(item)
                    }
                }
            }
        }
    }

    return newArray
}

function filterLogs(data, filter){
    const newArrayDocs = []

    for(const log of data){
        if(log.logs){
            const newLogs = []

            for(const text of log.logs){
                for(const item of filter.values){
                    if(text.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase().includes(
                        item.value.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase())
                        || item.value.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase().includes(
                            text.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase()))
                    {
                        newLogs.push(text)
                    }
                }
            }

            newArrayDocs.push({
                ...log,
                logs: newLogs
            })
        }
        
    }
    
    return newArrayDocs
}

function getFilterOptions(array, field, filters) {
    let baseData = array;

    for (const filter of filters) {
        if (filter.type === 'string' && filter.field != field) {
            const values = arrayHelper.reduceToSimple(filter.values, 'value');

            if (values.length > 0) {
                baseData = baseData.filter(x => values.includes(x[filter.field]))
            }
        }

        if(filter.type === 'date' && filter.values){
            baseData = filterCalendar(baseData, filter)
        }
    }

    const options = []

    for (const item of baseData) {
        const exists = options.find(x => x.value === item[field])

        if (!exists) {
            options.push({ label: item[field], value: item[field] })
        }
    }

    return options
}

function getDefaultValues(field, filters) {
    if (!filters) {
        return
    }
    const find = filters.find(x => x.field === field);

    if (find) {
        return find.values
    }

    return null
}