import React, { useEffect, useState } from 'react';

import { Container, Content, ActionsContent, CancelButton, ConfirmButton, ModalConfirmHeader, ModalConfirmText } from './styles';

import { IoWarningOutline } from 'react-icons/io5';
import CustomLoading from '../CustomLoading';

const ModalConfirm = ({ data, loading, onCancel, onConfirm }) => {

    return (
        <Container>
            <Content>
                <IoWarningOutline size={80}/>
                <ModalConfirmText>
                    {data.text}
                </ModalConfirmText>
            </Content>
            <ActionsContent>
                {loading
                    ? <CustomLoading />
                    : <>
                        <CancelButton onClick={onCancel}>
                            <p>Voltar</p>
                        </CancelButton>
                        <ConfirmButton onClick={onConfirm}>
                            <p>{data.option}</p>
                        </ConfirmButton>
                    </>
                }
            </ActionsContent>
        </Container>
    )
}

export default ModalConfirm;