
export const datetime = {
    getDate,
    getFullDate,
    getHours,
    getMonthIntervalMs,
    getDate00
}

function getFullDate(time){

    const dateNow = new Date(time);

    const day = dateNow.getDate() < 10 ? `0${dateNow.getDate()}` : dateNow.getDate();
    const month = dateNow.getMonth()+1 < 10 ? `0${dateNow.getMonth()+1}` : dateNow.getMonth()+1;
    const year = dateNow.getFullYear();

    const hours = dateNow.getHours() < 10 ? `0${dateNow.getHours()}` : dateNow.getHours();
    const minutes = dateNow.getMinutes() < 10 ? `0${dateNow.getMinutes()}` : dateNow.getMinutes();
    const seconds = dateNow.getSeconds() < 10 ? `0${dateNow.getSeconds()}` : dateNow.getSeconds();

    const fullDate = `${day}/${month}/${year} - ${hours}:${minutes}:${seconds}`;

    return fullDate
}

function getDate(time){

    const dateNow = new Date(time);

    const day = dateNow.getDate() < 10 ? `0${dateNow.getDate()}` : dateNow.getDate();
    const month = dateNow.getMonth()+1 < 10 ? `0${dateNow.getMonth()+1}` : dateNow.getMonth()+1;
    const year = dateNow.getFullYear();

    const fullDate = `${day}/${month}/${year}`;

    return fullDate
}

function getHours(time){

    const dateNow = new Date(time);

    const hours = dateNow.getHours() < 10 ? `0${dateNow.getHours()}` : dateNow.getHours();
    const minutes = dateNow.getMinutes()+1 < 10 ? `0${dateNow.getMinutes()+1}` : dateNow.getMinutes()+1;

    const fullDate = `${hours}:${minutes}`;

    return fullDate
}

function getMonthIntervalMs(time){
    const dateNow = new Date(time)

    const month = dateNow.getMonth();
    const year = dateNow.getFullYear();

    const timeStart = new Date(year, month, 1).getTime();
    const timeEnd = new Date(year, month+1, 1).getTime(); //get first day of next month

    return {
        timeStart: timeStart,
        timeEnd: timeEnd-1000, //subtract 1 second to get 23h59m of yesterday
    }
}

function getDate00(time){
    const date = new Date(time);

    const time00 = new Date(date.getFullYear(), date.getMonth(), date.getDate())

    return time00
}