import { USER_TYPE } from "../constants/userType";

export const claims = {
    checkUserRole,
}

function checkUserRole(profile){

    if(profile.root == true){
        return USER_TYPE.ROOT;
    }

    if(profile.admin == true){
        return USER_TYPE.ADMIN;
    }

    if(profile.manager == true){
        return USER_TYPE.MANAGER
    }

    return USER_TYPE.OPERATOR
}