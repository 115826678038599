import React from 'react';

import { Container } from './styles';

import DotLoader from "react-spinners/ClipLoader";

const CustomLoading = ({ background, padding, size }) => {
    return (
        <Container background={background} padding={padding}>
            <DotLoader
                size={size ? size : 60}
                color={"var(--red)"}
                loading={true}
                speedMultiplier={1.5}
            />
        </Container>
    )
}

export default CustomLoading;