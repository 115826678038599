import React, { useState } from 'react';

import { AiOutlineCloseCircle } from 'react-icons/ai'

import { Container, Title  } from './styles';

const ModalHeader = ({ title, onClose }) => {

    return <Container>
        <Title>{title}</Title>   
        <AiOutlineCloseCircle onClick={onClose} strokeWidth={0.5} cursor='pointer' size={22} />   
    </Container>
}

export default ModalHeader;