
const validEndNames = ['logradouro', 'numero', 'complemento', 'bairro', 'cidade', 'estado', 'cep'];

export const address = {
    formatEnd, 
    formatValidEnd,
    formatStringEnd
}

const hasContent = (string) => {
    if(!string || string == '' || string == 'N/D'){
        return false
    }
    
    return true
}

function formatEnd(client){
    var string;

    for(const endName of validEndNames){
        if(client[`${endName}${client.validEnd}`] != 'N/D'){
            const value = client[`${endName}${client.validEnd}`];
            
            if(hasContent(value)){
                if(endName == 'logradouro'){
                    string = value
                }else{
                    if(endName == 'cidade' || endName == 'estado'){
                        string = string + ' - ' + value
                    }else{
                        string = string + ', ' + value
                    }
                }
            }
        }
    }

    if(string == undefined){
        string = 'N/D';
    }

    return string;
}

function formatValidEnd(client, validEnd){
    var string;

    for(const endName of validEndNames){
        if(client[`${endName}${validEnd}`] != 'N/D'){
            const value = client[`${endName}${validEnd}`];

            if(hasContent(value)){
                if(endName == 'logradouro'){
                    string = value
                }else{
                    if(endName == 'cidade' || endName == 'estado'){
                        string = string + ' - ' + value
                    }else{
                        string = string + ', ' + value
                    }
                }
            }
        }
    }

    if(string == undefined){
        string = 'N/D';
    }

    return string;
}

function formatStringEnd(client){
    var string;

    for(const endName of validEndNames){
        if(client[endName] != 'N/D'){
            const value = client[endName];

            if(hasContent(value)){
                if(endName == 'logradouro'){
                    string = value
                }else{
                    if(endName == 'cidade' || endName == 'estado'){
                        string = string + ' - ' + value
                    }else{
                        string = string + ', ' + value
                    }
                }
            }
        }
    }

    if(string == undefined){
        string = 'N/D';
    }

    return string;
}