export const mappers = {
    mapFirebaseDoc,
    mapAttendClients,
    mapClientsFromMap
}

function mapFirebaseDoc(querySnapshot){
    const data = querySnapshot.docs;

    return data.map((value) => {
        return {...value.data(), docId: value.id} 
    })
}

function mapAttendClients(reports){

    if(!reports){
        return { mappedAttendClients: null, mappedReports: null }
    }

    const seen = new Set();
    const duplicated = [];

    const mappedAttendClients = reports.filter(item => {
        const duplicate = seen.has(item.clientCpf);
        if(duplicate){
            duplicated.push(item);
        }
        seen.add(item.clientCpf);
        return !duplicate;
    });

    let mapObj = new Map()
    
    duplicated.forEach(v => {
        let prevValue = mapObj.get(v.clientCpf)
        if(!prevValue || prevValue.time < v.time){
            mapObj.set(v.clientCpf, v)
        } 
    })

    return mappedAttendClients
}
function mapClientsFromMap(clients){
    const data = []

    if(!clients){
        return data
    }

    for(const client of clients){
        if(!["done", "foraDoMapa"].includes(client.selected)){
            data.push({ ...client, bairro: client[`bairro${client.validEnd}`] })
        }
    }

    return data
}