import React from 'react';

import { Container, Content, ActionsContent, CancelButton, ConfirmButton, ModalConfirmHeader, ModalConfirmText, Divider } from './styles';

import deleteIcon from '../../assets/deleteIcon.svg'
import CustomLoading from '../CustomLoading';

const ModalDeleteMap = ({ mapName, loading, onCancel, onConfirm }) => {

    return (
        <Container>
            <Content>
                <img src={deleteIcon} style={{ height: 80, width: 80 }} alt='map map'/>
                <ModalConfirmText>
                    Tem certeza que deseja excluir o mapa {mapName}?
                </ModalConfirmText>
            </Content>
            <Divider />
            <ActionsContent>
                {loading
                    ? <CustomLoading />
                    : <>
                        <CancelButton onClick={onCancel}>
                            <p>Voltar</p>
                        </CancelButton>
                        <ConfirmButton onClick={onConfirm}>
                            <p>Excluir</p>
                        </ConfirmButton>
                    </>
                }
            </ActionsContent>
        </Container>
    )
}

export default ModalDeleteMap;