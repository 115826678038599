import React, { useState, createContext } from "react";

export const RoutesContext = createContext();

export const RoutesProvider = (props) => {
    const { children } = props;

    const [ routes, setRoutes ] = useState('');
    const [ routesUser, setRoutesUser ] = useState('');

    const clearRoutesContext = async () => {
        setRoutes('')
        setRoutesUser('');
        return
    }

    return (
        <RoutesContext.Provider 
            value={{ 
                routes: routes,
                setRoutes,
                routesUser: routesUser,
                setRoutesUser,

                clearRoutesContext,
            }}
        >
            {children}
        </RoutesContext.Provider>
    )
}