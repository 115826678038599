import React from 'react';

import { Container } from './styles';

const CustomDivider = ({ width }) => {
    return (
        <Container width={width} />
    )
}

export default CustomDivider;