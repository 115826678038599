import styled from "styled-components";
import { breakpoints } from "../../breakpoints";

export const Container = styled.div`
    display: flex;
    flex-direction: column;

    height: 90dvh;
    max-width: 850px;

    padding: 20px 20px;

    @media ${breakpoints.md} {
        width: 93dvw;
    }
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    overflow: auto;
    padding: 0 10px 0px 0px;

    @media ${breakpoints.md} {
        height: 90dvh;
    }
`;

export const ImageStreetView = styled.img`
    width: 100%;
    height: 144px;

    margin-bottom: 10px;
    border-radius: 10px;

    cursor: pointer;
`;

export const ClientHeader = styled.div`
    display: grid;
    grid-template-columns: 2fr 1fr;
    align-items: flex-end;
    justify-content: flex-end;
    width: 100%;

    gap: 10px;

    h3 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        padding-bottom: 5px;

        /* 2 */

        color: #808B9F;
    }
`;

export const ClientHeaderLeft = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
`;

export const ImageEspecie = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 52px;
    width: 52px;

    border-radius: 100px;
    background-color: var(--grey1);
`;

export const ClientNameContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;

    h4 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 23px;

        /* 1 */

        color: #4A5E83;
    }

    p {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 19px;
        /* identical to box height */


        /* 2 */

        color: #808B9F;
    }
`;

export const Divider = styled.div`
    position: relative;
    width: 100%;
    height: 0px;

    margin: 20px 0;

    /* 3 */

    border: 0.5px dashed #B4C1D5;
`;

export const DetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    gap: 10px;
    margin-bottom: 10px;

    p {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        max-width: 80%;
        /* 2 */

        color: var(--grey2);
    }
`;

export const AnaliseText = styled.div`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;

    /* 1 */

    color: #4A5E83;
`;

export const InfoHeader = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: left;
    align-items: center;

    margin: 10px 0;

    gap: 10px;

    p {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        /* identical to box height */


        /* 1 */

        color: #4A5E83;
    }
`;

export const RowContent = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);

    row-gap: 10px;
    column-gap: 10px;
`;

export const RowItem = styled.div`
    display: flex;
    flex-direction: column;

    gap: 10px;

    h3 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;

        /* 1 */

        color: #4A5E83;

    }

    p {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;

        /* 2 */

        color: #808B9F;
    }
`;

export const ReportsContent = styled.div`
    display: flex;
    flex-direction: column;

    width: 100%;

    gap: 20px;

    justify-content: center;
    /* align-items: center; */
`;

export const ReportItem = styled.div`
    display: flex;
    flex-direction: column;

    width: 100%;

    gap: 10px;
`;

export const ReportHeader = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 44px;

    gap: 15px;
    
    justify-content: space-between;
    align-items: center;

    color: var(--blue4);

    .left {
        display: flex;
        flex-direction: row;

        align-items: center;
        gap: 10px;
    }

    p {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;

        /* 1 */

        color: #4A5E83;
    }

    span {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;

        /* 1 */

        color: #4A5E83;
    }
`;

export const ResultText = styled.div`
    box-sizing: border-box;
    display: flex;

    height: 34px;
    padding: 5px 15px;
    align-items: center;

    border: 1px solid var(--grey1);
    background: transparent;
    border-radius: 100px;
`;

export const ReportDescription = styled.div`
    width: 100%;

    p {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;

        /* 1 */

        color: #4A5E83;
    }
`;

export const Value = styled.div`
    display: flex;
    flex-direction: row;

    justify-content: right;
    align-items: center;

    gap: 10px;

    h3 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        
        color: var(--blue4);
    }

    h4 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 14px;
        
        color: var(--white);
    }

    div {
        padding: 10px 10px;
        background: var(--red);
        border-radius: 20px;
    }
    
`;