import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    gap: 15px;
`;

export const TextClearFilters = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
    user-select: none;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    /* identical to box height, or 200% */

    text-decoration-line: underline;

    /* 1 */

    color: #4A5E83;
`;

export const ButtonFilter = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 7px 18px;

    /* width: 180px; */
    height: 38px;
    cursor: pointer;

    h4 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        margin-right: 5px;
    
        color: #808B9F;
    }
    /* 3 */
    
    border: 1px solid #B4C1D5;
    border-radius: 100px;
`;

export const MoreFilters = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    gap: 10px;
    cursor: pointer;

    h5 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        /* identical to box height, or 171% */


        /* DE6161 */

        color: #DE6161;
    }
`;

export const ModalFilterContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    
    width: 100%;
`;

export const ModalFilterItem = styled.div`
    display: flex;
    flex-direction: column;

    h4 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        margin-top: 10px;
        margin-bottom: 10px;
        /* identical to box height, or 200% */


        /* 1 */

        color: #4A5E83;
    }
`;

export const SelectContent = styled.div`
    display: flex;
    flex-direction: column;
    height: 360px;

    justify-content: space-between;
`;

export const ConfirmButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;

    padding: 6px 30px;

    color: var(--white);
    user-select: none;
    cursor: pointer;

    background: #DE6161;
    box-shadow: 0px 7px 10px rgba(228, 73, 73, 0.25);
    border-radius: 100px;

    h3 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;

        color: #FFFFFF;
    }
`;