import React, { useEffect, useState, useContext } from "react";

import {
    Container, 
    Header,
    LogsContainer,
    LogItem,
    ListHeader,
    ModalContainer,
    SubHeader,
    ModalListHeader,
    ModalLogRouteItem,
    ModalListContainer,
    LogsListContainer,
    SwitchLogType,
    SwitchButton
} from './styles';

//Firebase and context
import { UsersContext } from "../../contexts/UsersContext";

//Icons
import { FiCheckCircle, FiAlertCircle } from 'react-icons/fi';
import { AiOutlineEye, AiOutlineClockCircle } from 'react-icons/ai';
import { TbCalendar } from 'react-icons/tb';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';

//Custom Components
import ProfileButton from "../../components/ProfileButton";
import CustomLoadingPage from "../../components/CustomLoadingPage";
import ModalHeader from "../../components/ModalHeader";
import CustomFilterHeader from '../../components/CustomFilterHeader';
import { TableFooter } from "../../components/CustomTable/styles";
import CustomDivider from "../../components/CustomDivider";

//Utils
import Modal from 'react-modal';

//MUI
import { Avatar } from '@mui/material';
import { avatar } from "../../helpers/avatar";
import { datetime } from "../../helpers/datetime";
import { getCloseRoutesLogs } from "../../firebase/logs/closeRoutes/getCloseRoutesLogs";


const perPage = 40;

const CloseRoutesLogsPage = () => {
    const { users }  = useContext(UsersContext);

    const [ closeRoutesLogs, setCloseRoutesLogs ] = useState('');
    const [ loadingData, setLoadingData ] = useState(true);
    const [ logs, setLogs ] = useState('');
    const [ logsBackup, setLogsBackup ] = useState('');

    const [ rangeOfList, setRangeOfList ] = useState({ init: 0, finish: perPage })

    //Modal
    const [ modalVisible, setModalVisible ] = useState(false);
    const [ modalData, setModalData ] = useState('');
    const [ modalDataList, setModalDataList ] = useState('');
    const [ switchSelected, setSwitchSelected ] = useState('closedRoutes')

    useEffect(() => {
        (async () => {
            const logs = await getCloseRoutesLogs();

            setCloseRoutesLogs(logs);
        })();
    }, [])
    
    useEffect(() => {
        (async () => {
            if(closeRoutesLogs){
                if(closeRoutesLogs.length == 0){
                    return alert('Sem logs')
                }

                const arrayLogs = [];

                for(const log of closeRoutesLogs){
                    if(log.closedRoutes && log.canceledRoutes){
                        arrayLogs.push(log);
                    }
                }
                
                setLogs(arrayLogs);
                setLogsBackup(arrayLogs)
                setLoadingData(false);
            }
        })();
    }, [closeRoutesLogs]);

    const getTextItem = (arrayRoutes) => {

        if(arrayRoutes.length > 0){

            const string = `${arrayRoutes[0].routeName} - ${arrayRoutes[0].operatorName}`

            return `${arrayRoutes.length} (${string} + ${arrayRoutes.length-1})`
        }else{
            return 0
        }
    }
    
    if(loadingData){
        return <Container>
            <CustomLoadingPage />
        </Container>
    }

    return (
        <Container>
            <Header>
                <h1>Log Fechamento de Rotas</h1>
                
                <div>
                    <CustomFilterHeader
                        tableRowsBackup={logsBackup}
                        data={logs}
                        filterTypes={[
                            { headerName: 'Data', field: 'time' },
                        ]}
                        onFilter={(newData, values) => {
                            setLogs(newData)
                        }}
                        clearFilters={() => {
                            setLogs(logsBackup)
                        }}
                        filterType='atendimentos'
                        dateField='time'
                    />
                    <ProfileButton arrowColor='var(grey2)' />
                </div>
            </Header>
            
            <LogsContainer>
                <ListHeader>
                    <h3>Data</h3>
                    <h3>Hora</h3>
                    <h3>Status</h3>
                    <h3>Rotas fechadas</h3>
                    <h3>Rotas canceladas atualizados</h3>
                    <h3>Visualizar</h3>
                </ListHeader>
                <LogsListContainer>
                    {logs && logs.slice(rangeOfList.init, rangeOfList.finish).map((item, index) => (
                        <>
                            <LogItem key={index} color={item.success ? 'var(--aqua)' : 'var(--red)'}>
                                <h4>{datetime.getDate(item.time)}</h4>
                                <h4>{datetime.getHours(item.time)}</h4>
                                <div>
                                    {item.success
                                        ? <FiCheckCircle />
                                        : <FiAlertCircle />
                                    }
                                    <h4>{item.success ? 'Bem sucedida' : 'Mal sucedida'}</h4>
                                </div>
                                <h4>{getTextItem(item.closedRoutes)}</h4>
                                <h4>{getTextItem(item.canceledRoutes)}</h4>
                                <AiOutlineEye 
                                    onClick={() => {
                                        setModalData(item);
                                        setModalVisible(true);
                                    }} 
                                    cursor='pointer' 
                                    size={20} 
                                    color='#808B9F' 
                                    fill='#808B9F'
                                />
                            </LogItem>
                            {index < logs.length && (<CustomDivider width='98%'/>)}
                        </>
                    ))}
                </LogsListContainer>
                <TableFooter>
                    <h3>{rangeOfList.init} - {rangeOfList.finish}</h3>
                    <div>
                        <MdKeyboardArrowLeft 
                            size={20}
                            cursor='pointer'
                            onClick={() => {
                                if(rangeOfList.finish == logs.length){
                                    const value = (rangeOfList.finish - rangeOfList.init) + perPage;

                                    const newFinish = rangeOfList.init;

                                    setRangeOfList({ init: rangeOfList.finish - value, finish: newFinish})
                                }else{
                                    if((rangeOfList.init - perPage) >= 0){
                                        setRangeOfList({ init: rangeOfList.init - perPage, finish: rangeOfList.finish-perPage})
                                    }
                                }
                            }}
                        />
                        <MdKeyboardArrowRight 
                            size={20}
                            cursor='pointer'
                            onClick={() => {
                                if(logs.length > perPage){
                                    if((rangeOfList.finish + perPage) <= logs.length){
                                        setRangeOfList({ init: rangeOfList.finish, finish: rangeOfList.finish+perPage})
                                    }else{
                                        if(rangeOfList.finish != logs.length){
                                            const value = rangeOfList.finish+(logs.length - rangeOfList.finish);
        
                                            setRangeOfList({ init: rangeOfList.finish, finish: value})
                                        }
                                    }
                                }
                            }}
                        />
                    </div>
                    <h3>{logs ? logs.length : 0} clientes</h3>
                </TableFooter>
            </LogsContainer>

            <Modal
                isOpen={modalVisible}
                onRequestClose={() => {
                    setModalVisible(false)
                }}
                overlayClassName="modal-overlay"
                className="modal-content-auto-width"
                contentLabel="Example Modal"
            >
                <ModalContainer>
                    <ModalHeader title='Rotas fechadas e canceladas' onClose={() => {
                        setModalVisible(false)
                    }}/>
                    <SwitchLogType>
                        <SwitchButton 
                            selected={switchSelected == 'closedRoutes' ? true : false}
                            onClick={() => setSwitchSelected('closedRoutes')}
                        >
                            Fechadas
                            {switchSelected == 'closedRoutes' && (<div></div>)}
                        </SwitchButton>
                        <SwitchButton 
                            selected={switchSelected == 'canceledRoutes' ? true : false}
                            onClick={() => setSwitchSelected('canceledRoutes')}
                        >
                            Canceladas
                            {switchSelected == 'canceledRoutes' && (<div></div>)}
                        </SwitchButton>
                    </SwitchLogType>
                    <SubHeader>
                        <div>
                            <TbCalendar />
                            <h4>{datetime.getDate(modalData.time)}</h4>
                        </div>
                        <div>
                            <AiOutlineClockCircle />
                            <h4>{datetime.getHours(modalData.time)}</h4>
                        </div>
                        <div>
                            {modalData.success
                                ? <FiCheckCircle color='var(--aqua)' />
                                : <FiAlertCircle color='var(--red)' />
                            }
                            <h4>{modalData.success ? 'Bem sucedida' : 'Mal sucedida'}</h4>
                        </div>
                    </SubHeader>
                    <ModalListContainer>
                        {modalData && modalData[switchSelected].map((item, index) => (
                            <>
                                <ModalLogRouteItem key={index}>
                                    <h1>{item.routeName}</h1>
                                    {switchSelected == 'canceledRoutes'
                                        ?<div>
                                            <h4>{datetime.getFullDate(item.time)}</h4>
                                            <h4>-</h4>
                                            <h4>{datetime.getFullDate(modalData.time)}</h4>
                                        </div>
                                        : <div>
                                            <h4>{item.routeStartDate ? datetime.getFullDate(item.routeStartDate) : datetime.getFullDate(item.time)}</h4>
                                            <h4>-</h4>
                                            <h4>{item.routeCloseDate ? datetime.getFullDate(item.routeCloseDate) : datetime.getFullDate(modalData.time)}</h4>
                                        </div>
                                    }
                                    
                                    <div>
                                        {item.operatorUid && (
                                            <Avatar
                                                alt="Avatar"
                                                src={avatar.getUserAvatar(users.operators, item.operatorUid)}
                                                sx={{ 
                                                    width: 20, 
                                                    height: 20, 
                                                    borderRadius: 100, 
                                                    borderColor: 'white', 
                                                    borderWidth: 10 
                                                }}
                                            />
                                        )}
                                        <h4>{item.operatorName}</h4>
                                    </div>
                                </ModalLogRouteItem>
                                {index < modalData[switchSelected].length && (<CustomDivider width='98%'/>)}
                            </>
                        ))}
                    </ModalListContainer>
                </ModalContainer>
            </Modal>
        </Container>
    )
}

export default CloseRoutesLogsPage;