//Icons map
import retorno from '../assets/markers/retorno34.png';
import retornoPremium from '../assets/markers/retornoPremium34.png';  

//Variables
export const returnFilterIcons = [
    {
        especie: "return",
        icon: retorno,
        title: "RETORNO",
    },
    {
        especie: "premiumReturn",
        icon: retornoPremium,
        title: "RETORNO PREMIUM",
    },
]