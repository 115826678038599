import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';

import { 
    Container, 
    ContainerLogin, 
    FieldText, 
    ImageLogin, 
    LoadingContainer, 
    LoginButton, 
    LoginForm, 
    LogoContainer, 
    ShowPassword, 
    TextInput, 
    WrapLogin 
} from "./styles";

//Firebase and Context
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { AuthContext } from "../../contexts/AuthContext";

//Icons
import { AiOutlineCloseSquare } from 'react-icons/ai';

//Utils
import DotLoader from "react-spinners/ClipLoader";
import { toast } from 'react-toastify';

//Images
import logo from '../../assets/logoCentral.svg';
import imageLogin from '../../assets/imageLogin.svg';
import { alertErrorStyle } from "../../resources/alertErrorStyle";
import { USER_TYPE } from "../../constants/userType";
import Button from "../../components/Button";

const LoginPage = (() => {

    const { firebaseLogin } = useContext(AuthContext);

    const [ email, setEmail ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ loading, setLoading ] = useState(false);
    const [ showPassword, setShowPassword ] = useState(false);
    const [ savedLogins, setSavedLogins ] = useState([]);

    const handleInputChangeEmail = (event) => {
        setEmail(event.target.value);
    }

    const handleInputChangePassword = (event) => {
        setPassword(event.target.value);
    }
    
    const navigate = useNavigate();
    const auth = getAuth();

    useEffect(() => {
        getAllCacheData();
    }, [])

    const tryLogin = async (email, password) => {
        const { success, role, error } =  await firebaseLogin(email, password);

        if(success){
            if(role == USER_TYPE.OPERATOR){
                navigate("/map");
            }else{
                navigate("/dashboard")
            }
        }else{
            if(error.code == 'auth/user-disabled'){
                return toast(`Conta desativada`, alertErrorStyle)
            }

            toast(`Usuário ou senha incorretos!`, alertErrorStyle)
        }
    }

    const handleLogin = async (e) => {
        e.preventDefault();
            setLoading(true);

            if(!email || !password){
                setLoading(false);
                return toast(`Preencha todos os campos`, { 
                    style: {
                        background: 'var(--red)',
                        color: 'white',
                    },
                    progressStyle: {
                        background: 'white'
                    },
                    icon: <AiOutlineCloseSquare />
                })
            }

            if(email.match(/@/)){
                await tryLogin(email, password)
            }else{
                if(savedLogins.length !== 0){
                    const findLogin = savedLogins.find(x => x.login === email);
    
                    if(findLogin){
                        await tryLogin(findLogin.email, password)
                    }else{
                        toast(`Por favor use o email`, { 
                            style: {
                                background: 'var(--red)',
                                color: 'white',
                            },
                            progressStyle: {
                                background: 'white'
                            },
                            icon: <AiOutlineCloseSquare />
                        })
                    }
                }  
            }
            
            setLoading(false);
    };

    const getAllCacheData = async () => {
        
        try {

            const cache = window.localStorage.getItem("logins");

            const data = JSON.parse(cache);

            setSavedLogins(data.logins);
        } catch{
            // alert("Por favor utilize o email")
        }
    };

    return(
        <Container>
            <ImageLogin>
                <img style={{ resize:"inherit" }} src={imageLogin} alt="Login Logo"/>
            </ImageLogin>
            <ContainerLogin>
                <WrapLogin>
                    <LogoContainer>
                        <img style={{ height: '100%', width: '100%' }} src={logo} alt="Login Logo"/>
                    </LogoContainer>
                    <LoginForm onSubmit={(e) => handleLogin(e)}>
                        <FieldText>
                            E-mail
                        </FieldText>
                        <TextInput>
                            <input 
                                name="email" 
                                onChange={event => handleInputChangeEmail(event)}
                                placeholder='E-mail'
                            />
                        </TextInput>

                        <FieldText>
                            Senha
                        </FieldText>
                        <TextInput>
                            <input 
                                type={showPassword ? "text" : "password"} 
                                name="password" 
                                onChange={event => handleInputChangePassword(event)} 
                                placeholder='Senha'
                            />
                        </TextInput>

                        <ShowPassword onClick={() => setShowPassword(!showPassword)}>
                            <p>{showPassword ? "Ocultar senha" : "Mostrar senha"}</p>
                        </ShowPassword>

                        <Button text='Entrar' loading={loading} />           
                    </LoginForm>
                </WrapLogin>
            </ContainerLogin>
        </Container>
    )
})

export default LoginPage;