import { useContext, useEffect } from "react";
import { arrayHelper } from "../helpers/arrayHelper";
import { ClientsContext } from "../contexts/ClientsContext";
import useAttendClients from "./useAttendClients";
import useMapClients from "./useMapClients";
import useMapRemovedClients from "./useMapRemovedClients";

export default function useTotalClients(){

    const { attendClients } = useAttendClients();
    const { mapClients } = useMapClients();
    const { mapRemovedClients } = useMapRemovedClients();

    const { totalClients, setTotalClients } = useContext(ClientsContext);

    useEffect(() => {
        if(attendClients && mapClients && mapRemovedClients){
            let array = [];

            const mapClientsCpf = arrayHelper.reduceToSimple(mapClients, 'cpf')

            for(const client of attendClients){
                const resultIndex = mapClientsCpf.findIndex(x => x === client.clientCpf)

                array.push({
                    ...client, //pensar questão sobre time
                    dataInclusao: resultIndex != -1 ? mapClients[resultIndex].time : client.time,
                    reportOperatorUid: client.operatorUid
                })
            }

            for(const cliente of mapRemovedClients){
                array.push({
                    ...cliente,
                    clientName: cliente.nome,
                    clientCpf: cliente.cpf,
                    operatorName: 'Sem operador',
                    dataInclusao: cliente.time,
                    reportOperatorUid: '',
                    result: "Não atendido"
                })
            }

            for(const cliente of mapClients){
                array.push({
                    ...cliente,
                    clientName: cliente.nome,
                    clientCpf: cliente.cpf,
                    operatorName: 'Sem operador',
                    dataInclusao: cliente.time,
                    reportOperatorUid: '',
                    result: "Não atendido"
                })
            }

            const seen = new Set();
            const duplicated = [];

            const mapped = array.filter(item => {
                const duplicate = seen.has(item.clientCpf);
                if(duplicate){
                    duplicated.push(item);
                }
                seen.add(item.clientCpf);
                return !duplicate;
            });

            const result = arrayHelper.ordenateArrayDesc(mapped, 'time');
            
            setTotalClients(result)
        }
    }, [attendClients, mapRemovedClients, mapClients])

    return {
        totalClients: totalClients,
        loadingAttendClients: !totalClients ? true : false
    }
}