export const arrayHelper = {
    reduceToSimple,
    createArrayOfSubArrays,
    ordenateArrayAsc,
    ordenateArrayDesc,
    addSelectedField,
    insertObjectOnArray,
    isPropertyValue,
    arrayToString,
    includeOnArray,
    includeOnArrayObject,
    removeItemOfArray,
    replaceItemOfArray,
    findInArray,
    difference,
    removeDuplicates,
    getBalance,
    getUniqueArray
}

function reduceToSimple(array, field){
    const arr = [];

    if(array){
        for(const item of array){
            if(item[field]){
                const result = arr.find(x => x[field] == item[field]);
    
                if(!result){
                    arr.push(item[field])
                }
            }else{
                arr.push(item)
            }        
        }
    }

    return arr
}

function createArrayOfSubArrays(array, field){
    let arr = [];

    if(array){
        for(const item of array){
            arr = arr.concat(item[field])    
        }
    }

    return arr
}

function ordenateArrayDesc(array, field){

    const ordenatedArray = array.sort(function(a,b) {
        return a[field] < b[field] ? 1 : a[field] > b[field] ? -1 : 0;
    });

    return ordenatedArray
}

function ordenateArrayAsc(array, field){

    const ordenatedArray = array.sort(function(a,b) {
        return a[field] > b[field] ? 1 : a[field] < b[field] ? -1 : 0;
    });

    return ordenatedArray
}

function addSelectedField(array, defaultValue){
    if(array.length == 0){
        return array
    }

    const newArray = []

    for(const item of array){
        newArray.push({
            ...item,
            selected: defaultValue
        })
    }

    return newArray
}

function insertObjectOnArray(array, index, object){
    if(array.length == 0){
        return array
    }

    const array1 = array.slice(0, index);
    const array2 = array.slice(index+1, array.length);

    array1.push(object)
    
    return array1.concat(array2)
}

function isPropertyValue(array, property, value){
    if(array.length == 0){
        return false
    }
    
    return array.find(x => x[property] == value)
}

function arrayToString(array, separator){
    var string = '';

    for(const item of array){
        if(string == ''){
            string = item
        }else{
            string = string + separator + item
        }
    }

    return string
}

function includeOnArray(array, string){
    return array.find(x => x.includes(string))
}

function includeOnArrayObject(array, property, string){
    return array.find(x => x[property].includes(string))
}


function findInArray(array, string){
    return array.find(x => x == string)
}

function removeItemOfArray(array, index){
    const array1 = array.slice(0, index);
    const array2 = array.slice(index+1, array.length);

    return array1.concat(array2)
}

function replaceItemOfArray(array, index, object){
    const array1 = array.slice(0, index);
    const array2 = array.slice(index+1, array.length);

    array1.push(object)

    return array1.concat(array2)
}

function difference(array1, array2){
    const find1 = array1.filter(x => !array2.includes(x));
    const find2 = array2.filter(x => !array1.includes(x));

    return removeDuplicates(find1.concat(find2))
}

function removeDuplicates(array){
    return [...new Set(array)];
}

function getBalance(oldArray, newArray, field){
    const arrayAdded = []
    const arrayRemoved = []

    for(const item of newArray){
        const findOld = oldArray.find(x => x[field] == item[field]);

        if(!findOld){
            arrayAdded.push(item)
        }
    }

    for(const item of oldArray){
        const findNew = newArray.find(x => x[field] == item[field]);

        if(!findNew){
            arrayRemoved.push(item)
        }
    }

    return { added: arrayAdded, removed: arrayRemoved }
}

function getUniqueArray(array, field){
    return [...new Map(array.map(v => [v[field], v])).values()]
}