import styled from 'styled-components';

export const Container = styled.div`
    display: grid;
    grid-template-columns: ${props => `2fr repeat(${props.columns-2}, 1fr) 0.5fr`};

    padding: 0 15px;
    cursor: pointer;
    user-select: none;

    align-items: center;
    justify-content: center;
    height: 59px;

    column-gap: 10px;


    div {
        display: flex;
        flex-direction: row;
        align-items: center;

        gap: 10px
    }

    h4 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;

        color: #808B9F;
    }
`;

export const Name = styled.div`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;

    /* 1 */

    color: #4A5E83;
`;

export const Especie = styled.div`
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-self: left;
    background: ${props => props.color};
    opacity: 0.8;
    border-radius: 100px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    /* identical to box height */


    /* 5 */

    color: ${props => props.textColor};
`;

export const Date = styled.div`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: center;

    color: ${props => props.color};
`;

export const Result = styled.div`
    display: flex; 
    flex-direction: row;

    align-items: center;
    justify-content: flex-start;

    gap: 10px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;

    color: ${props => props.color};
`;

export const Options = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    gap: 5px;
`;