import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;

    max-width: 80vw;
`;

export const Header = styled.div`
    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: space-between;

    padding: 0 10px;

    height: 20%;

    user-select: none;

    color: #4A5E83;

    h3 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 19px;

        color: #4A5E83;
    }

    h4 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;

        color: #808B9F;
    }

    div {
        display: flex;
        flex-direction: row;
        align-items: center;

        gap: 10px
    }
`;

export const ListContainer = styled.div`
    /* display: flex;
    flex-direction: column; */
    margin-top: 20px;
    gap: 10px;

    height: 75vh;
    overflow-y: auto;
`;