import React, { useEffect, useState, useContext } from "react";

import {
    Container, 
    Header
} from './styles';

//Firebase and context
import { AuthContext } from '../../contexts/AuthContext';

//Custom Components
import OperatorsCardDashboard from "../../components/OperatorsCardDashboard";
import CustomTable from "../../components/CustomTable";

//Utils
import tableColumnsReturns from '../../resources/tableColumnsReturns.json';

//MUI
import { datetime } from "../../helpers/datetime";
import { UsersContext } from "../../contexts/UsersContext";

//utils
import ReportsCards from "./components/ReportsCards";
import { cards } from "../../helpers/cards";
import useAttendClients from "../../hooks/useAttendClients";
import { arrayHelper } from "../../helpers/arrayHelper";
import useRoutes from "../../hooks/useRoutes";
import { reportResultType } from "../../constants/reportResultType";
import useExpiredReturns from "../../hooks/useExpiredReturns";
import CustomFilterHeader from "../../components/CustomFilterHeader";
import { monthNames } from "../../constants/monthNames";
import ProfileButton from "../../components/ProfileButton";
import { filtersHelper } from "../../helpers/filtersHelper";
import { searchHelper } from "../../helpers/searchHelper";
import Charts from "./components/Charts";

const dateNow = new Date();
const currentMonth = dateNow.getMonth();

const defaultFilterObj = {
    line1: [],
    line2: [],
    line3: []
}

const DashboardPage = () => {

    const { user, isAdmin } = useContext(AuthContext);

    const { users } = useContext(UsersContext);
    const { attendClients } = useAttendClients();
    const { expiredReturnsClients, fetchExpiredReturns } = useExpiredReturns();
    const { routes, fetchRoutes } = useRoutes();

    //Cards
    const [ operatorsData, setOperatorsData ] = useState('');
    const [ cardsData, setCardsData ] = useState('')

    //OperatorsCard
    const [ operatorsCardFilters, setOperatorsCardFilters ] = useState([])

    //filters and search
    const [ headerFilters, setHeaderFilters ] = useState([]);
    const [ filters, setFilters ] = useState(defaultFilterObj)
    const [ search, setSearch ] = useState('');
    
    //Charts
    const [ selectedLine, setSelectedLine ] = useState('line1');

    useEffect(() => {
        if(attendClients){
            processReportsCards(attendClients)
        }
    }, [attendClients, headerFilters, users])

    useEffect(() => {
        if(attendClients){
            fetchExpiredReturns()
            fetchRoutes()
            processOperatorsCard(attendClients)
        }
    }, [attendClients, expiredReturnsClients, routes, users])

    const processReportsCards = (attendClients) => {
        const monthAttendClients = cards.getReportsOfMonth(attendClients);

        const filteredAttendClients = headerFilters.length > 0
            ? headerFilters[0]?.values ? filtersHelper.filterDataTable(attendClients, headerFilters) 
            : monthAttendClients : monthAttendClients
            
        const adminUids = arrayHelper.reduceToSimple(users.admins, 'uid').concat([users.rootUid]);

        if(isAdmin){
            adminUids.push(user.uid)
        }

        const notAttendByAdmin = filteredAttendClients.filter(x => !adminUids.includes(x.operatorUid))

        const signed = cards.calculateSignedContracts(notAttendByAdmin);
        const winnerSigned = cards.calculateRanking(signed, notAttendByAdmin);
    
        const visitedClients = cards.calculateVisitedClients(notAttendByAdmin)
        const winnerVisits = cards.calculateWinnerVisits(visitedClients, notAttendByAdmin);

        setCardsData({
            signed: signed,
            winnerSigned: winnerSigned,
            visitedClients: visitedClients,
            winnerVisits: winnerVisits
        })
    }

    const processOperatorsCard = (attendClients) => {
        const array = [];

        const arrayUsers = users ? users?.operators?.filter(x => x.disabled != true) : [];

        const adminUids = arrayHelper.reduceToSimple(users.admins, 'uid').concat([users.rootUid]);

        if(isAdmin){
            adminUids.push(user.uid)
        }

        const notAttendByAdmin = attendClients.filter(x => !adminUids.includes(x.operatorUid))

        const totalReturns = notAttendByAdmin.filter(x => x.result === reportResultType.RETURN);

        const expiredReturnsCpfs = arrayHelper.reduceToSimple(expiredReturnsClients, 'cpf')
        
        const realizedReturns = notAttendByAdmin.filter(x => x.result != reportResultType.RETURN && x.retornos > 0)
        const expiredReturns = totalReturns.filter(x => !expiredReturnsCpfs.includes(x.clientCpf));
        const pendingReturns = totalReturns.filter(x => expiredReturnsCpfs.includes(x.clientCpf));

        const findDaysWithRoutes = [];
        const daysOfMonth = [];

        const monthInterval = datetime.getMonthIntervalMs(dateNow.getTime())

        for(var i = monthInterval.timeStart; i <= monthInterval.timeEnd; i = i+(86400*1000)){
            daysOfMonth.push(i)
        }

        const { timeStart } = datetime.getMonthIntervalMs(dateNow.getTime())
        const monthRoutes = routes ? routes.filter(x => x.time > timeStart) : []

        for(const dayTime of daysOfMonth){
            const result = monthRoutes.filter(x => x.time > dayTime && x.time < (dayTime+(86399*1000)))

            if(result.length > 0){
                findDaysWithRoutes.push({
                    day: dayTime,
                    routes: result
                })
            }
        }

        const validVisited = notAttendByAdmin.filter(x => x.result != reportResultType.DIVERSE_LOCATION)

        array.push({
            userName: 'Geral',
            uid: 'geral',
            img64: null,
            daysWithRoutes: findDaysWithRoutes.length,
            visitedClients: validVisited,
            returns: {
                total: realizedReturns.concat(expiredReturns, pendingReturns),
                realized: realizedReturns,
                expired: expiredReturns,
                pending: pendingReturns,
            },
        })

        for(const user of arrayUsers){
            var totalDays = 0;

            for(const day of findDaysWithRoutes){
                const result = day.routes.find(x => x.uid === user.uid);

                if(result){
                    totalDays = totalDays + 1
                }
            }
            const findVisitedClients = validVisited.filter(x => x.operatorUid === user.uid);
            const findRealizedReturns = realizedReturns.filter(x => x.operatorUid === user.uid);
            const findExpiredReturns = expiredReturns.filter(x => x.operatorUid === user.uid);
            const findPendingReturns = pendingReturns.filter(x => x.operatorUid === user.uid);
            
            array.push({
                ...user,
                daysWithRoutes: totalDays,
                visitedClients: findVisitedClients,
                returns: {
                    total: findRealizedReturns.concat(findPendingReturns, findExpiredReturns),
                    realized: findRealizedReturns,
                    expired: findExpiredReturns,
                    pending: findPendingReturns,
                },
            })
        }
        
        setOperatorsData(array);
    }

    const filterByOperatorCard = () => {
        const operatorUid = operatorsCardFilters[0]
        const type = operatorsCardFilters[1]

        if(operatorUid){
            const operatorCardData = operatorsData.find(x => x.uid === operatorUid)

            if(operatorCardData){
                if(type){
                    if(type === 'visits'){
                        return arrayHelper.ordenateArrayDesc(operatorCardData.visitedClients)
                    }
    
                    if(type === 'returns'){
                        return arrayHelper.ordenateArrayDesc(operatorCardData.returns.total)
                    }
                }else{
                    return arrayHelper.ordenateArrayDesc(operatorCardData.visitedClients.concat(operatorCardData.returns.total))
                }
            }
        }

        return []
    }

    const filterByTable = () => {
        return filtersHelper.filterDataTable(attendClients, filters[selectedLine])
    }

    const clearFilters = () => {
        setFilters(prev => ({
            ...prev,
            [selectedLine]: defaultFilterObj[selectedLine]
        }))
        setSearch('')
    }

    const baseTableData = operatorsCardFilters.length > 0  
        && operatorsData ? filterByOperatorCard() : filterByTable() 

    const backupTableData = operatorsCardFilters.length > 0  
        && operatorsData ? filterByOperatorCard() : attendClients

    const tableData = search ? 
        searchHelper.searchTable(search, tableColumnsReturns, backupTableData) : baseTableData

    return (
        <Container>
            <Header>
                <h1>Dashboard</h1>
                <div>
                    <CustomFilterHeader
                        filters={headerFilters}
                        filterTypes={[{ headerName: 'Data', field: 'time' }]}
                        dateField='time'
                        onChangeFilters={(newFiltersArray) => {
                            setHeaderFilters(newFiltersArray)
                        }}
                        onClearFilters={() => setHeaderFilters([])}
                        defaultTitle={monthNames.full[currentMonth]}
                    />
                    <ProfileButton arrowColor='var(grey2)' />
                </div>
            </Header>

            <ReportsCards cardsData={cardsData} />

            <OperatorsCardDashboard
                operatorsData={operatorsData}
                users={users}
                expiredReturnsClients={expiredReturnsClients}
                routes={routes}
                onFilter={(newData) => {
                    // clearFilters()
                    // setOperatorsCardFilters(newData)
                }}
                unFilter={() => {
                    // clearFilters()
                    // setOperatorsCardFilters([])
                }}
            />

            <Charts 
                data={tableData} 
                backupTableData={backupTableData}
                filters={filters}
                selectedLine={selectedLine}
                setSelectedLine={setSelectedLine}
            />
            
            <CustomTable 
                title='Clientes atendidos'
                tableColumns={tableColumnsReturns} 
                tableRows={tableData} 
                tableRowsBackup={backupTableData}
                filterTypes={tableColumnsReturns} 
                columns={7}
                filtersHighlights={[
                    {
                        field: 'time',
                        headerName: 'Data'
                    },
                    {
                        field: 'operatorName',
                        headerName: 'Operador'
                    },
                    {
                        field: 'result',
                        headerName: 'Resultado'
                    }
                ]}
                dateField='time'
                search={search}
                setSearch={setSearch}
                filters={filters[selectedLine]}
                onChangeFilters={(newFiltersArray) => {
                    setFilters(prev => ({
                        ...prev,
                        [selectedLine]: newFiltersArray
                    }))
                }}
                onClearFilters={() => clearFilters()}
            />
        </Container>
    )
}

export default DashboardPage;