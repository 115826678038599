import React, { useState } from 'react';

import { 
    Container,
    Title,
    OperatorsList,
    HeaderList,
    ListContainer,
    OperatorItem,
    OperatorsInfo,
    HeaderInfo,
    InfoItem,
    Circle,
    InfoItemHeader,
    InfoItemDescription,
    OperatorsListLoading,
    OperatorsInfoLoading
} from './styles';

import { FiUsers, FiRefreshCcw } from 'react-icons/fi';
import { TbRoute } from 'react-icons/tb';

//MUI
import { Avatar } from '@mui/material';
import CustomLoading from '../CustomLoading';
import { avatar } from '../../helpers/avatar';
import { reportResultOptions } from '../../constants/reportResultOptions';
import { ClipLoader } from 'react-spinners';

function OperatorsCardDashboard({ operatorsData, users, expiredReturnsClients, routes, onFilter, unFilter }) {

    const [ selected, setSelected ] = useState(0);

    const handleSelect = (item, index, type) => {
        if(index == selected){
            unFilter()
            setSelected(0)
        }else{
            if(type === 'operator'){
                setSelected(index)
                onFilter([item.uid])
            }else{
                onFilter([item.uid, type])
            }
        }
    }
 
    const getReportsType = (data) => {
        const array = []

        for(const option of reportResultOptions){
            const reports = data.visitedClients.filter(x => x.result == option);

            if(reports.length > 0){
                array.push({
                    field: option,
                    qtd: reports.length
                }) 
            }
                      
        }
        return array
    }

    if(!operatorsData || !users){
        return (
            <Container>
                <OperatorsListLoading>
                    <CustomLoading />
                </OperatorsListLoading>
                <OperatorsInfoLoading>
                    <CustomLoading />
                </OperatorsInfoLoading>
            </Container>
        )
    }

    return (
        <Container>
            <OperatorsList>
                <Title>
                    <FiUsers />
                    <h3>Operadores</h3>
                </Title>
                <HeaderList>
                    <h3>Operador</h3>
                    <h3>Dias com rotas</h3>
                    <h3>Clientes visitados</h3>
                    <h3>Retornos</h3>
                </HeaderList>
                <ListContainer>
                    {operatorsData.map((item, index) => {

                        if(item.userName != 'Geral'){
                            return (
                                <OperatorItem key={index} active={selected == index ? true : false} onClick={() => handleSelect(item, index, 'operator')}>
                                    <div>
                                        <Avatar
                                            alt="Avatar"
                                            src={avatar.getAvatarByData(item)}
                                            sx={{ width: 30, height: 30, borderRadius: 100, borderColor: 'white', borderWidth: 20 }}
                                        />
                                        <h4>{item.userName}</h4>
                                    </div>
                                    <div>
                                        <h4>{item.daysWithRoutes}</h4>
                                    </div>
                                    <div>
                                        <h4>{item.visitedClients.length}</h4>
                                    </div>
                                    <div>
                                        <h4>{item.returns.total.length}</h4>
                                    </div>
                                </OperatorItem>
                            )
                        }
                    })}
                </ListContainer>
            </OperatorsList>
            {operatorsData.length > 0 && (
                <OperatorsInfo>
                    <HeaderInfo>
                        <Avatar
                            alt="Avatar"
                            src={avatar.getAvatarByData(operatorsData[selected])}
                            sx={{ 
                                marginTop: -5, 
                                width: 100, 
                                height: 100, 
                                borderRadius: 100, 
                                borderColor: 'white', 
                                borderWidth: 10 
                            }}
                        />
                        <h3>{operatorsData[selected].userName}</h3>
                    </HeaderInfo>
                    <InfoItem>
                        <InfoItemHeader color='var(--success)'>
                            <div>
                                <Circle color='var(--success)'>
                                    <TbRoute />
                                </Circle>
                                <h3>Dias com rotas</h3>
                            </div>
                            {routes
                                ? <h1>{operatorsData[selected].daysWithRoutes}</h1>
                                : <ClipLoader
                                    size={25}
                                    color={"var(--red)"}
                                    loading={true}
                                    speedMultiplier={1.5}
                                />
                            }
                        </InfoItemHeader>
                    </InfoItem>
                    <InfoItem onClick={() => {
                        handleSelect(operatorsData[selected], null, 'visits')
                    }}>
                        <InfoItemHeader color='var(--yellow)' >
                            <div>
                                <Circle color='var(--yellow)'>
                                    <FiUsers />
                                </Circle>
                                <h3>Clientes visitados</h3>
                            </div>
                            <h1>{operatorsData[selected].visitedClients.length}</h1>
                        </InfoItemHeader>
                        <InfoItemDescription>
                            {getReportsType(operatorsData[selected]).map((item, index) => {
                                if(index == getReportsType(operatorsData[selected]).length -1){
                                    return <h3 key={index}>{item.field}: <span>{item.qtd}</span></h3>
                                }else{
                                    return <h3 key={index}>{item.field}: <span>{item.qtd}</span> /</h3>
                                }
                                
                            })}
                        </InfoItemDescription>
                    </InfoItem>
                    <InfoItem onClick={() => {
                        handleSelect(operatorsData[selected], null, 'returns')
                    }}>
                        <InfoItemHeader color='var(--blue)'>
                            <div>
                                <Circle color='var(--blue)'>
                                    <FiRefreshCcw />
                                </Circle>
                                <h3>Retornos</h3>
                            </div>
                            {expiredReturnsClients
                                ? <h1>{operatorsData[selected].returns.total.length}</h1>
                                : <ClipLoader
                                    size={25}
                                    color={"var(--red)"}
                                    loading={true}
                                    speedMultiplier={1.5}
                                />
                            }
                        </InfoItemHeader>
                        {expiredReturnsClients && (
                                <InfoItemDescription>
                                <h3>Realizados: <span>{operatorsData[selected].returns.realized.length}</span> / Pendentes: <span>
                                    {operatorsData[selected].returns.pending.length}</span> / Expirados: <span>
                                    {operatorsData[selected].returns.expired.length}</span>
                                </h3>
                            </InfoItemDescription>
                        )}
                    </InfoItem>
                </OperatorsInfo>
            )}
        </Container>
    )
}

export default OperatorsCardDashboard;