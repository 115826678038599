import { FiCheckSquare } from 'react-icons/fi';

export const alertSuccessStyle = { 
    style: {
        background: 'var(--success)',
        color: 'white',
    },
    progressStyle: {
        background: 'white'
    },
    icon: <FiCheckSquare />
}