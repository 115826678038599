import React, { useContext, useState } from 'react';

//Firebase and Context
import { AuthContext } from '../../contexts/AuthContext';

//Styles
import { ActionsContainer, Container, SavedPointsContainer, SavedRouteDetails, SavedRouteHeader, SavedRouteInfo, SavedRouteName } from './styles';

//Icons
import { BiUser } from 'react-icons/bi';
import { AiOutlineClockCircle } from 'react-icons/ai';
import { TbCalendarMinus, TbFile, TbPencil } from 'react-icons/tb';
import { FiTrash2 } from 'react-icons/fi';
import ElipseIcon from '../ElipseIcon';
import CustomDivider from '../CustomDivider';
import StatusIcon from '../StatusIcon';
import AdminRoutePoint from '../AdminRoutePoint';
import { datetime } from '../../helpers/datetime';

const AdminRouteCard = ({ route, onEdit, onRemove, onCancel, edit }) => {

    const { isAdmin } = useContext(AuthContext);

    const [ expanded, setExpanded ] = useState(false);

    return (
        <Container>
            {!edit && (
                <>
                    <SavedRouteHeader>
                        <SavedRouteName>
                            <p>{route.name}</p>
                            <ElipseIcon 
                                text={route.points.length} 
                                size={17} 
                                background='var(--blue3)' 
                                fontWeight='400' 
                                fontSize={12} 
                            />
                        </SavedRouteName>
                        {route.status == 'aguardando' && isAdmin && (
                            <ActionsContainer>
                                <TbPencil 
                                    onClick={onEdit}
                                    size={22} 
                                    style={{ strokeWidth: 1.5, cursor: 'pointer' }} 
                                />
                                <FiTrash2 
                                    onClick={onCancel}
                                    color='var(--red)' 
                                    size={20} 
                                    style={{ strokeWidth: 1.5, cursor: 'pointer' }}
                                />
                            </ActionsContainer>
                        )}
                    </SavedRouteHeader>
                    <SavedRouteDetails>
                        <SavedRouteInfo>
                            <BiUser />
                            <p>Operador: {route.operatorName}</p>
                        </SavedRouteInfo>
                        <SavedRouteInfo>
                            <TbFile />
                            <p>ID: {route.docId}</p>
                        </SavedRouteInfo>
                        <SavedRouteInfo>
                            <AiOutlineClockCircle />
                            <p>Status:</p>
                            <StatusIcon status={route.status} />
                        </SavedRouteInfo>
                        <SavedRouteInfo>
                            <TbCalendarMinus />
                            <p>Data de criação: {datetime.getFullDate(route.time)}</p>
                        </SavedRouteInfo>
                        <SavedRouteInfo>
                            <TbCalendarMinus />
                            <p>Início: {route.routeStartDate ? datetime.getFullDate(route.routeStartDate) : '---'}</p>
                        </SavedRouteInfo>
                        <SavedRouteInfo>
                            <TbCalendarMinus />
                            <p>Finalização: {route.routeCloseDate ? datetime.getFullDate(route.routeCloseDate) : '---'}</p>
                        </SavedRouteInfo>
                    </SavedRouteDetails>
                </>
            )}
            <CustomDivider />
            <SavedPointsContainer edit={edit}>
                {route.points.length != 0 && route.points.map((item, index) => (
                    <>
                        
                        <AdminRoutePoint 
                            letter={index} 
                            client={item}
                            clientName={item.nome}
                            especie={item.especie}
                            edit={edit}
                            onRemove={(client) => onRemove(client)}
                        />
                    </>
                ))}
            </SavedPointsContainer >
        </Container>
    )
}

export default AdminRouteCard;