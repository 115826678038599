import { useContext, useEffect } from "react";
import { AuthContext } from "../contexts/AuthContext";
import { mappers } from "../helpers/mappers";
import { collection, query, onSnapshot, where } from "firebase/firestore";
import db from '../firebase/config';
import { ClientsContext } from "../contexts/ClientsContext";
import { arrayHelper } from "../helpers/arrayHelper";

export default function useMapReturns(){
    const { user, isAdmin, isManager, userData } = useContext(AuthContext);

    const { returnsUser, setReturnsUser, returnMapClients, setReturnMapClients } = useContext(ClientsContext);

    //get return map clients admin
    const getReturnsMapClientsAdmin = async () => {
        const consulta = query(collection(db, 'clientes'), where('selected', '==', ''),  where('retornos', '>', 0));
        await onSnapshot(consulta, (querySnapshot) => {
            const mappedDocs = mappers.mapFirebaseDoc(querySnapshot)

            setReturnMapClients(mappedDocs);
        }, error => {
            console.log("getReturnsMapClientsAdmin: ", error)
        });
    }

    //get return map clients admin
    const getReturnsMapClientsManager = async (mapsArray) => {
        if(!mapsArray || mapsArray?.length == 0){
            return setReturnMapClients([]);
        } 

        const mapsIds = arrayHelper.reduceToSimple(mapsArray, 'value');

        const consulta = query(collection(db, 'clientes'), where('mapId', 'in', mapsIds), 
            where('selected', '==', ''),  where('retornos', '>', 0));
        await onSnapshot(consulta, (querySnapshot) => {
            const mappedDocs = mappers.mapFirebaseDoc(querySnapshot)

            setReturnMapClients(mappedDocs);
        }, error => {
            console.log("getReturnsMapClientsManager: ", error)
        });
    }

    //get return map clients user
    const getReturnsMapClientsUser = async (user) => {
        const consulta = query(collection(db, 'clientes'), where('selected', '==', ''), 
            where("operatorUid", "==", user.uid), where('retornos', '>', 0));
        await onSnapshot(consulta, (querySnapshot) => {
            const mappedDocs = mappers.mapFirebaseDoc(querySnapshot)

            setReturnsUser(mappedDocs);
        }, error => {
            console.log("getReturnsMapClientsUser: ", error)
        });
    }

    useEffect(() => {
        if(isAdmin){
            if(!returnMapClients){
                getReturnsMapClientsAdmin()
            }
        }
    
        if(isManager){
            if(!returnMapClients && userData){
                getReturnsMapClientsManager(userData.mapsArray)
            }
        }
    
        if(!isAdmin && !isManager){
            if(!returnsUser && user){
                getReturnsMapClientsUser(user)
            }
        }
    }, []);

    return {
        returnMapClients: returnMapClients,
        returnsUser: returnsUser,
        loading: !returnMapClients || !returnsUser ? true : false
    }
}