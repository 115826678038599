import { collection, getDocs } from "firebase/firestore";
import { orderBy } from "lodash";
import db from '../../config';

export async function getSystemLogs(user){
    const querySnapshot = await getDocs(collection(db, "users"));

    const users = []
    querySnapshot.forEach(async (doc) => {
        if(doc.id != user.uid){
            users.push(doc.id)
        }
    });

    const database = []

    for(const user of users){
        const querySnapshot = await getDocs(collection(db, `usersLogs/${user}/logs`), orderBy('time', 'desc'));

        
        querySnapshot.forEach((docLog) => {
            // doc.data() is never undefined for query doc snapshots
            
            database.push({...docLog.data(), docId: user})
        });
    }

    const ordenatedArray = database.sort(function(a,b) {
        return a.time < b.time ? 1 : a.time > b.time ? -1 : 0;
    });

  
    const processedLogs = await processLogs(ordenatedArray)

    
    return processedLogs
}

const processLogs = async (logs) => {
    const dateNow = new Date();

    const dayInMiliseconds = (86400*1000)

    const dateStart = new Date(`1/1/2022`).getTime();
    const dateEnd = dateNow.getTime()+dayInMiliseconds;

   const array = []

    for(var i = dateStart; i < dateEnd; i = i + dayInMiliseconds){
        const find = logs.filter(x => x.time >= i && x.time < i + dayInMiliseconds)

        if(find.length > 0){
            let newLogs = []

            for(const logDoc of find){
                newLogs = newLogs.concat(logDoc.logs)
            }

            array.push({
                logs: newLogs,
                time: i
            })
        }
    }

    const ordenatedArray = array.sort(function(a,b) {
        return a.time < b.time ? 1 : a.time > b.time ? -1 : 0;
    });

    return ordenatedArray
}