import styled from "styled-components";

export const Container = styled.div`
    /* display: flex; */
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 256px;

    padding: 20px 34px;

    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    height: 200vh;
    width: calc(100% - 256px);


    background: var(--background);
    /* z-index: 5000; */
`;

export const Header = styled.div`
    width: 100%;
    height: 67px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 20px;

    h1 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 300;
        font-size: 32px;
        line-height: 38px;
        /* identical to box height */


        /* 1 */

        color: #4A5E83;
    }

    div {
        display: flex;
        flex-direction: row;

        gap: 20px;
        align-items: center;
    }
`;

export const TableContainer = styled.div`
    width: 100%;
    height: 180vh;

    padding: 20px;

    background: #FFFFFF;
    /* shadowcard */

    box-shadow: 0px 4px 20px rgba(35, 54, 63, 0.05);
    border-radius: 20px;
`;

export const TableHeader = styled.div`
    position: absolute;

    right: 65px;
    margin-top: 10px;
    /* left: 0; */
`;

export const Table = styled.div`
    display: flex;
    flex-direction: column;

    width: 100%;
`;

export const TableColumns = styled.div`
    display: grid;
    grid-template-columns: 1.5fr repeat(6, 1fr) 0.1fr;
    height: 46px;
    width: 100%;

    padding: 0 10px;

    align-items: center;
    /* justify-content: center; */

    background: rgba(236, 242, 251, 0.5);
    border-radius: 10px;

    color: #808B9F;

    h3 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;

        color: #808B9F;
    }
`;

export const TableList = styled.div`
    /* display: flex;
    flex-direction: column; */

    overflow-y: auto;
    width: 100%;
    min-height: 165vh;
    height: 165vh;
`;