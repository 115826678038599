import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px 15px;
`;

export const HeaderContent = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    gap: 20px;

    h4 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        /* identical to box height */

        color: #4A5E83;
    }

    h5 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;

        /* 2 */

        color: #808B9F;
    }
`;

export const Divider = styled.div`
    width: 260px;
    margin-top: 20px;
    margin-bottom: 20px;
    height: 0.1px;

    border: 0.5px solid #ECF2FB;
`;

export const FooterContent = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    gap: 20px;

    cursor: pointer;

    h4 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;

        /* DE6161 */

        color: #DE6161;
    }
`;