import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    width: 34px;
    height: 34px;
    border-radius: 100px;

    cursor: pointer;
    user-select: none;

    background: #DE6161;
`;
