import React from 'react';

import { Container, Text } from './styles';

const ElipseIcon = ({ text, color, background, size, fontSize, fontWeight}) => {
    return (
        <Container 
            background={background} 
            size={size} 
        >
            <Text 
                color={color} 
                fontSize={fontSize}
                fontWeight={fontWeight}
            >
                {text}
            </Text>
        </Container>
    )
}

export default ElipseIcon;