import React, { useState } from 'react';

import { Container, ButtonFilter, MoreFilters, ModalFilterItem, ModalFilterContainer, TextClearFilters, ConfirmButton, SelectContent } from './styles';

//icons
import { IoMdArrowDropdown, IoIosAddCircleOutline } from 'react-icons/io';
import { BsEraser } from 'react-icons/bs';

//Custom components
import ModalHeader from '../ModalHeader';
import DateFilter from '../DateFilter';

//Utils
import Select from 'react-select'
import Modal from 'react-modal';

//MUI
import Menu from '@mui/material/Menu';
import { filtersHelper } from '../../helpers/filtersHelper';
import { arrayHelper } from '../../helpers/arrayHelper';

const CustomFilter = ({ filters, filterTypes, tableRowsBackup, filtersHighlights, 
    dateField, onChangeFilters, onClearFilters }) => {

    const [ modalMoreFilters, setModalMoreFilters ] = useState(false);
    const [ selectedField, setSelectedField ] = useState('');

    const [ options, setOptions ] = useState([]);

    //Date filter
    const [ filterCalendar, setFilterCalendar ] = useState(false);

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const clearAllFilters = () => {
        onClearFilters()
    }

    const handleMenuClose = () => {
        setOptions([])
        setSelectedField('');
        setAnchorEl(null);
    }

    const handleMenuOpen = (event, headerName) => {
        if(headerName == 'Data'){
            setFilterCalendar(true);
        }else{
            setFilterCalendar(false)
        }

        setSelectedField(event.currentTarget.id)
        setAnchorEl(event.currentTarget);
    };

    const handleChangeFilters = async (newValueArray, field) => {
        
        const exists = filters.findIndex(x => x.field === field);

        if(exists != -1){
            if(newValueArray.length > 0){
                const newObject = {
                    ...filters[exists],
                    values: newValueArray
                }
    
                const newArray = arrayHelper.replaceItemOfArray(filters, exists, newObject)
    
                onChangeFilters(newArray)
            }else{
                const newArray = arrayHelper.removeItemOfArray(filters, exists)
    
                onChangeFilters(newArray)
            }
        }else{
            const newObject = {
                field: field,
                type: 'string',
                values: newValueArray
            }
            
            onChangeFilters([...filters, newObject])
        }
    }

    const handleChangeDateFilter = (calendarValues, field) => {
        const exists = filters.findIndex(x => x.field === field);

        if(exists != -1){
            if(calendarValues?.selectedStart || calendarValues?.selectedEnd){
                const newObject = {
                    ...filters[exists],
                    values: calendarValues
                }
    
                const newArray = arrayHelper.replaceItemOfArray(filters, exists, newObject)
    
                onChangeFilters(newArray)
            }else{
                const newArray = arrayHelper.removeItemOfArray(filters, exists)
    
                onChangeFilters(newArray)
            }
        }else{
            const newObject = {
                field: field,
                type: 'date',
                reportType: 'atendimentos',
                values: calendarValues
            }
            
            onChangeFilters([...filters, newObject])
        }
    }

    const buttonHandle = (label, field) => {
        const exists = filters.find(x => x.field === field);

        if(exists){
            if(exists.type === 'string'){
                if(exists.values?.length > 1){
                    return `${exists.values[0].value} +${exists.values.length -1}`
                }else{
                    return `${exists.values[0].value}`
                }
            }

            if(exists.type === 'date'){
                const selectedStart = exists?.values?.selectedStart;
                const selectedEnd = exists?.values?.selectedEnd;

                if(selectedStart && selectedEnd?.day){
                    return `${selectedStart.day}/${selectedStart.month}/${selectedStart.year} - 
                        ${selectedEnd.day}/${selectedEnd.month}/${selectedEnd.year}`
                }

                if(selectedStart){
                    return `${selectedStart.day}/${selectedStart.month}/${selectedStart.year}`
                }
            }
        }else{
            return `${label}`
        }
    }

    const getNumberMoreFilters = () => {
        const filtersFields = arrayHelper.reduceToSimple(filters, 'field');

        var count = 0;

        for(const field of filtersFields){
            const result = filtersHighlights.find(x => x === field);

            if(!result){
                count++
            }
        }

        if(count != 0){
            return `(${count})`;
        }else{
            return '';
        }
    }

    const getOptions = (selectedField) => {
        setOptions(filtersHelper.getFilterOptions(tableRowsBackup, selectedField, filters))
    }

    const customStyle = {      
        container: (base) => ({
            ...base,
            borderRadius: 40
        }),
        control: (base) => ({
            ...base,
            padding: 3,
            borderRadius: 40
        }),
        multiValue: (base) => ({
            ...base,
            borderRadius: 40
        }),
        menuList: (base) => ({
            ...base,
            height: 260
        })
    }  

    return <Container>
            {filtersHighlights.map((item, index) => (
                    <ButtonFilter 
                        key={index} 
                        id={item.field} 
                        onClick={(event) => handleMenuOpen(event, item.headerName)}
                    >
                        <h4>{buttonHandle(item.headerName, item.field)}</h4>
                        <IoMdArrowDropdown style={{ cursor: 'pointer'}} size={18} color='#808B9F' fill='#808B9F' />
                    </ButtonFilter>
            ))}

            <MoreFilters onClick={() => setModalMoreFilters(true)}>
                <IoIosAddCircleOutline style={{ cursor: 'pointer', marginRight: -5 }} size={15} color='#DE6161'  fill='#DE6161' />
                <h5>Mais </h5>
            </MoreFilters>

            <TextClearFilters onClick={() => clearAllFilters()}>
                <BsEraser style={{ cursor: 'pointer', marginRight: -5 }} />
                Limpar {getNumberMoreFilters()}
            </TextClearFilters>

            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleMenuClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                PaperProps={{  
                    style: {  
                        width: 400,
                        height: 400,
                        padding: 10,
                        borderRadius: 15
                    },  
                }} 
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {filterCalendar
                    ?<DateFilter 
                        filters={filters}
                        dateField={dateField}
                        onFilterDate={(values) => handleChangeDateFilter(values, dateField)} 
                        clearDateFilter={() => handleChangeDateFilter(null, dateField)}
                        onClose={handleMenuClose}
                    />
                    :<SelectContent>
                        <Select 
                            isClearable={false} 
                            isSearchable={true} 
                            defaultValue={selectedField && filters && filtersHelper.getDefaultValues(selectedField, filters)}
                            closeMenuOnSelect={false} 
                            options={options}
                            isMulti={true} 
                            styles={customStyle}
                            autoFocus={true}
                            openMenuOnFocus={true}
                            onMenuOpen={() => getOptions(selectedField)}
                            onChange={(item) => handleChangeFilters(item, selectedField)}
                        />
                        <ConfirmButton onClick={() => handleMenuClose()}>
                            <h3>OK</h3>
                        </ConfirmButton>
                    </SelectContent>
                }
            </Menu>

            <Modal
                isOpen={modalMoreFilters}
                onRequestClose={() => {
                    setModalMoreFilters(false)
                }}
                overlayClassName="modal-overlay"
                className="modal-content"
                contentLabel="Example Modal"
                style={{overlay: {
                    overflowY:"scroll"
                }}}
            >
                <ModalFilterContainer>
                    <ModalHeader title='Filtros' onClose={() => {
                        setModalMoreFilters(false)
                    }} />
                    {filterTypes.map((item, index) => {
                        if(item.headerName != "Data" && item.headerName != 'Ações'){
                            return (
                                <ModalFilterItem key={`filter-${index}`}>
                                    <h4>{item.headerName}</h4>
                                    <Select 
                                        key={`filter-${index}`}
                                        isClearable={false} 
                                        isSearchable={true} 
                                        defaultValue={item.field && filters && filtersHelper.getDefaultValues(item.field, filters)}
                                        closeMenuOnSelect={false} 
                                        options={options} 
                                        isMulti={true} 
                                        styles={customStyle}
                                        onMenuOpen={() => {
                                            setSelectedField(item.field)
                                            getOptions(item.field)
                                        }}
                                        onChange={(text) => {
                                            handleChangeFilters(text, item.field)
                                        }}
                                    />
                                </ModalFilterItem>
                            )
                        }
                    })}
                </ModalFilterContainer>
            </Modal>
    </Container>
}

export default CustomFilter;