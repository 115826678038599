import styled from "styled-components";

export const ModalContainer = styled.div`
    min-width: 50vw;
    padding: 20px;
`;

export const SubHeader = styled.div`
    display: flex;
    flex-direction: row;

    align-items: center;

    gap: 20px;

    margin: 20px 0;

    color: var(--red);

    h1 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;

        /* 2 */

        color: #808B9F;
    }

    div {
        display: flex;
        flex-direction: row;

        align-items: center;
        gap: 5px;
    }

    h4 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;

        /* 2 */

        color: #808B9F;
    }
`;

export const ModalListHeader = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr) 0.5fr;
    min-height: 60px;
    width: 100%;

    padding: 0 10px;

    align-items: center;
    /* justify-content: center; */

    background: rgba(236, 242, 251, 0.5);
    border-radius: 10px;

    h3 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        /* identical to box height */


        /* 2 */

        color: #808B9F;
    }
`;

export const ModalListContainer = styled.div`
    display: flex;
    flex-direction: column;

    padding-top: 10px;
    overflow-y: auto;
    height: 450px;
`;

export const ModalItem = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr) 0.5fr;

    padding: 0 15px;

    align-items: center;
    min-height: 40px;

    gap: 5px;

    div {
        display: flex;
        flex-direction: row;
        align-items: center;

        max-width: 95%;

        color: ${props => props.color};

        gap: 10px;
    }

    h4 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;

        color: #808B9F;
    }
`;

export const SwitchLogType = styled.div`
    display: flex;
    flex-direction: row;
    height: 50px;
    gap: 20px;

    margin: 20px 0;
`;

export const SwitchButton = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    color: ${props => props.selected ? 'var(--red)' : 'var(--grey2)'};

    cursor: pointer;
    user-select: none;

    div {
        background: var(--red);
        height: 2px;
        width: 100%;
    }
`;