import justica from '../assets/badges/JUSTICA.png';
import excelente from '../assets/badges/EXCELENTE.png';

const outOfMap = ['foraDoMapa', 'done']

export const badges = {
    getClientBadges,
}

function getClientBadges(client){
    const array = []

    if(!outOfMap.includes(client.selected)){
        array.push({ title: 'JUSTIÇA LIVRE', path: justica })
    }

    if(client.specialIcon){
        array.push({ title: 'EXCELENTE', path: excelente })
    }
    
    return array
}