import FuzzySet from "fuzzyset";
import { arrayHelper } from "./arrayHelper";

export const searchHelper = {
    searchText,
    searchTable
}

function isNumeric(str) {
    var er = /^[0-9]+$/;
    return (er.test(str));
}

function searchText(baseWord, searchWord){
    if(!baseWord || !searchWord) return false
    
    if(isNumeric(searchWord)){
       if(baseWord.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase().includes(
        searchWord.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase()))
        {
            return true
        }
    }else{
        if(baseWord.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase().includes(
            searchWord.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase())
            || searchWord.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase().includes(
                baseWord.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase()))
        {
            return true
        }
    }

    return false
}

function searchTable(search, tableColumns, tableRowsBackup){
    var arrayResult = [];

    if(search && tableRowsBackup){
        if(Number(search)){
            const result = tableRowsBackup.filter(x => {
                if(searchHelper.searchText(x['cpf'], search) 
                    || searchHelper.searchText(x['clientCpf'], search))
                {
                    return true
                }                

                return false
            })

            if(result.length > 0){
                arrayResult = arrayResult.concat(result)
            }
        }else{
            const field = tableRowsBackup[0].clientName ? 'clientName' : 'nome';

            const result = tableRowsBackup.filter(x => {
                if(searchHelper.searchText(x['clientName'], search) 
                    || searchHelper.searchText(x['nome'], search))
                {
                    return true
                }

                return false
            })

            if(result.length > 0){
                arrayResult = arrayResult.concat(result)
            }

            const formattedList = []

            for(const item of arrayHelper.ordenateArrayAsc(arrayResult, field)){
                const parts = item[field].split(' ')

                let rating = 0
                let stop = false

                for(const index in parts){
                    const fuzy = FuzzySet([parts[index]])
                    const result = fuzy.get(search, [], 0.35)

                    if(result.length > 0){
                        const newRate = result[0][0]

                        if(!stop){
                            rating = rating + newRate
                            stop = true
                        }
                    }else{
                        if(!stop){
                            rating = rating - 1
                        }
                    }
                }

                formattedList.push({
                    rating: rating,
                    value: item[field],
                    item: item
                })
            }

            const finalResult = arrayHelper.ordenateArrayDesc(formattedList, 'rating')

            arrayResult = arrayHelper.reduceToSimple(finalResult, 'item')
        }
    }else{
        return arrayResult
    }

    return arrayResult
}