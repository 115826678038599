import React, { useEffect, useState } from 'react';

import { Container, ProgressBar, ProgressText } from './styles';

const validStatus = ['aguardando', 'iniciado', 'parcial', 'finalizado', 'cancelado']
const colorsStatus = ['var(--grey1)', 'var(--orange)', 'var(--yellow)', 'var(--success)', 'var(--red)']

function ProgressIcon({ data }) {

    const [ progress, setProgress ] = useState(0);

    useEffect(() => {
        var atendido = 0;
        for(const point of data.points){
            if(point.atendido){
                atendido++
            }
        }

        setProgress(atendido)
    }, [data]);

    return (
        <Container>
            <ProgressBar 
                progress={progress > 0 ? (progress*100)/data.points.length : 0} 
                background={colorsStatus[validStatus.findIndex(x => x === data.status)]}
                barBackground={data.status == 'cancelado' ? 'var(--red)' : 'var(--background)'}
            >
                <div></div>
            </ProgressBar>
            <ProgressText>
                {progress}/{data.points.length}
            </ProgressText>
        </Container>
    )
}

export default ProgressIcon;