import React, { useContext, useState } from 'react';

import { Container } from './styles';

//Firebase and context
import { AuthContext } from '../../contexts/AuthContext';

//Icons
import { IoIosArrowDown } from 'react-icons/io';

//MUI
import { Avatar } from '@mui/material';
import LogoutMenu from '../LogoutMenu';
import Menu from '@mui/material/Menu';
import { avatar } from '../../helpers/avatar';


const ProfileButton = ({ arrowColor }) => {

    const { userData } = useContext(AuthContext);

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    return (
        <Container>
            <Avatar
                alt="Remy Sharp"
                src={avatar.getAvatarByData(userData)}
                sx={{ width: 46, height: 46, borderRadius: 2, borderColor: 'white', borderWidth: 20 }}
                onClick={(event) => setAnchorEl(event.currentTarget)}
            />
            <IoIosArrowDown 
                onClick={(event) => setAnchorEl(event.currentTarget)}
                size={20} color={arrowColor} 
                fill={arrowColor} 
                style={{ cursor: 'pointer'}}
            />

            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{  
                    style: {  
                        marginTop: 18,
                        marginRight: '50%',
                        borderRadius: 20
                    },  
                }} 
            >
                <LogoutMenu />
            </Menu>
        </Container>
    )
}

export default ProfileButton;