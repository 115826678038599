import styled from "styled-components";

export const Container = styled.div`
    /* display: flex; */
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 256px;

    padding: 20px 34px;

    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    height: 200vh;
    width: calc(100% - 256px);


    background: var(--background);
    /* z-index: 5000; */
`;

export const Header = styled.div`
    width: 100%;
    height: 67px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 20px;

    h1 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 300;
        font-size: 32px;
        line-height: 38px;
        /* identical to box height */


        /* 1 */

        color: #4A5E83;
    }

    div {
        display: flex;
        flex-direction: row;

        gap: 20px;
        align-items: center;
    }
`;

export const AdminCardContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    overflow-y: auto;
    max-height: 165vh;
`;

export const UsersCardsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);

    gap: 20px;

    overflow-y: auto;
    max-height: 165vh;
`;

export const UserCard = styled.div`
    display: flex;
    flex-direction: column;

    padding: 20px;
    background: #FFFFFF;
    /* shadowcard */

    box-shadow: 0px 4px 20px rgba(35, 54, 63, 0.05);
    border-radius: 20px;
`;

export const UserCardHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    color: var(--blue4);

    user-select: none;
    
    height: 56px;

    div {
        display: flex;
        justify-content: center;

        column-gap: 20px;
    }
`;

export const UserName = styled.div`
    display: flex;
    flex-direction: column;

    row-gap: 5px;

    h3 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 23px;

        color: #4A5E83;
    }

    h4 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;

        color: #808B9F;
    }
`;

export const UserCardContent = styled.div`
    gap: 20px;
    margin-top: 20px;
`;

export const FieldText = styled.p`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;

    margin-bottom: 5px;

    color: #4A5E83;
`;

export const UserCardText = styled.div`
    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: space-between;
    gap: 10px;

    margin: 15px 10px 0 0;

    color: var(--blue4);

    div {
        display: flex;

        gap: 5px;

        align-items: center;
    }

    span {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 24px;

        color: #4A5E83;
    }

    h4 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;

        color: #4A5E83;
    }
`;

export const NewUserButton = styled.div`
    display: flex;
    justify-content: space-evenly;
    gap: 10px;

    padding: 12px 30px;

    color: var(--white);
    user-select: none;
    cursor: pointer;

    background: #DE6161;
    box-shadow: 0px 7px 10px rgba(228, 73, 73, 0.25);
    border-radius: 100px;

    h3 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;

        /* 5 */

        color: #FFFFFF;
    }
`;

export const ConfigButton = styled.div`
    display: flex;
    justify-content: space-evenly;
    gap: 10px;

    padding: 12px 30px;

    color: #808B9F;
    user-select: none;
    cursor: pointer;

    background: var(--background);
    border: 1px solid #B4C1D5;
    border-radius: 100px;

    h3 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;

        color: #808B9F;
    }
`;

export const FormContainer = styled.div`
    width: 95%;
    margin-top: 20px;
    padding: 20px;

    h1 {
        color: #9a4749;
        font-weight: bold;
    }
`;

export const FormContent = styled.div`
    width: 100%;
    margin-top: 20px;
    padding: 10px;

    display: flex;
    flex-direction: row;
    justify-content: space-around;
`;

export const InputUser = styled.input`
    font-size: 18px;
    color: #9a4749;
    line-height: 1.2;
    border: 1px solid #9a4749;
    width: 25%;
    
    height: 40px;

    background-color: transparent;
    padding: 0 5px;
`;

export const InputPassword = styled.div`
    border: 1px solid #9a4749;
    width: 25%;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    input{
        font-size: 18px;
        color: #9a4749;
        line-height: 1.2;
        width: 90%;
        height: 100%;
        border: none;
        background-color: transparent;
        padding: 5px;
    }

    svg{
        margin: 2% 2% 2% 2%;
        cursor: pointer;
    }
`;

export const ButtonAddUser = styled.div`
    justify-content: center;
    padding-bottom: 13px;
    margin-top: 5px;
    max-width: 250px;

    button {
        font-size: 15px;
        border: none;
        border-radius: 8px;
        color: #fff;
        padding: 10px;

        line-height: 1.2;

        text-transform: uppercase;

        display: flex;
        justify-content: center;
        align-items: center;

        width: 100%;
        height: 30px;

        background: #9a4749;
    }

    &:hover .button-add-user {
        cursor: pointer;
        background: #ca787a;
        color: #fff;
    }
`;

export const Content = styled.div`
    width: 95%;
    margin-top: 50px;
    padding: 40px;

    display: grid;
    grid-template-columns: repeat(2, 2fr);
    grid-template-rows: repeat(2, 2fr);
    grid-gap: 20px;

    align-self: center;
    justify-content: center;
    align-items: center;

    background: #fff;
    border-radius: 20px;
    border: 0.5px solid #EAECEE;
    box-shadow: 0 5px 5px 0px rgba(0, 0, 0, 0.5);
`;

export const RowContent = styled.div`
    display: flex;
    flex-direction: grid;
    grid-template-columns: 2 2fr;

    width: 100%;
`;

export const ImageInput = styled.input`
    display: flex;
    padding: 20px;

    width: 100%;
`;

export const ImagePreview = styled.div`
    display: flex;
    padding: 20px;

    width: 100%;
    max-height: 200;
    justify-content: center;
    align-items: center;
`;

export const ButtonsContainer = styled.div`
    width: 100%;
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
`;

export const ButtonEditUser= styled.div`
    display: flex;
    justify-content: center;
    padding-bottom: 13px;
    margin-top: 5px;

    button {
        font-size: 14px;
        border: none;
        border-radius: 8px;
        color: #fff;
        padding: 5px;

        line-height: 1.2;

        display: flex;
        justify-content: center;
        align-items: center;

        width: 100%;
        height: 30px;

        background: #626567;
    }

    &:hover .button-change-password {
        cursor: pointer;
        background: #fff;
        font-weight: bold;
        color: #000;
        border: 2px solid #adadad;
    }
`;

export const ButtonChangePassword = styled.div`
    display: flex;
    justify-content: center;
    padding-bottom: 13px;
    margin-top: 5px;

    button {
        font-size: 14px;
        border: none;
        border-radius: 8px;
        color: #fff;
        padding: 5px;

        line-height: 1.2;

        display: flex;
        justify-content: center;
        align-items: center;

        width: 100%;
        height: 30px;

        background: #626567;
    }

    &:hover .button-change-password {
        cursor: pointer;
        background: #fff;
        font-weight: bold;
        color: #000;
        border: 2px solid #adadad;
    }
`;

export const ButtonDeleteUser = styled.div`
    display: flex;
    justify-content: center;
    padding-bottom: 13px;
    margin-top: 5px;

    button {
        font-size: 14px;
        border: none;
        border-radius: 8px;
        color: #fff;
        padding: 5px;

        line-height: 1.2;

        display: flex;
        justify-content: center;
        align-items: center;

        width: 100%;
        height: 30px;

        background: #9a4749;
    }

    &:hover .button-delete-user {
        cursor: pointer;
        background: #ca787a;
        color: #fff;
    }
`;

export const InputModal = styled.input`
    font-size: 18px;
    color: #9a4749;
    line-height: 1.2;
    border: 1px solid #9a4749;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 15px;
    
    height: 40px;

    background-color: transparent;
    padding: 0 5px;
`;

export const TextShowPassword = styled.h4`
    font-size: 14px;
    color: #adadad;
    line-height: 1.5;
    padding-right: 5px;
    text-decoration: none;
    text-align: center;
    margin-bottom: 40px;

    &:hover .text-show-password {
        cursor: pointer;
    }
`;

export const TextPasswordLength = styled.h4`
    font-size: 14px;
    color: ${props => props.color};
    line-height: 1.5;
    padding: 5px;
    margin-right: 5px;
    text-decoration: none;
    text-align: center;
    margin-bottom: 10px;
`;

export const LoadingButton = styled.div`
    display: flex;
    justify-content: center;
    padding-bottom: 13px;
    margin-top: 5px;
    width: 100%;
    height: 30px;
`;

export const SwitchType = styled.div`
    display: flex;
    flex-direction: row;
    height: 50px;
    gap: 20px;
    margin-bottom: 20px;
`;

export const SwitchButton = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;

    color: #4A5E83;

    cursor: pointer;
    user-select: none;

    div {
        background: #4A5E83;
        height: 2px;
        width: 100%;
    }
`;

export const EspeciesContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
    box-sizing: border-box;
    padding: 10px 0;
    border-top: 1px solid var(--grey1);
    border-bottom: 1px solid var(--grey1);

    h4 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;

        margin: 10px 0;

        color: #4A5E83;
    }
`;

export const EspeciesHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    user-select: none;
`;

export const EspeciesItem = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;

    h3 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 24px;

        color: #4A5E83;
    }
`;

export const EspeciesSelector = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    box-sizing: border-box;
`;

export const EspeciesSelectorItem = styled.div`
    display: flex;
    height: 34px;
    width: 34px;
    align-items: center;
    justify-content: center;
    background: var(--background);
    border-radius: 10px;
    cursor: pointer;

    img {
        height: 24px;
        width: 24px;
        opacity: ${({ selected }) => selected ? 1 : 0.4};
    }
`;

export const SaveEspeciesButton = styled.div`
    display: flex;
    justify-content: space-evenly;
    gap: 10px;

    padding: 5px 10px;
    margin: 10px 0;

    color: var(--white);
    user-select: none;
    cursor: pointer;

    background: #DE6161;
    box-shadow: 0px 7px 10px rgba(228, 73, 73, 0.25);
    border-radius: 100px;

    h3 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 24px;

        /* 5 */

        color: #FFFFFF;
    }
`;