import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: ${props => props.size ? `${props.size}px`: `${18}px`};
    width: ${props => props.size ? `${props.size}px`: `${18}px`};

    padding: 2px;
    border-radius: 360px;
    background: ${props => props.backgroundColor ? props.backgroundColor : 'var(--red)'};
`;

export const Text = styled.div`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: ${props => props.fontWeight ? props.fontWeight : 'normal'};
    font-size: ${props => props.fontSize ? `${props.fontSize}px`: `${8}px`};

    /* 5 */

    color: ${props => props.textColor ? props.textColor : '#fff'}
`;