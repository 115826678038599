import { collection, getDocs, orderBy, query } from "firebase/firestore";
import db from '../../config';

//Get logs do mapa
export async function getMapLogs(mapId){
    const array = []

    const consulta = query(collection(db, `mapsLogs/${mapId}/logs`), 
        orderBy("time", 'desc'));

    const querySnapshot = await getDocs(consulta);

    
    querySnapshot.forEach(async (doc) => {
        array.push({...doc.data(), docId: doc.id});
    });

    return array
}