import React, { useEffect, useState } from 'react';

import { Container, Header, Table, TableColumns, TableContainer, TableHeader, TableList } from './styles';

import { CancelButton, SearchBar, SearchButton, SearchContainer, ClearSearch, TableFooter } from '../../components/CustomTable/styles';

//Icons
import { FiSearch } from 'react-icons/fi';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';

//Custom components
import ProfileButton from '../../components/ProfileButton'
import CustomLoadingPage from '../../components/CustomLoadingPage'
import DatabaseItem from '../../components/DatabaseItem'
import ModalDetailsAdmin from '../../components/ModalDetailsAdmin';

//Utils
import Modal from 'react-modal'
import tableColumnsReturns from '../../resources/tableColumnsReturns.json';

//MUI
import Menu from '@mui/material/Menu';
import CustomFilterHeader from '../../components/CustomFilterHeader';
import { searchHelper } from '../../helpers/searchHelper';
import { filtersHelper } from '../../helpers/filtersHelper';
import useTotalClients from '../../hooks/useTotalClients';
import DownloadButton from '../../components/DownloadButton';

import tableColumnsDatabase from '../../resources/tableColumnsDatabase.json';

const perPage = 25;

function DatabasePage() {

    const { totalClients } = useTotalClients();

    const [ filters, setFilters ] = useState([]);

    const [ modalFullData, setModalFullData ] = useState(false);
    const [ currentClient, setCurrentClient ] = useState('');

    const [ rangeOfList, setRangeOfList ] = useState({ init: 0, finish: perPage })

    const [ search, setSearch ] = useState('');
    const [ draftSearch, setDraftSearch ] = useState('');
    const [ searchBar, setSearchBar ] = useState(null);
    const openSearch = Boolean(searchBar);

    const clearSearch = () => {
        setSearch('')
        setDraftSearch('')
    }

    const handleSearch = () => {
        setSearchBar(null)
        setSearch(draftSearch)
    }

    useEffect(() => {
        if(!draftSearch){
            clearSearch()
        }
    }, [draftSearch])

    if(!totalClients){
        return (
            <Container>
                <CustomLoadingPage />
            </Container>
        )
    }

    const filterByTable = () => {
        return filtersHelper.filterDataTable(totalClients, filters)
    }

    const clearFilters = () => {
        setFilters([])
    }

    const baseTableData = filters.length > 0 ? filterByTable(totalClients) : totalClients

    const backupTableData = totalClients
    
    const tableData = search ? 
        searchHelper.searchTable(search, tableColumnsReturns, backupTableData) : baseTableData

    return (
        <Container>
            <Header>
                <div>
                    <h1>Banco de dados</h1>
                    <FiSearch 
                        onClick={(event) => {
                            setSearchBar(event.currentTarget);
                        }}
                        size={20} 
                        cursor='pointer' 
                        color='var(--grey2)' 
                    />
                    <ClearSearch>
                        {draftSearch && (
                            <>
                                {draftSearch}
                                <AiOutlineCloseCircle cursor='pointer' onClick={() => clearSearch()} />
                            </>
                        )}
                    </ClearSearch>
                </div>
                <div>
                    <CustomFilterHeader 
                        dateField='time'
                        filters={filters}
                        filterTypes={[
                            { headerName: 'Operador', field: 'operatorName'},
                            { headerName: 'Resultado', field: 'result'}
                        ]}
                        tableRowsBackup={backupTableData}
                        onChangeFilters={(newFiltersArray) => setFilters(newFiltersArray)}
                        onClearFilters={() => clearFilters()}
                    />
                    <ProfileButton arrowColor='var(grey2)' />
                </div>
            </Header>

            <TableContainer>
                <TableHeader>
                    <DownloadButton tableColumns={tableColumnsDatabase} tableRows={tableData} />
                </TableHeader>
                <Table>
                    <TableColumns>
                        <h3>Cliente</h3>
                        <h3>CPF</h3>
                        <h3>Operador</h3>
                        <h3>Benefício</h3>
                        <h3>Resultado</h3>
                        <h3>Cidade</h3>
                        <h3>Data</h3>
                    </TableColumns>
                    <TableList>
                        {tableData && tableData.slice(rangeOfList.init, rangeOfList.finish).map((item, index) => (
                            <DatabaseItem 
                                key={index}
                                onView={() => {
                                    setCurrentClient(item)
                                    setModalFullData(true)
                                }}
                                data={item}
                            />
                        ))}
                    </TableList>
                    <TableFooter>
                        <h3>{rangeOfList.init} - {rangeOfList.finish}</h3>
                        <div>
                            <MdKeyboardArrowLeft 
                                size={20}
                                cursor='pointer'
                                onClick={() => {
                                    if(rangeOfList.finish == tableData.length){
                                        const value = (rangeOfList.finish - rangeOfList.init) + perPage;

                                        const newFinish = rangeOfList.init;

                                        setRangeOfList({ init: rangeOfList.finish - value, finish: newFinish})
                                    }else{
                                        if((rangeOfList.init - perPage) >= 0){
                                            setRangeOfList({ init: rangeOfList.init - perPage, finish: rangeOfList.finish-perPage})
                                        }
                                    }
                                }}
                            />
                            <MdKeyboardArrowRight 
                                size={20}
                                cursor='pointer'
                                onClick={() => {
                                    if(tableData.length > perPage){
                                        if((rangeOfList.finish + perPage) <= tableData.length){
                                            setRangeOfList({ init: rangeOfList.finish, finish: rangeOfList.finish+perPage})
                                        }else{
                                            if(rangeOfList.finish != tableData.length){
                                                const value = rangeOfList.finish+(tableData.length - rangeOfList.finish);
            
                                                setRangeOfList({ init: rangeOfList.finish, finish: value})
                                            }
                                        }
                                    }
                                }}
                            />
                        </div>
                        <h3>{tableData ? tableData.length : 0} clientes</h3>
                    </TableFooter>
                </Table>
            </TableContainer>

            <Modal
                isOpen={modalFullData}
                onRequestClose={() => setModalFullData(false)}
                overlayClassName="modal-overlay"
                className="modal-content-auto-width"
                contentLabel="Example Modal"
            >
                <ModalDetailsAdmin 
                    data={currentClient} 
                    onClose={() => setModalFullData(false)}
                    onStreetView={() => {

                    }}
                />
            </Modal>

            <Menu
                id="basic-menu"
                anchorEl={searchBar}
                open={openSearch}
                onClose={() => {
                    setSearchBar(null);
                }}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                PaperProps={{
                    style: {
                        width: 600,
                        height: 40,
                        maxHeight: 40,
                        borderRadius: 20,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        overflow: 'hidden',
                    },
                }}
            >
                <SearchContainer onSubmit={(e) => { 
                    e.preventDefault();
                    handleSearch();
                }}>
                    <FiSearch 
                        size={18} 
                        cursor='pointer' 
                        color='#626F87'
                        strokeWidth='3'
                    />
                     <p>&#8288;</p>
                    <SearchBar>
                        <input 
                            name="draftSearch" 
                            onChange={event => setDraftSearch(event.target.value)}
                            value={draftSearch}
                            placeholder='Buscar...'
                            autoFocus={true}
                        />
                    </SearchBar>
                    <CancelButton onClick={() => {
                        setSearchBar(null);
                    }}>
                        Cancelar
                    </CancelButton>
                    <SearchButton onClick={() => handleSearch()}>
                        Buscar
                    </SearchButton>
                </SearchContainer>
            </Menu>
        </Container>
    )
}

export default DatabasePage;