import React, { useState, useContext, useEffect } from 'react';

import { 
    Container,
    Title,
    OperatorsList,
    HeaderList,
    ListContainer,
    OperatorItem,
    OperatorsInfo,
    HeaderInfo,
    InfoItem,
    Circle,
    OperatorsListLoading,
    OperatorsInfoLoading
} from './styles';

//Firebase and context
import { UsersContext } from '../../contexts/UsersContext';

//Icons
import checkedIcon from '../../assets/checkedIcon.svg';
import clockIcon from '../../assets/clockIcon.svg';
import expiredIcon from '../../assets/expiredIcon.svg';
import expiredIconWhite from '../../assets/expiredIconWhite.svg';

import { FiUsers, FiCheckSquare } from 'react-icons/fi';
import { TbRoute, TbFileInvoice } from 'react-icons/tb';
import { AiOutlineClockCircle } from 'react-icons/ai';

//MUI
import { Avatar } from '@mui/material';
import CustomLoading from '../CustomLoading';
import { avatar } from '../../helpers/avatar';

function OperatorsCard({ operatorsData, onFilter, unFilter }) {

    const [ selected, setSelected ] = useState(0);

    const handleSelect = (item, index, type) => {
        if(index == selected){
            unFilter()
            setSelected(0)
        }else{
            if(type === 'operator'){
                setSelected(index)
                onFilter([item.uid])
            }else{
                onFilter([item.uid, type])
            }
        }
    }

    if(!operatorsData){
        return (
            <Container>
                <OperatorsListLoading>
                    <CustomLoading />
                </OperatorsListLoading>
                <OperatorsInfoLoading>
                    <CustomLoading />
                </OperatorsInfoLoading>
            </Container>
        )
    }
   
    return (
        <Container>
            <OperatorsList>
                <Title>
                    <FiUsers />
                    <h3>Operadores</h3>
                </Title>
                <HeaderList>
                    <h3>Operador</h3>
                    <h3>Realizados</h3>
                    <h3>Pendentes</h3>
                    <h3>Expirados</h3>
                </HeaderList>
                <ListContainer>
                    {operatorsData.map((item, index) => {

                        if(item.userName != 'Geral'){
                            return (
                                <OperatorItem key={index} active={selected == index ? true : false} onClick={() => handleSelect(item, index, 'operator')}>
                                    <div>
                                        <Avatar
                                            alt="Avatar"
                                            src={avatar.getAvatarByData(item)}
                                            sx={{ width: 30, height: 30, borderRadius: 100, borderColor: 'white', borderWidth: 20 }}
                                        />
                                        <h4>{item.userName}</h4>
                                    </div>
                                    <div>
                                        <img src={checkedIcon} alt='icon icon' />
                                        <h4>{item.realizedReturns.length}</h4>
                                    </div>
                                    <div>
                                        <img src={clockIcon} alt='icon icon' />
                                        <h4>{item.pendingReturns.length}</h4>
                                    </div>
                                    <div>
                                        <img src={expiredIcon} alt='icon icon' />
                                        <h4>{item.expiredReturns.length}</h4>
                                    </div>
                                </OperatorItem>
                            )
                        }
                    })}
                </ListContainer>
            </OperatorsList>
            {operatorsData.length > 0 && (
                <OperatorsInfo>
                    <HeaderInfo>
                        <Avatar
                            alt="Avatar"
                            src={avatar.getAvatarByData(operatorsData[selected])}
                            sx={{ 
                                marginTop: -5, 
                                width: 100, 
                                height: 100, 
                                borderRadius: 100, 
                                borderColor: 'white', 
                                borderWidth: 10 
                            }}
                        />
                        <h3>{operatorsData[selected].userName}</h3>
                    </HeaderInfo>
                    <InfoItem color='var(--success)' onClick={() => {
                        handleSelect(operatorsData[selected], null, 'realized')
                    }}>
                        <div>
                            <Circle color='var(--success)'>
                                <FiCheckSquare />
                            </Circle>
                            <h3>Retornos realizados</h3>
                        </div>
                        <h1>{operatorsData[selected].realizedReturns.length}</h1>
                    </InfoItem>
                    <InfoItem color='var(--yellow)' onClick={() => {
                        handleSelect(operatorsData[selected], null, 'pending')
                    }}>
                        <div>
                            <Circle color='var(--yellow)'>
                                <AiOutlineClockCircle />
                            </Circle>
                            <h3>Retornos a realizar</h3>
                        </div>
                        <h1>{operatorsData[selected].pendingReturns.length}</h1>
                    </InfoItem>
                    <InfoItem color='var(--blue)' onClick={() => {
                        handleSelect(operatorsData[selected], null, 'inRoute')
                    }}>
                        <div>
                            <Circle color='var(--blue)'>
                                <TbRoute />
                            </Circle>
                            <h3>Retornos em rota</h3>
                        </div>
                        <h1>{operatorsData[selected].inRouteReturns.length}</h1>
                    </InfoItem>
                    <InfoItem color='var(--red)' onClick={() => {
                        handleSelect(operatorsData[selected], null, 'expired')
                    }}>
                        <div>
                            <Circle color='var(--red)'>
                                <img src={expiredIconWhite} alt='icon' />
                            </Circle>
                            <h3>Retornos expirados</h3>
                        </div>
                        <h1>{operatorsData[selected].expiredReturns.length}</h1>
                    </InfoItem>
                    <InfoItem color='var(--success)' onClick={() => {
                        handleSelect(operatorsData[selected], null, 'closed')
                    }}>
                        <div>
                            <Circle color='var(--success)'>
                                <TbFileInvoice />
                            </Circle>
                            <h3>Contratos fechados após retorno</h3>
                        </div>
                        <h1>{operatorsData[selected].closedByReturns.length}</h1>
                    </InfoItem>
                </OperatorsInfo>
            )}
        </Container>
    )
}

export default OperatorsCard;