import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: ${props => props.size ? `${props.size}px`: `${17}px`};
    /* width: ${props => props.size ? `${props.size + 8}px`: `${25}px`}; */

    padding: 2px 10px;
    border-radius: 100px;
    background: ${props => props.background ? props.background : 'var(--red)'};
`;

export const Text = styled.div`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: ${props => props.fontWeight ? props.fontWeight : 'normal'};
    font-size: ${props => props.fontSize ? `${props.fontSize}px`: `${8}px`};

    /* 5 */

    color: ${props => props.color ? props.color : '#fff'}
`;