import React, { useEffect, useState } from 'react'

import * as S from './styles';
import {
    VictoryArea,
    VictoryAxis,
    VictoryChart,
    VictoryLine,
    VictoryTooltip,
    VictoryVoronoiContainer
} from 'victory';
import { datetime } from '../../../../helpers/datetime';
import { HiOutlineChartBar } from 'react-icons/hi';
import { IoMdAddCircleOutline } from 'react-icons/io';
import { MdRemoveCircleOutline } from 'react-icons/md';
import PointIcon from '../../../../components/PointIcon';
import CustomDivider from "../../../../components/CustomDivider";
import { filtersHelper } from '../../../../helpers/filtersHelper';

const dateNow = new Date();
const currentMonth = dateNow.getMonth();

const mesesRedux = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];

const lines = [
    { label: 'Linha vermelha', value: 'line1', color: 'var(--red)' },
    { label: 'Linha azul', value: 'line2', color: 'var(--blue4)' },
    { label: 'Linha verde', value: 'line3', color: 'var(--aqua)' }
]

export const yAxisStyle = {
    axis: { stroke: 'transparent' },
    grid: { stroke: 'transparent' },
    tickLabels: {
        fontSize: 12,
        padding: 5,
        fill: "#554040",
        opacity: 0.5,
    },
};

const chartWidth = window.innerWidth / 2

const defaultChartsData = {
    line1: [],
    line2: [],
    line3: []
}

export default function Charts({ backupTableData, filters, selectedLine, setSelectedLine }) {

    const [chartRange, setChartRange] = useState('mes');

    const filterCharts = (backupTableData, filters) => {
        let chartData = defaultChartsData;

        for(const line of lines){
            if(filters[line.value]){
                if(line.value == 'line1'){
                    const array = filtersHelper.filterDataTable(backupTableData, filters[line.value])
                    chartData[line.value] = filterNewChart(array)
                }else{
                    if(filters[line.value].length > 0){
                        const array = filtersHelper.filterDataTable(backupTableData, filters[line.value])
                        chartData[line.value] = filterNewChart(array)
                    }else{
                        chartData[line.value] = []
                    }
                }
            }
        }

        return chartData
    }

    const filterNewChart = (data) => {

        const arrayDays = []
        const arrayMonths = []

        if (!data || data.length == 0) {
            return []
        }

        const firstReportTime = 1659368211000;

        const lastReportTime = data.reduce(function (prev, current) {
            return prev.time > current.time ? prev : current;
        }).time;


        const dateStart = new Date(firstReportTime)
        const dateEnd = new Date(lastReportTime)

        const startTime = new Date(`${dateStart.getMonth() + 1}/${dateStart.getDate()}/${dateStart.getFullYear()}`).getTime();

        const endTime = new Date(`${dateEnd.getMonth() + 1}/${dateEnd.getDate()}/${dateEnd.getFullYear()}`).getTime();

        const array = [];

        for (var i = startTime; i <= endTime; i = i + (86400 * 1000)) {
            const date = new Date(i);

            const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
            const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
            const year = date.getFullYear();

            const stringDate = `${day}/${month}/${year}`;

            arrayDays.push(stringDate)

            const result = arrayMonths.find(x => x.month == date.getMonth() + 1 && x.year == date.getFullYear())

            if (!result) {
                arrayMonths.push({
                    month: date.getMonth() + 1,
                    year: date.getFullYear()
                })
            }
        }

        if (chartRange == 'mes') {
            for (const item of arrayMonths) {
                const result = data.filter(x => (new Date(x.time)).getMonth() + 1 == item.month &&
                    (new Date(x.time)).getFullYear() == item.year)

                const formatedMonth = `${mesesRedux[item.month - 1]}`

                array.push({
                    x: formatedMonth,
                    y: result.length,
                })
            }
        } else {
            for (const day of arrayDays) {
                const result = data.filter(x => datetime.getDate(x.time) == day);

                if (result.length > 0) {
                    const date = new Date(result[0].time)

                    const exists = array.find(x => x.month === date.getMonth() + 1)

                    var formatedDate = ''

                    if (!exists) {
                        formatedDate = `${date.getDate()} de ${mesesRedux[date.getMonth()]}`;
                    } else {
                        formatedDate = `${date.getDate()}`;
                    }


                    array.push({
                        x: formatedDate,
                        y: result.length,
                        month: date.getMonth() + 1,
                    })
                }
            }
        }

        return array
    }

    const VictoryCustomTooltip = (props) => {
        const { datum, x, y, activePoints } = props;
        const color = activePoints[0] ? activePoints[0].style.data.stroke : "#C85A5A";

        return (
            <g style={{ pointerEvents: 'none' }}>
                <foreignObject
                    x={x}
                    y={(y || 50) - 50}
                    width="20"
                    height="20"
                    style={{ overflow: 'visible' }}
                >
                    <S.TooltipArea color={color}>
                        <div>
                            <p>{datum.y}</p>
                        </div>
                    </S.TooltipArea>
                </foreignObject>
                <g>
                    <circle
                        cx={x}
                        cy={y}
                        r="5"
                        stroke={color}
                        strokeWidth={2}
                        fill="white"
                    />
                </g>
            </g>
        );
    };

    return (
        <S.ChartContainer>
            <S.ChartHeader>
                <div>
                    <HiOutlineChartBar color='var(--red)' />
                    <h3>Gráficos</h3>
                </div>
                {/* <S.RangeSelectContainer>
                    <h3>Selecionar intervalo: </h3>
                    <S.RangeSelectButton
                        selected={chartRange == 'dia' ? true : false}
                        onClick={() => setChartRange('dia')}
                    >
                        <h4>Dia</h4>
                    </S.RangeSelectButton>
                    <S.RangeSelectButton
                        selected={chartRange == 'mes' ? true : false}
                        onClick={() => setChartRange('mes')}
                    >
                        <h4>Mês</h4>
                    </S.RangeSelectButton>
                </S.RangeSelectContainer> */}
            </S.ChartHeader>
            <S.ChartArea>
                <S.SvgArea>
                    <svg>
                        <defs>
                            <linearGradient
                                id="myGradient"
                                x1="0%"
                                y1="0%"
                                x2="0%"
                                y2="100%"
                            >
                                <stop
                                    offset="0%"
                                    stopColor="rgba(208, 75, 75, 0.35)"
                                />
                                <stop
                                    offset="68.23%"
                                    stopColor="rgba(208, 75, 75, 0)"
                                />
                            </linearGradient>
                        </defs>
                    </svg>
                </S.SvgArea>
                {backupTableData ? (
                    <VictoryChart
                        width={chartWidth}
                        height={250}
                        containerComponent={
                            <VictoryVoronoiContainer
                                labels={() => ' '}
                                labelComponent={
                                    <VictoryTooltip
                                        flyoutComponent={<VictoryCustomTooltip />}
                                    />
                                }
                            />
                        }
                    >
                        {filterCharts(backupTableData, filters).line1 && (
                            <VictoryArea
                                data={filterCharts(backupTableData, filters).line1}
                                interpolation="cardinal"
                                style={{
                                    data: {
                                        fill: 'url(#myGradient)',
                                        stroke: lines[0].color,
                                        strokeWidth: 3,
                                    },
                                }}
                                labelComponent={<VictoryTooltip />}
                            />
                        )}

                        {filterCharts(backupTableData, filters).line2 && (
                             <VictoryArea
                                data={filterCharts(backupTableData, filters).line2}
                                interpolation="cardinal"
                                style={{
                                    data: {
                                        fill: 'url(#myGradient)',
                                        stroke: lines[1].color,
                                        strokeWidth: 3,
                                    },
                                }}
                                labelComponent={<VictoryTooltip />}
                            />
                        )}

                        {filterCharts(backupTableData, filters).line3 && (
                             <VictoryArea
                                data={filterCharts(backupTableData, filters).line3}
                                interpolation="cardinal"
                                style={{
                                    data: {
                                        fill: 'url(#myGradient)',
                                        stroke: lines[2].color,
                                        strokeWidth: 3,
                                    },
                                }}
                                labelComponent={<VictoryTooltip />}
                            />
                        )}

                        <VictoryAxis dependentAxis style={yAxisStyle} />
                        <VictoryAxis style={yAxisStyle} />
                    </VictoryChart>
                ) : (
                    <p className="chartVoid">
                        Nenhuma informação para ser exibida!
                    </p>
                )}
            </S.ChartArea>

            <CustomDivider />

            <S.ChartLinesContainer>
                {lines.map((line, index) => (
                    <S.ChartLineFilter
                        key={index}
                    >
                        <S.ChartLineTitle
                            color={line.color}
                            selected={selectedLine == line.value ? true : false}
                            onClick={() => {
                                setSelectedLine(line.value)
                            }}
                        >
                            <PointIcon
                                backgroundColor={line.color}
                                size={10}
                            />
                            <h1>{line.label}:</h1>
                        </S.ChartLineTitle>
                        {filters[line.value].length > 0
                            ? filters[line.value].map((item, index) => {
                                if (item?.type == 'date') {
                                    const selectedStart = item?.values?.selectedStart;
                                    const selectedEnd = item?.values?.selectedEnd;

                                    if(selectedStart && selectedEnd?.day){
                                        return (
                                            <S.ChartLineItem key={index}>
                                                <h4>{`${selectedStart.day}/${selectedStart.month}/${selectedStart.year}`} 
                                                - {`${selectedEnd.day}/${selectedEnd.month}/${selectedEnd.year}`}</h4>
                                            </S.ChartLineItem>
                                        )
                                    }

                                    if(selectedStart){
                                        return (
                                            <S.ChartLineItem key={index}>
                                                <h4>{`${selectedStart.day}/${selectedStart.month}/${selectedStart.year}`}</h4>
                                            </S.ChartLineItem>
                                        )
                                    }
                                } else {
                                    return (
                                        <S.ChartLineItem key={index}>
                                            <h4>{item?.values.length > 1
                                                ? `${item?.values[0].label} + ${item?.values.length - 1}`
                                                : item?.values[0].label
                                            }</h4>

                                        </S.ChartLineItem>
                                    )
                                }
                            })
                            : <>
                                {line.value === 'line1' && (
                                    <S.ChartLineItem>
                                        <h4>Todos</h4>
                                    </S.ChartLineItem>
                                )}
                            </>
                        }
                    </S.ChartLineFilter>
                ))}
            </S.ChartLinesContainer>
        </S.ChartContainer>
    )
}
