import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 369px;

    padding: 15px;

    background: var(--white);
    border-radius: 20px;

    cursor: pointer;
    border: ${props => props.selected ? '1px solid #4D6289' : 'none'};
`;

export const MapHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    gap: 10px;
`;

export const MapName = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    p {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        text-overflow: clip;

        color: var(--blue3);
    }
`;

export const MapDetails = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 20px;

    align-items: center;
    gap: 15px;

    color: var(--blue4);

    user-select: none;

    div {
        display: flex;
        flex-direction: row;

        align-items: center;
        gap: 5px;
    }

    h4 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;

        color: #626F87;
    }
`;

export const CitiesContainer = styled.div`
    display: flex;
    flex-flow: wrap;
    /* justify-content: center; */
    width: 100%;

    margin-top: 20px;

    gap: 10px;
`;

export const CityItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background: #EBF3FF;
    border-radius: 10px;

    width: auto;
    height: 20px;
    padding: 8px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;

    color: #808B9F;
`;

export const ExpandedButton = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;

    margin-top: 20px;

    gap: 10px;
    color: var(--blue3);
    fill: var(--blue3);

    cursor: pointer;

    p {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 16px;

        color: var(--blue3);
    }
    
`;

export const ActionsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;

    gap: 10px;

    color: var(--blue3);
    fill: var(--blue3);
`;