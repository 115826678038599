import styled from 'styled-components';

export const Container = styled.div`
    /* display: flex; */
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 256px;

    padding: 20px 34px;

    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    height: 200vh;
    width: calc(100% - 256px);


    background: var(--background);
    /* z-index: 5000; */
`;

export const Header = styled.div`
    width: 100%;
    height: 67px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 20px;

    h1 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 300;
        font-size: 32px;
        line-height: 38px;
        /* identical to box height */


        /* 1 */

        color: #4A5E83;
    }
`;