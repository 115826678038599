import React, { useContext, useState } from 'react';

import { 
    Container,
    Name,
    Especie,
    TextAction,
} from './styles';

//Firebase and context
import { AuthContext } from '../../contexts/AuthContext';

//Custom components
import ModalDeleteUser from '../ModalDeleteUser';

//Icons
import cpfIcon from '../../assets/cpfIcon.svg';
import { BiMap } from 'react-icons/bi';
import { AiOutlineEye } from 'react-icons/ai';
import { TbPencil } from 'react-icons/tb';
import { FiTrash2 } from 'react-icons/fi';

//Utils
import Modal from 'react-modal';

//logs
import { especiesHelper } from '../../helpers/especiesHelper';

const alertOptions = [
    {
        title: 'não encontrados pelo sistema',
        value: 'endNotFound',
    },
    {
        title: 'não encontrados pelos operadores',
        value: 'endNotFoundOperator',
    },
    {
        title: 'localidade diversa',
        value: 'differentLocation', 
    }
]

function MapItem({ data, onClick, onView, onEdit, tableType, onChangeAddress }) {

    const { isAdmin } = useContext(AuthContext);

    const [ modalDeleteUser, setModalDeleteUser ] = useState(false);

    const getEndNotFoundType = () => {
        if(data.endNotFound == true){
            return '(S)'
        }

        if(data.endNotFoundOperator == true){
            return '(O)'
        }

        return
    }

    return (
        <Container onClick={onClick}>
            <Name>{data.nome} {tableType != 'clientsMap' && (getEndNotFoundType()) }</Name>
            <div>
                <img src={cpfIcon} alt='cpf' />
                <h4>{data.cpf}</h4>
            </div>
            <div>
                <h4>{data.idade}</h4>
            </div>
            <Especie 
                color={especiesHelper.getEspecieTag(data.especie).tagColor}
                textColor={especiesHelper.getEspecieTag(data.especie).textColor}
            >
                {data.especie}
            </Especie>
            <div>
                <BiMap size={15} color='var(--grey2)' />
                <h4>{data[`cidade${data.validEnd}`]}</h4>
            </div>

            {isAdmin
                ?<>
                    {tableType == 'clientsMap'
                        ? <div>
                            <AiOutlineEye 
                                onClick={onView} 
                                cursor='pointer' 
                                size={18} 
                                color='#808B9F' 
                                fill='#808B9F'
                            />
                            {isAdmin && (
                                <>
                                    <TbPencil 
                                        onClick={onEdit}
                                        size={18} 
                                        style={{ strokeWidth: 1.5, cursor: 'pointer' }} 
                                        color='#808B9F' 
                                    />
                                    <FiTrash2 
                                        onClick={() => setModalDeleteUser(true)}
                                        size={16} 
                                        style={{ strokeWidth: 1.5, cursor: 'pointer' }}
                                        color='#808B9F' 
                                    />
                                </>
                            )}
                            
                        </div>
                        : <TextAction onClick={onChangeAddress}>
                            {tableType == alertOptions[2].value ? 'Alterar endereço no mapa' : 'Escolher outro endereço'}
                        </TextAction>
                    }
                </>
                : <div>
                    <AiOutlineEye 
                        onClick={onView} 
                        cursor='pointer' 
                        size={18} 
                        color='#808B9F' 
                        fill='#808B9F'
                    />
                </div>
            }
            
            
            <Modal
                isOpen={modalDeleteUser}
                onRequestClose={() => {
                    setModalDeleteUser(false);
                }}
                overlayClassName="modal-overlay"
                className="modal-content-auto-width"
                contentLabel="Example Modal"
            >
                <ModalDeleteUser 
                    client={data}
                    onSuccess={() => {
                        setModalDeleteUser(false);
                    }}
                    onCancel={() => {
                        setModalDeleteUser(false);
                }}/>
            </Modal>
        </Container>
    )
}

export default MapItem;