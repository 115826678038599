import React, { useEffect, useState } from 'react';

import { 
    DetailsContainer,
    Header, 
    ClientHeader, 
    ClientNameContent, 
    Container, 
    ImageContainer, 
    ImageStreetView, 
    InfoHeader, 
    PointDivider, 
    FullData,
    GenerateReportButton,
    ImageEspecie,
    Content,
} from './styles';


import { AiOutlineCloseCircle } from 'react-icons/ai';
import { FiMapPin } from 'react-icons/fi';
import { TbFileInvoice } from 'react-icons/tb';
import CustomLoading from '../CustomLoading';
import axios from 'axios';

//utils
import { markers } from '../../helpers/markers';
import { address } from '../../helpers/address';
import ClientBadges from '../ClientBadges';


const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const ClientDetailsOperator = ({ data, points, onClose, streetView, onStreetView, onGenerateReport, onViewFullData }) => {

    const [ loadingData, setLoadingData ] = useState(true);

    const [ imageStreetView, setImageStreetView ] = useState('');

    const [ reportDisable, setReportDisable ] = useState('');

    useEffect(() => {
        (async () => {
            setLoadingData(true);

            if(points.length > 0){
                const available = points.find(x => x.cpf === data.cpf);

                setReportDisable(available);
            }            

            await axios.get(`https://maps.googleapis.com/maps/api/streetview?size=400x400&location=${data.lat},${data.lng}&fov=80&heading=70&pitch=0&key=${apiKey}`, { responseType: "blob" })
                .then((response) => {
                    setImageStreetView(response.data);
                })
                .catch((error) => {
                    
                })
            
            setLoadingData(false);
        })();
    }, [data]);

    if(loadingData){
        return <Container>
            <CustomLoading background='white' />
        </Container>
    }

    return (
        <Container streetView={streetView}>
            <Header>
                <AiOutlineCloseCircle size={25} style={{ cursor: 'pointer'}} onClick={onClose}/>
            </Header>

            <Content>
                <ImageContainer>
                    <ImageStreetView
                        src={URL.createObjectURL(imageStreetView)}
                        onClick={() => {
                            onStreetView()
                        }}
                    />
                </ImageContainer>

                <ClientHeader>
                    <ImageEspecie>
                        <img 
                            src={markers.getMarkerIconsEspecie(data.especie)} 
                            style={{ width: 34, height: 34, objectFit: 'cover'}} 
                        />
                    </ImageEspecie>
                    <ClientNameContent>
                        <h4>{data.nome}</h4>
                        <p>{data.especie}</p>
                    </ClientNameContent>
                </ClientHeader>
                
                <PointDivider />

                <DetailsContainer>
                    <InfoHeader>
                        <FiMapPin size={22} color='var(--red)' />
                        <p>Endereço</p>
                    </InfoHeader>
                    <p>{address.formatEnd(data)}</p>
                </DetailsContainer>

                <DetailsContainer>
                    <InfoHeader>
                        <TbFileInvoice size={26} color='var(--red)' style={{ strokeWidth: 1.8 }} />
                        <p>Dados</p>
                    </InfoHeader>
                    <p>Idade: {data.idade}</p>
                    <ClientBadges data={data} />
                </DetailsContainer>

                <FullData onClick={() => onViewFullData(imageStreetView)}>
                    Ver dados completos
                </FullData>
                
                {!reportDisable && (
                    <GenerateReportButton
                        onClick={onGenerateReport}
                    >
                        Localidade diversa
                    </GenerateReportButton>
                )}
            </Content>
        </Container>
    )
}

export default ClientDetailsOperator;