import React, { useEffect, useState } from 'react';

import {
    ClientHeader,
    ClientNameContent,
    RowContent,
    RowItem,
    Container,
    DetailsContainer,
    Divider,
    ImageEspecie,
    ImageStreetView,
    InfoHeader,
    Content,
    AddressItem,
    AddressHeader,
    AddressText,
    ClientHeaderLeft,
    AnaliseText,
    HistoryHeader,
    HistoryLine,
    HistoryItem,
    HistoryItemContent,
} from './styles';

//Icons
import { FiMapPin } from 'react-icons/fi';
import { TbPhoneCall, TbHammer, TbFileInvoice } from 'react-icons/tb';

//Custom components
import CustomLoading from '../CustomLoading';
import ModalHeader from '../ModalHeader';
import ModalStreetView from '../ModalStreetView';

//Utils
import Modal from 'react-modal';

//utils
import { markers } from '../../helpers/markers';
import { address } from '../../helpers/address';
import { datetime } from '../../helpers/datetime';
import ClientBadges from '../ClientBadges';

//APIS
import useClientHistory from '../../hooks/useClientHistory';
import axios from 'axios';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

const validEnds = ['S1', 'S2', 'O1', 'O2'];

const validEndNamesInd = ['clientLogradouro', 'clientNumero', 'clientBairro', 'clientCidade', 'clientEstado'];

const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

function ModalDetailsHistory({ data, onClose }) {

    const [loadingData, setLoadingData] = useState(true);

    const { clientHistory, getClientHistory } = useClientHistory();

    const [imageStreetView, setImageStreetView] = useState('');

    const [ expandedAddress, setExpandedAddress ] = useState(0);

    const [modalStreetView, setModalStreetView] = useState(false);

    const [ client, setClient ] = useState(data);

    useEffect(() => {
        (async () => {
            setLoadingData(true);

            if (!clientHistory) {
                await getClientHistory(data.cpf)
            } else {
                setLoadingData(false);
            }
        })();
    }, [clientHistory])


    useEffect(() => {
        (async () => {
            await axios.get(`https://maps.googleapis.com/maps/api/streetview?size=724x144&location=${
                client.lat},${client.lng}&fov=80&heading=70&pitch=0&key=${apiKey}`, { responseType: "blob" })
            .then((response) => {
                setImageStreetView(response.data);
            })
            .catch((error) => {

            })
        })();
    }, [client]);

    const formatEndIndication = (client) => {
        var string;

        for(const endName of validEndNamesInd){
            if(client[`${endName}`] != ''){
                if(endName == 'clientLogradouro'){
                    string = client[`${endName}`]
                }else{
                    if(endName == 'clientCidade' || endName == 'clientEstado'){
                        string = string + ' - ' + client[`${endName}`]
                    }else{
                        string = string + ', ' + client[`${endName}`]
                    }
                }
            }
        }

        if(string == undefined){
            string = 'N/D';
        }

        return string;
    }

    if (loadingData) {
        return (
            <Container>
                <CustomLoading background='transparent' />
            </Container>
        )
    }

    return (
        <Container>
            <ModalHeader
                title='Histórico do cliente'
                onClose={onClose}
            />

            <HistoryHeader>
                <HistoryLine />

                <HistoryItemContent>
                    {clientHistory && clientHistory.concat([data]).reverse().map((item, index) => (
                        <HistoryItem 
                            key={index}
                            selected={client.dataInclusao 
                                ? client.dataInclusao === item?.dataInclusao
                                : client?.time === item.time}
                            onClick={() => setClient(item)}    
                        >
                            <div />
                            {client.dataInclusao
                                ? <h1>{datetime.getDate(item.dataInclusao)} {index === 0 ? '(atual)' : ''}</h1>
                                : <h1>{datetime.getDate(item.time)} {index === 0 ? '(atual)' : ''}</h1>
                            }
                        </HistoryItem>
                    ))}
                </HistoryItemContent>                
            </HistoryHeader>

            <Content>
                <ImageStreetView
                    src={imageStreetView ? URL.createObjectURL(imageStreetView) : null}
                    onClick={() => setModalStreetView(true)}
                />

                <ClientHeader>
                    <ClientHeaderLeft>
                        <ImageEspecie>
                            <img 
                                src={markers.getMarkerIconsEspecie(client.especie)} 
                                style={{ width: 30, height: 30, objectFit: 'cover'}} 
                            />
                        </ImageEspecie>
                        <ClientNameContent>
                            {client.nome
                                ? <h4>{client.nome}</h4>
                                : <h4>{client.clientName}</h4>
                            }
                            <p>{client.especie}</p>
                        </ClientNameContent>
                    </ClientHeaderLeft>
                    <div>
                        <ClientBadges data={client} isModalDetails />
                    </div>
                    {client.nome && client.dataInclusao && (<h3>Data de inclusão: {datetime.getDate(client.dataInclusao)}</h3>)}
                    {client.nome && !client.dataInclusao && (<h3>Data de inclusão: {datetime.getDate(client.time)}</h3>)}
                </ClientHeader>

                <Divider />

                <DetailsContainer>
                    <InfoHeader>
                        <div>
                            <TbFileInvoice size={24} color='var(--red)'  style={{ strokeWidth: 1.8 }} />
                            <p>Dados</p>
                        </div>
                    </InfoHeader>
                    <RowContent>
                        {client.dataNasc && (
                            <RowItem>
                                <h3>Data de nascimento</h3>
                                <p>{client.dataNasc}</p>
                            </RowItem>
                        )}
                        
                        {client.idade && (
                            <RowItem>
                                <h3>Idade</h3>
                                <p>{client.idade}</p>
                            </RowItem>
                        )}
                        
                        {client.cpf
                            ?<RowItem>
                                <h3>CPF</h3>
                                <p>{client.cpf}</p>
                            </RowItem>

                            : <RowItem>
                                <h3>CPF</h3>
                                <p>{client.clientCpf}</p>
                            </RowItem>
                        }
                        
                        {client.nomeMae && (
                            <RowItem>
                                <h3>Nome da mãe</h3>
                                <p>{client.nomeMae}</p>
                            </RowItem>
                        )}
                    </RowContent>
                </DetailsContainer>

                {client.cpf 
                    ? <>
                        <Divider />
                        <DetailsContainer>
                            <InfoHeader>
                                <div>
                                    <FiMapPin size={22} color='var(--red)' />
                                    <p>Endereço</p>
                                </div>
                            </InfoHeader>
                            {validEnds.map((item, index) => {

                                const clientAddress = address.formatValidEnd(client, item)

                                if(clientAddress != 'N/D'){
                                    return (
                                        <AddressItem key={index}>
                                            <AddressHeader>
                                                <p>
                                                    Endereço {item}{item == client.validEnd && (' (atual)')}
                                                </p>
                                            </AddressHeader>
                                            <p>{clientAddress}</p>
                                        </AddressItem>
                                    )
                                }
                            })}
                        </DetailsContainer>
                    </>

                    : <>
                        <Divider />
                        <DetailsContainer>
                            <InfoHeader>
                                <div>
                                    <FiMapPin size={22} color='var(--red)' />
                                    <p>Endereço</p>
                                </div>
                            </InfoHeader>
                            <AddressItem>
                                <AddressHeader>
                                    <p>
                                        Endereço
                                    </p>
                                    {expandedAddress == 0
                                        ? <IoIosArrowUp cursor='pointer' onClick={() => setExpandedAddress(null)} />
                                        : <IoIosArrowDown cursor='pointer' onClick={() => setExpandedAddress(0)} />
                                    }
                                </AddressHeader>
                                {expandedAddress == 0 && (
                                    <AddressText>
                                        <p>{formatEndIndication(client)}</p>
                                    </AddressText>
                                )}
                                
                            </AddressItem>
                        </DetailsContainer>
                    </>
                }

                {client.cpf
                    ? <>
                        <Divider />

                        <DetailsContainer>
                            <InfoHeader>
                                <div>
                                    <TbPhoneCall size={22} color='var(--red)' />
                                    <p>Contato</p>
                                </div>
                            </InfoHeader>
                            <RowContent>
                                {client.telPrincipal && client.telPrincipal != 'N/D' && (
                                    <RowItem>
                                        <h3>Telefone principal</h3>
                                        <p>{client.telPrincipal}</p>
                                    </RowItem>
                                )}
                                
                                {client.telSecundario && client.telSecundario != 'N/D'  && (
                                    <RowItem>
                                        <h3>Telefone secundário</h3>
                                        <p>{client.telSecundario}</p>
                                    </RowItem>
                                )}
                                
                                {client.celular && client.celular != 'N/D'  && (
                                    <RowItem>
                                        <h3>Celular</h3>
                                        <p>{client.celular}</p>
                                    </RowItem>
                                )}

                                {client.tel0 && client.tel0 != 'N/D'  && (
                                    <RowItem>
                                        <h3>Telefone 0</h3>
                                        <p>{client.tel0}</p>
                                    </RowItem>
                                )}

                                {client.tel1 && client.tel1 != 'N/D'  && (
                                    <RowItem>
                                        <h3>Telefone 1</h3>
                                        <p>{client.tel1}</p>
                                    </RowItem>
                                )}

                                {client.tel2 && client.tel2 != 'N/D'  && (
                                    <RowItem>
                                        <h3>Telefone 2</h3>
                                        <p>{client.tel2}</p>
                                    </RowItem>
                                )}
                                
                                {client.celular0 && client.celular0 != 'N/D'  && (
                                    <RowItem>
                                        <h3>Celular 0</h3>
                                        <p>{client.celular0}</p>
                                    </RowItem>
                                )}

                                {client.celular1 && client.celular1 != 'N/D'  && (
                                    <RowItem>
                                        <h3>Celular 1</h3>
                                        <p>{client.celular1}</p>
                                    </RowItem>
                                )}

                                {client.celular2 && client.celular2 != 'N/D'  && (
                                    <RowItem>
                                        <h3>Celular 2</h3>
                                        <p>{client.celular2}</p>
                                    </RowItem>
                                )}
                            </RowContent>
                        </DetailsContainer>
                    </>
                    : <>
                        <Divider />

                        <DetailsContainer>
                            <InfoHeader>
                                <div>
                                    <TbPhoneCall size={22} color='var(--red)' />
                                    <p>Contato</p>
                                </div>
                            </InfoHeader>
                            <RowContent>
                                {client.clientTel && client.clientTel != 'N/D' && (
                                    <RowItem>
                                        <h3>Telefone</h3>
                                        <p>{client.clientTel}</p>
                                    </RowItem>
                                )}
                            </RowContent>
                        </DetailsContainer>
                    </>
                }


                {client.cpf && (
                    <>
                        <Divider />
                        <DetailsContainer>
                            <InfoHeader>
                                <div>
                                    <TbPhoneCall size={22} color='var(--red)' />
                                    <p>Indeferimento</p>
                                </div>
                            </InfoHeader>
                            <RowContent>
                                {client.nb && client.nb != 'N/D' && (
                                    <RowItem>
                                        <h3>NB</h3>
                                        <p>{client.nb}</p>
                                    </RowItem>
                                )}
                                
                                {client.motivoIndef && client.motivoIndef != 'N/D'  && (
                                    <RowItem>
                                        <h3>Resultado</h3>
                                        <p>{client.motivoIndef}</p>
                                    </RowItem>
                                )}
                                
                                {client.dataIndef && client.dataIndef != 'N/D'  && (
                                    <RowItem>
                                        <h3>Data do indeferimento</h3>
                                        <p>{client.dataIndef}</p>
                                    </RowItem>
                                )}

                                {client.dataDer && client.dataDer != 'N/D'  && (
                                    <RowItem>
                                        <h3>Data da Der</h3>
                                        <p>{client.dataDer}</p>
                                    </RowItem>
                                )}
                            </RowContent>
                        </DetailsContainer>
                    </>
                )}
                
                {client.cpf && (
                    <>
                        <Divider />
                        <DetailsContainer>
                            <InfoHeader>
                                <div>
                                    <TbHammer size={22} color='var(--red)' style={{transform: 'rotate(-90deg)' }} />
                                    <p>Processos arquivados</p>
                                </div>
                            </InfoHeader>
                            <p>{client.processos}</p>
                        </DetailsContainer>
                    </>
                )}           

                {client.cpf && (
                    <>
                        <Divider />
                        <DetailsContainer>
                            <InfoHeader>
                                <div>
                                    <TbHammer size={22} color='var(--red)' style={{transform: 'rotate(-90deg)' }} />
                                    <p>Análise</p>
                                </div>
                            </InfoHeader>
                            {client.analise && client.analise.length > 0 &&
                                client.analise.split('<br/>').map((item, index) => (
                                    <AnaliseText key={index}>{item}</AnaliseText>
                                ))
                            }
                        </DetailsContainer>
                    </>
                )}               
            </Content>

            <Modal
                isOpen={modalStreetView}
                onRequestClose={() => setModalStreetView(false)}
                overlayClassName="modal-overlay"
                className="modal-content-auto-width"
                contentLabel="Example Modal"
            >
                <ModalStreetView 
                    client={client}
                    onClose={() => setModalStreetView(false)}
                />
            </Modal>
        </Container>
    )
}

export default ModalDetailsHistory;