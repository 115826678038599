import React, { useContext } from 'react';

import { 
    Container,
    Name,
    Especie,
    Date,
    Options
} from './styles';

//Firebase and context
import { UsersContext } from '../../contexts/UsersContext';

//Icons
import { AiOutlineEye } from 'react-icons/ai';

//Custom components
import CustomDivider from '../CustomDivider';

//MUI
import { Avatar } from '@mui/material';

//utils
import { datetime } from '../../helpers/datetime';
import { avatar } from '../../helpers/avatar';
import { especiesHelper } from '../../helpers/especiesHelper';

function ModalDashboardItem({ data, type, columns, onViewFullData }) {

    const { users } = useContext(UsersContext);

    return (
        <>
            <Container columns={columns}>
                <Name>{data.clientName}</Name>
                <Especie 
                    color={especiesHelper.getEspecieTag(data.especie).tagColor}
                    textColor={especiesHelper.getEspecieTag(data.especie).textColor}
                >
                    {data.especie}
                </Especie>
                <Date color='var(--grey2)'>
                    {datetime.getDate(data.time)}
                </Date>
                {!type && (
                    <div>
                        <Avatar
                            alt="Avatar"
                            src={avatar.getUserAvatar(users.operators, data.operatorUid)}
                            sx={{ 
                                width: 20, 
                                height: 20, 
                                borderRadius: 100, 
                                borderColor: 'white', 
                                borderWidth: 10 
                            }}
                        />
                        <h4>{data.operatorName}</h4>
                    </div>
                )}
                <Options>
                    <AiOutlineEye 
                        onClick={onViewFullData} 
                        cursor='pointer' 
                        size={20} 
                        color='#808B9F' 
                        fill='#808B9F'
                    />
                </Options>
            </Container>
            <CustomDivider width='99%' />
        </>
        
    )
}

export default ModalDashboardItem;